import React, { useState,useRef } from 'react';
import './PostingTasks.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const PostingTasks = (milestonedata) => {
    console.log(milestonedata)
    const datePickerRef = useRef()
    const [expandedMilestones, setExpandedMilestones] = useState([]); 
    const toggleMilestone = (milestoneId) => {
        setExpandedMilestones((prevExpandedMilestones) => {
            if (prevExpandedMilestones.includes(milestoneId)) {
                return prevExpandedMilestones.filter(id => id !== milestoneId);
            } else {
                return [...prevExpandedMilestones, milestoneId];
            }
        });
    };

    return (
        <div className="PostingTasksSection">
            <table className="milestonesTable">
                <thead>
                    <tr>
                        <th>#</th> 
                        <th>Milestone</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                    </tr>
                </thead>
                <tbody>
                    {milestonedata.milestonedata.map((milestone, milestoneIndex) => (
                        <React.Fragment key={milestone.milestoneId}>
                            <tr
                                className={`milestone-row ${expandedMilestones.includes(milestone.milestoneId) ? 'expandedRow1' : ''} ${milestone.milestoneId === expandedMilestones[0] ? 'selectedRow' : ''}`}
                                onClick={() => toggleMilestone(milestone.milestoneId)}
                                
                                aria-expanded={expandedMilestones.includes(milestone.milestoneId)}
                                aria-controls={`tasks-${milestone.milestoneId}`}
                            >
                                <td>{milestoneIndex + 1}</td> {/* Serial Number for Milestones */}
                                <td>{milestone.milestoneName}</td>
                                <td>{milestone.milestoneStartDate || "MM/DD/YYYY"}</td>
                                <td>{milestone.milestoneEndDate || "MM/DD/YYYY"}</td>
                            </tr>

                            {expandedMilestones.includes(milestone.milestoneId) && (
                                <tr>
                                    <td colSpan="4">
                                        <table className="nested-table">
                                            <thead>
                                                
                                            </thead>
                                            <tbody>
                                                {milestone.tasks.map((task, taskIndex) => (
                                                    <tr key={task.taskId} className="task-row">
                                                        <td>{taskIndex + 1}</td>
                                                        <td className="task-title">{task.taskName}</td>
                                                        <td>
                                                        <div className="taskDate input-div">
                                                        <DatePicker
                                                            ref = {datePickerRef}
                                                            enableTabLoop={false}
                                                            // onChange={(date) => setStartDate(date)}
                                                            dateFormat="MM/dd/yyyy"
                                                            placeholderText="MM/DD/YYYY"
                                                            className="custom-date-picker"
                                                            wrapperClassName="w-full"   
                                                            value={task.taskStartDate|| "MM/DD/YYYY"}
                                                        />
                                                        </div>
                                                        </td>
                                                        <td>
                                                        <div className="taskDate input-div">
                                                        <DatePicker
                                                            ref = {datePickerRef}
                                                            enableTabLoop={false}
                                                            // onChange={(date) => setStartDate(date)}
                                                            dateFormat="MM/dd/yyyy"
                                                            placeholderText="MM/DD/YYYY"
                                                            className="custom-date-picker"
                                                            wrapperClassName="w-full"   
                                                            value={task.taskEndDate|| "MM/DD/YYYY"}              
                                                        />
                                                        </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default PostingTasks;
