import React, { useState } from "react";
import ReactSelectAsync from 'react-select/async';
import './Modal.css'; 
import PhoneInput from 'react-phone-number-input';
import { locationAutoComplete } from "../../../services/locationService";
import { useEffect } from "react";

const AddNewPerson = ({ show, handleClose, handleSave }) => {
  const [memberDetails, setMemberDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    alternateNumber: "",
    address1: "",
    address2: "",
    country: "",
    city: "",
    state: "",
    zipCode: "",
    county: ""
});

  const handleCloseClick = () => {
    handleClose();
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setMemberDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value
  }));
  };
  const handleSaveClick = () => {
    handleSave(memberDetails);
    setMemberDetails('')
  };
  const handleCountryChange = (name,value)=>{
    setMemberDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value
  }));
}

const getlocationAutoComplete = async (data) => {
  console.log(data)
  let suggestions = []
  if(data.length > 8){
      await locationAutoComplete(data).then((listData)=>{                
          suggestions = listData;
      })
  }
  return suggestions.map((item)=>({        
      label: item.description,
      value:item
  }))
}


  if (!show) {
    return null; 
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={handleCloseClick}>
          &times;
        </span>
        <div className="modal-header">
          <h5>Add New Staff Member</h5>
        </div>
        <div className="modal-body">
          <div className="form-row">
            <div className="form-group col-md-4">
              <div className="label">First Name</div>
              <input name="firstName" type="text" value={memberDetails.firstName} onChange={handleInputChange}/>
            </div>
            <div className="form-group col-md-4">
            <div className="label">Last Name</div>
              <input  name="lastName" type="text" value={memberDetails.lastName} onChange={handleInputChange}/>
            </div>
            <div className="col-md-4"></div>
            
          </div>

         
          <div className="form-row">
            <div className="form-group col-md-4">
                <div className="label">Email Address</div>
              <input name="email" type="email" value={memberDetails.email} onChange={handleInputChange}/>
            </div>


            <div className="form-group col-md-4">
            <div className="label">Mobile Phone Number</div>
              <PhoneInput 
               international
               defaultCountry="US"
               value = {memberDetails.mobile}
               countryCallingCodeEditable={false}
               onChange={(value) =>
                handleCountryChange("phoneNumber",value)
                }
            />
            </div>
            <div className="form-group col-md-4">
            <div className="label">Alternate Phone Number</div>              
            <PhoneInput 
              international
              defaultCountry="US"
              value = {memberDetails.alternateNumber}
              countryCallingCodeEditable={false}
              onChange={(value) =>
                handleCountryChange("alternateNumber",value)
                }
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-4">
            <div className="label">Address 1</div>
                <ReactSelectAsync 
                    className="basic-single"
                    classNamePrefix="custom-select"                    
                    value = {memberDetails.address1? {label:memberDetails.address1, value:memberDetails.address1}: null}
                    loadOptions ={getlocationAutoComplete}                            
                    onChange = {(option) => setMemberDetails((prev) => ({
                      ...prev, 
                      address1: option.value?.address1,
                      address2: option.value?.address2,
                      city: option.value?.city,
                      county: option.value?.county,
                      country: option.value?.country,
                      zipCode: option.value?.zipCode,
                      state: option.value?.state
                  }))}     
                />
            </div>
            <div className="form-group col-md-4">
            <div className="label">Address 2</div>
              <input name="address2" type="text" value={memberDetails.address2}/>
            </div>
            <div className="form-group col-md-4">
            <div className="label">Country</div>
              <input
                className="form-control"
                name="country"
                type="text"
                value={memberDetails.country || "USA"}
                disabled={true}                
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-4">
                <div className="label">City</div>
                <input className="form-control" name="city" type="text" value={memberDetails.city}/>
            </div>
            <div className="form-group col-md-4">
                <div className="row">
                    <div className="col-md-6">
                        <div className="label">State</div>
                        <input className="form-control" name="state" type="text" value={memberDetails.state}/>
                    </div>
                    <div className="col-md-6">
                        <div className="label">ZipCode</div>
                        <input className="form-control" name="zipcode" type="text" value={memberDetails.zipCode}/>
                    </div>
                </div>
            </div>
          
            <div className="form-group col-md-4">
                <div className="label">County</div>
                    <input className="form-control" name="county" type="text" value={memberDetails.county}/>
                </div>
            </div>
        </div>


        <div className="modal-footer">
          <button className="Close" onClick={handleCloseClick}>
            Close
          </button>
          <button className="Save" onClick={handleSaveClick}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNewPerson;
