import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown} from '@fortawesome/free-solid-svg-icons';
import './CreateProject.css'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import customersData from '../../data/Customers.json';
import {isMandatoryFieldValid, isValidEmail, isValidPhone, isValidZipCode} from "../../services/utils";
import {locationAutoComplete} from '../../services/locationService';
import ReactSelectAsync from "react-select/async";
import PhoneInput from 'react-phone-number-input';
import useAuthService from '../../services/AuthService';

const  CustomerInformation = ({propertyType=1, sendCustomerInfo, data,onClose, projectId}) => {
    const {getOrganisationList,getResidentialCustomer,getCustomerInfo,updateCustomer, getCustomerContact} = useAuthService();
    
    const [isCustomerOpen, setIsCustomerOpen] = useState(true);
    const [isEditable, setIsEditable] = useState(true);
    const [isMailingEditable,setIsMailingEditable] = useState(true)
    const [isSearchExistingVisible, setIsSearchExistingVisible] = useState(false);
    
    const [isVisible, setIsVisible] = useState(true);
    const [isMailingAddressSame,setIsMailingAddressSame] = useState(false)
    const [isOrganizationNameVisible, setIsOrganizationNameVisible] = useState(false);
    const [organizationOptions,setOrganizationOptions] = useState([])
    const [searchExistingInput,setSearchExistingInput] = useState([]);
    const [organization,setOrganization] = useState("");
    const [customerOptions, setCustomerOptions] = useState([])
    const [organisationCustomerOptions, setOrganisationCustomerOptions] = useState([])
    const [shouldEdit, setShouldEdit] = useState(false)
    const [shouldCancelSave,setShouldCancelSave] = useState(false);
    const newOption = { value: 'New', label: 'New' };

    const initialCustomerData = {
        relationshipId:"",
        firstName:"",
        lastName:"",
        phoneNumber:"",
        alternateNumber : "",
        email:"",
        organizationName:null,
        mailingAddressFlag:null,
        lossAddress:{
            address1:"",
            address2:"",
            city:"",
            country:"",
            state:"",
            zipCode:"",
            county:""
        },
        mailingAddress:{
            address1:"",
            address2:"",
            city:"",
            country:"",
            state:"",
            zipCode:"",
            county:""
        }
    };

    const [customerDataErrors, setCustomerDataErrors] = useState({
        id:false,
        firstName:false,
        lastName:false,
        phoneNumber:false,
        alternateNumber : false,
        email:false,
        organizationName:false,
        lossAddress:{
            address1:false,
            address2:false,
            city:false,
            country:false,
            state:false,
            zipCode:false,
            county:false
        },
        mailingAddress:{
            address1:false,
            address2:false,
            city:false,
            country:false,
            state:false,
            zipCode:false,
            county:false
        }
    });
    // const [customerDataErrors,setCustomerDataErrors] = useState(dataErrors)
    const [customerData, setCustomerData] = useState(initialCustomerData);

    useEffect(()=>{
        if (sendCustomerInfo){
            sendCustomerInfo(customerData);
        }
    },[customerData, sendCustomerInfo]);  

    const fetchOrganisationList = async () => {
          const types = await getOrganisationList();  
          const formattedOptions = types.map(type => ({
            value: type.id,
            label: type.name,
          }));
       
        const updatedOptions = [newOption, ...formattedOptions]; 
          setOrganizationOptions(updatedOptions);
        };

    const fetchOrganisationContact = async (value) => {
        setOrganization(value);
        const types = await getCustomerContact(value);  
        const formattedOptions = types.map(type => ({
            value: type.id,
            label: type.name,
          }));
        const updatedOptions = [newOption, ...formattedOptions]; 
        setOrganisationCustomerOptions(updatedOptions)
        };

    const fetchResidentialCustomer = async () => {
        const types = await getResidentialCustomer();  
        const formattedOptions = types.map(type => ({
            value: type.id,
            label: type.name,
        }));
        const updatedOptions = [newOption, ...formattedOptions]; 
        setCustomerOptions(updatedOptions);
        };

    useEffect(()=>{
        console.log(shouldEdit,shouldCancelSave)
    },[shouldEdit,shouldCancelSave])

    
    useEffect(()=>{
       if(data){
        setIsEditable(true);
        setShouldCancelSave(true);
        setShouldEdit(false)
        if(data!=='NA'){
        let lossAddress = {};
            let mailingAddress = {};
            data.address.forEach(addr => {
            if (addr.Loss) {
                lossAddress = {
                address1: addr.Loss.address1,
                address2: addr.Loss.address2,
                city: addr.Loss.city,
                county: addr.Loss.county,
                state: addr.Loss.state,
                zipCode: addr.Loss.zipCode,
                country: addr.Loss.country,
                };
            }
            if (addr.Mailing) {
                mailingAddress = {
                address1: addr.Mailing.address1,
                address2: addr.Mailing.address2,
                city: addr.Mailing.city,
                county: addr.Mailing.county,
                state: addr.Mailing.state,
                zipCode: addr.Mailing.zipCode,
                country: addr.Mailing.country
                };
            }
            });
            setCustomerData({
            relationshipId: data.relationshipId,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            alternateNumber: data.alternateNumber,
            email: data.email,
            mailingAddressFlag:false,
            lossAddress,  
            mailingAddress 
            });
       }
    }
    },[data])

    const fetchCustomerInfo = async (option) => {
        setCustomerData(initialCustomerData)
        setIsEditable(false);
        setShouldEdit(true);
        try {
            const types = await getCustomerInfo(option.value);
            console.log(types)
            let lossAddress = {};
            let mailingAddress = {};
            types.address.forEach(addr => {
            if (addr.Loss) {
                lossAddress = {
                address1: addr.Loss.address1,
                address2: addr.Loss.address2,
                city: addr.Loss.city,
                county: addr.Loss.county,
                state: addr.Loss.state,
                zipCode: addr.Loss.zipCode,
                country: addr.Loss.country,
                };
            }
            if (addr.Mailing) {
                mailingAddress = {
                address1: addr.Mailing.address1,
                address2: addr.Mailing.address2,
                city: addr.Mailing.city,
                county: addr.Mailing.county,
                state: addr.Mailing.state,
                zipCode: addr.Mailing.zipCode,
                country: addr.Mailing.country
                };
            }
            });
            setCustomerData({
            relationshipId: types.relationshipId,
            firstName: types.firstName,
            lastName: types.lastName,
            phoneNumber: types.phoneNumber,
            alternateNumber: types.alternateNumber,
            email: types.email,
            mailingAddressFlag:false,
            lossAddress,  
            mailingAddress 
            });
        
            
        } catch (error) {
            console.error('Error fetching customer info:', error);
        }
        };

    const sendEditedData = async () => {
        customerData.projectId = projectId;
        const types = await updateCustomer(customerData).then((res) =>  onClose()).catch((err)=>{onClose(); alert("failed to sendEditedData")});  
        if(!data){
            setIsEditable(false)
            setShouldEdit(true)
        }
    };

    const toggleCustomerDetails = () => {
        setIsCustomerOpen(!isCustomerOpen);
    };

    useEffect(()=>{
        setShouldEdit(false);
        if(!data){
            console.log('1')
            setShouldCancelSave(false)
            setIsEditable(false);
        }
        if(propertyType === 2){
            setCustomerData(initialCustomerData)
            setOrganization("")
            setIsOrganizationNameVisible(true);
            fetchOrganisationList()
        }
        else if(propertyType === 1){
            fetchResidentialCustomer();
            setIsOrganizationNameVisible(false);
        }
    },[propertyType])

    useEffect(()=>{
        if(searchExistingInput!= "" && searchExistingInput.value === 'New' || organization === 'New'){
            setIsEditable(true);
            setCustomerData(initialCustomerData)
            setIsMailingAddressSame(false);
            setCustomerDataErrors(customerDataErrors)
            setShouldEdit(false);
           
        } 
        else{
            setIsSearchExistingVisible(true);
            setCustomerDataErrors(customerDataErrors);
        }
    },[searchExistingInput,organization]);

    useEffect(()=>{
        setIsMailingEditable(!isMailingAddressSame);
        setCustomerData((prev)=>({
            ...prev,
            mailingAddress : { ...prev.lossAddress }
        }))
        setCustomerDataErrors(prev =>({
            ...prev,
            mailingAddress:{
                address1:false,
                address2:false,
                city:false,
                country:false,
                state:false,
                county:false,
                zipCode:false
            }
        }))
    },[isMailingAddressSame])

    const handleInputChange = (field, subfield, value) => {
        if(subfield === "address1"){
            setCustomerData(prev =>({
                ...prev,
                [field]: {
                    address1: value.address1,
                    address2: value.address2,
                    city: value.city,
                    country: value.city,
                    state: value.state,
                    zipCode: value.zipCode,
                    county: value.county
                }
            }))
            return;
        }
        setCustomerData(prev => ({
            ...prev,
            [field]: subfield ? { ...prev[field], [subfield]: value } : value
        }));
        validateField(field,subfield,value);
    };

    // const handleInputChange = (field, subField, value) => {
    //     setCustomerData(prev => ({
    //         ...prev,
    //         [field]: subField ? { ...prev[field], [subField]: value } : value
    //     }));
    //     validateField(field, subField, value);
    // };
    const validateField = (field, subField, value) => {
        if(field == "phoneNumber" || field == "alternateNumber"){
            setCustomerDataErrors(prev => ({
                ...prev,
                [field] : subField? {...prev[field], [subField]: !isValidPhone(value)} : !isValidPhone(value)
            }))
            return;    
        }
        if(field == "email"){
            setCustomerDataErrors(prev => ({
                ...prev,
                [field] : subField? {...prev[field], [subField]: !isValidEmail(value)} : !isValidEmail(value)
            }))
            return;
        }
        if(subField== "zipCode"){
            setCustomerDataErrors(prev => ({
                ...prev,
                [field] : subField? {...prev[field], [subField]: !isValidZipCode(value)} : !isValidZipCode(value)
            }))
            return;
        }
        setCustomerDataErrors(prev => ({
            ...prev,
            [field] : subField? {...prev[field], [subField]: !isMandatoryFieldValid(value)} : !isMandatoryFieldValid(value)
        }))
    }
    const getlocationAutoComplete = async (data) => {
        let suggestions = []
        if(data.length > 8){
            await locationAutoComplete(data).then((listData)=>{                
                suggestions = listData;
            })
        }
        return suggestions.map((item)=>({        
            label: item.description,
            value:item
        }))
    }

    const handleCancelButton = () =>{
        if(!data){
        console.log('2')
        setShouldCancelSave(false);
        setIsEditable(false);
        setShouldEdit(true)
        }
        else{
            onClose()
        }
    }
  return (
    <section className="customer-information-bottom-div">
        <div className="heading-edit-button-div">
          <div className="customer-information-section-heading customer-information-label">
            <button onClick={toggleCustomerDetails}>
                <FontAwesomeIcon icon={isCustomerOpen ? faChevronUp : faChevronDown}/>
                <span className="customer-information-div"> Customer Information</span>
            </button>
          </div>
        </div>
        <div className={`customer-information-bottom-values-div customer-information ${isCustomerOpen ? 'show' : ''}`}>

            <div className = "row">
                <div className="col-md-4 custom-padding">
                   {isOrganizationNameVisible && ( <div className="input-div client-type-div" >
                        <div>Select Organization</div>
                        <Select
                            className="basic-single"
                            classNamePrefix="custom-select"
                            menuPosition="fixed"
                            menuPortalTarget={document.body}
                            options={organizationOptions}
                            value={organizationOptions.find((option) => option.value === organization)}
                            onChange={(option) => {
                                if (option.value === 'New') {
                                    setOrganization('New');
                                } else {
                                fetchOrganisationContact(option.value)
                                
                                }
                            }}
                            filterOption={(candidate, input) => {
                                if (candidate.label === 'New') return true;
                                const label = candidate.label || '';
                                const searchValue = input || '';
                                return label.toLowerCase().includes(searchValue.toLowerCase());
                            }}
                            />
                    </div>)}
                    { !isOrganizationNameVisible && !data && (<div className="input-div client-type-div" >
                        <div>Select Customer</div>
                            <Select
                                    className="basic-single"
                                    classNamePrefix="custom-select"
                                    menuPosition="fixed"  
                                    menuPortalTarget={document.body}  
                                    options={customerOptions}
                                    value = {customerOptions.find((option) => option.value === searchExistingInput.value)}
                                    onChange={(option) => {
                                        setShouldCancelSave(false);
                                        setIsMailingAddressSame(false)
                                        if (option.value === 'New') {
                                            setCustomerData(initialCustomerData);
                                            setShouldEdit(false);
                                            setIsEditable(true);
                                            
                                        } else {
                                            fetchCustomerInfo(option);
                                        }
                                    }}
                                    filterOption={(candidate, input) => {
                                        if (candidate.label === 'New') return true;
                                        const label = candidate.label || '';
                                        const searchValue = input || '';
                                        return label.toLowerCase().includes(searchValue.toLowerCase());
                                    }}
                            />
                    </div>)}
                </div>
                {isOrganizationNameVisible && organization && organization === "New" &&
                (<div className="col-md-4">    
                    <div className="input-div organisation-name-div" >
                        <div>Organization Name</div>
                        <input
                            className="job-details-input"
                            type="text" 
                            onInput={(event) => setCustomerData(prevData => ({
                                ...prevData,
                                OrganisationName: event.target.value
                                }))}
                        />
                    </div>
                </div>)}
                <div className="col-md-4">
                        {organization && organization!=="New" && propertyType===2 && 
                        (<div className="input-div organisation-name-div" >
                            <div>Select Contact</div>
                            <Select
                                className="basic-single"
                                classNamePrefix="custom-select"
                                menuPosition="fixed"  
                                menuPortalTarget={document.body} 
                                options={organisationCustomerOptions}
                                value = {organisationCustomerOptions.find((option) => option.value === searchExistingInput.value)}
                                onChange={(option) => {
                                    if (option.value === 'New') {
                                        setCustomerData(initialCustomerData);
                                        setShouldEdit(false);
                                        setIsEditable(true);
                                        
                                    } else {
                                        fetchCustomerInfo(option);
                                    }
                                }}
                                filterOption={(candidate, input) => {
                                    if (candidate.label === 'New') return true;
                                    const label = candidate.label || '';
                                    const searchValue = input || '';
                                    return label.toLowerCase().includes(searchValue.toLowerCase());
                                }}
                               
                            />
                        </div>)
                    }
                </div>
                <div className="col-md-4">
                { shouldEdit && (<div className="customerinfo-edit-pen">
                        <span class="fa-pen"><FontAwesomeIcon icon="fa-solid fa-pen" onClick={()=>{setIsEditable(true); setShouldEdit(false);setShouldCancelSave(true)}}/></span>
                    </div>)}
                </div>
            </div>

            { isVisible && 
            ( <div>
                <div className = "row">
                <div className="col-md-4">
                    <div className="input-div customer-firstname-div">
                        <div className="label">
                            <span>First Name {customerDataErrors.firstName && <FontAwesomeIcon icon="fa-solid fa-asterisk" />}</span>
                            <div className="invalid_text">{customerDataErrors.firstName? "required":""}</div>
                        </div>
                        
                        <input 
                            className={`job-details-input  ${customerDataErrors.firstName? 'invalid' : ''}`} 
                            value={customerData.firstName} 
                            onChange={(event) => handleInputChange("firstName",null,event.target.value)}
                            onFocus = {(event) => validateField("firstName",null,event.target.value)}
                            type="text" 
                            disabled ={ !isEditable } 
                            />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-div customer-lastname-div">
                        <div className="label">
                            <span>Last Name {customerDataErrors.lastName && <FontAwesomeIcon icon="fa-solid fa-asterisk" />}</span>
                            <div className="invalid_text">{customerDataErrors.lastName? "required":""}</div>
                        </div>
                        <input 
                            className={`job-details-input  ${customerDataErrors.lastName? 'invalid' : ''}`}
                            type="text" 
                            disabled ={ !isEditable }
                            value = {customerData.lastName}
                            onChange = {(event) => handleInputChange("lastName",null,event.target.value)}
                            onFocus = {(event) => validateField("lastName",null,event.target.value)}
                        />
                    </div>
                </div>
                
            </div>

            <div className = "row">
                <div className="col-md-4">
                    <div className="input-div customer-lastname-div">
                        <div className="label">
                            <span>Email Address {customerDataErrors.email && <FontAwesomeIcon icon="fa-solid fa-asterisk" />}</span>
                            <div className="invalid_text">{customerDataErrors.email? "enter valid email":""}</div>
                        </div>
                        <input 
                            className={`job-details-input  ${customerDataErrors.email? 'invalid' : ''}`}
                            type="text" 
                            disabled ={ !isEditable }
                            value={customerData.email}
                            onChange = {(event) => handleInputChange("email",null,event.target.value)}
                            onFocus = {(event) => validateField("email",null,event.target.value)}
                            />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-div mobile-div">
                        <div className="label">
                            <span>Primary Phone Number {customerDataErrors.phoneNumber && <FontAwesomeIcon icon="fa-solid fa-asterisk" />}</span>
                            <div className="invalid_text">{customerDataErrors.phoneNumber? "enter valid number":""}</div>
                        </div>
                        <PhoneInput
                            international
                            defaultCountry="US"
                            value={customerData.phoneNumber}
                            disabled ={ !isEditable } 
                            name = "phoneNumber" 
                            countryCallingCodeEditable={false}
                            displayInitialValueAsLocalNumber
                            onChange={(value) =>
                                handleInputChange('phoneNumber',null,value)
                              }
                            />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-div alternate-number-div">
                        <div className="label">
                            <span>{isOrganizationNameVisible?"Office Phone Number": "Alternate Phone Number"} </span>
                        </div>
                        <PhoneInput
                            international
                            defaultCountry="US"
                            value={customerData.alternateNumber}
                            disabled ={ !isEditable } 
                            name = "alternateNumber" 
                            countryCallingCodeEditable={false}
                            displayInitialValueAsLocalNumber
                            onChange={(value) =>
                                handleInputChange('alternateNumber',null,value)
                                }
                            />
                        
                    </div>
                </div>
            </div>

            
            <div className="side-heading">
                <p><b>Loss Address </b></p>
            </div>
            

            <div className = "row">
                <div className="col-md-4">
                    <div className="input-div email-div">
                        <div className="label">
                            <span>Address 1 {customerDataErrors.lossAddress.address1 && <FontAwesomeIcon icon="fa-solid fa-asterisk"/>}</span>
                            <div class="invalid_text">{customerDataErrors.lossAddress.address1?"required":""}</div>
                        </div>
                        <ReactSelectAsync 
                            className="basic-single"
                            classNamePrefix="custom-select"
                            isDisabled ={!isEditable}
                            value = {customerData.lossAddress? {label:customerData.lossAddress.address1, value:customerData.lossAddress.address1}: null}
                            loadOptions ={getlocationAutoComplete}                            
                            onChange = {(option) => handleInputChange("lossAddress","address1",option? option.value:"")}
                            // onFocus = {(event) => validateField("lossAddress","address1",event.target.value)}
                            />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-div mobile-div">
                        <div className="label">
                            <span>Address 2 {customerDataErrors.lossAddress.address2 && <FontAwesomeIcon icon="fa-solid fa-asterisk"/>}</span>
                            <div class="invalid_text">{customerDataErrors.lossAddress.address2?"required":""}</div>
                        </div>
                        <input 
                            className={`job-details-input  ${customerDataErrors.lossAddress.address2? 'invalid' : ''}`}
                            type="text" 
                            disabled ={ !isEditable }
                            value = {customerData.lossAddress.address2}
                            onChange = {(event) => handleInputChange("lossAddress","address2",event.target.value)}
                            onFocus = {(event) => validateField("lossAddress","address",event.target.value)}
                            />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-div alternate-number-div">
                        <div className="label">
                            <span>Country {customerDataErrors.lossAddress.country && <FontAwesomeIcon icon="fa-solid fa-asterisk"/>}</span>
                            <div class="invalid_text">{customerDataErrors.lossAddress.country?"enter valid country":""}</div>
                        </div>
                        <input 
                            className={`job-details-input  ${customerDataErrors.lossAddress.country? 'invalid' : ''}`}
                            type="text" 
                            disabled ={ true }
                            value = {"USA"}
                            onChange = {(event) => handleInputChange("lossAddress","country",event.target.value)}
                            onFocus = {(event) => validateField("lossAddress","country",event.target.value)}
                            />
                    </div>
                </div>
            </div>

            <div className = "row">
                <div className="col-md-4">
                    <div className="input-div email-div">
                        <div className="label">
                            <span>City {customerDataErrors.lossAddress.city && <FontAwesomeIcon icon="fa-solid fa-asterisk"/>}</span>
                            <div class="invalid_text">{customerDataErrors.lossAddress.city?"enter valid city":""}</div>
                        </div>
                        <input 
                            className={`job-details-input  ${customerDataErrors.lossAddress.city? 'invalid' : ''}`}
                            type="text" 
                            disabled ={ !isEditable }
                            value = {customerData.lossAddress.city}
                            onChange = {(event) => handleInputChange("lossAddress","city",event.target.value)}
                            onFocus = {(event) => validateField("lossAddress","city",event.target.value)}
                            />
                    </div>
                </div>
                <div className="col-md-4">
                <div className="row">
                    <div className="col-6">
                        <div className="input-div">
                            <div className="label">
                                <span>State {customerDataErrors.lossAddress.state && <FontAwesomeIcon icon="fa-solid fa-asterisk"/>}</span>
                                {/* <div class="invalid_text">{customerDataErrors.lossAddress.state?"enter valid":""}</div> */}
                            </div>
                            <input 
                                className={`job-details-input  ${customerDataErrors.lossAddress.state? 'invalid' : ''}`}
                                type="text" 
                                maxLength={2}
                                disabled ={ !isEditable }
                                value = {customerData.lossAddress.state}
                                onChange={ (event) => handleInputChange("lossAddress","state",event.target.value)}
                                onFocus = {(event) => validateField("lossAddress","state",event.target.value)}
                                />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="input-div">
                            <div className="label">
                                <span>Zip Code {customerDataErrors.lossAddress.zipCode && <FontAwesomeIcon icon="fa-solid fa-asterisk"/>}</span>
                                {/* <div class="invalid_text">{customerDataErrors.lossAddress.zipCode?"enter valid":""}</div> */}
                            </div>
                            <input 
                                className={`job-details-input  ${customerDataErrors.lossAddress.zipCode? 'invalid' : ''}`}
                                type="number" 
                                disabled ={ !isEditable }
                                value = {customerData.lossAddress.zipCode}
                                onChange={(event) => handleInputChange("lossAddress","zipCode",event.target.value)}
                                onFocus = {(event) => validateField("lossAddress","zipCode",event.target.value)}
                                />
                        </div>
                    </div>
                </div>
            </div>  
                <div className="col-md-4">
                    <div className="input-div alternate-number-div">
                        <div className="label">
                            <span>County {customerDataErrors.lossAddress.county && <FontAwesomeIcon icon="fa-solid fa-asterisk"/>}</span>
                            <div class="invalid_text">{customerDataErrors.lossAddress.county?"enter valid county":""}</div>
                        </div>
                        <input 
                            className={`job-details-input  ${customerDataErrors.lossAddress.county? 'invalid' : ''}`}
                            type="text" 
                            disabled ={ !isEditable }
                            value = {customerData.lossAddress.county}
                            onChange={(event) => handleInputChange("lossAddress","county",event.target.value)}
                            onFocus = {(event) => validateField("lossAddress","county",event.target.value)}
                            />
                    </div>
                </div>
            </div>
        
            <div className="side-heading email-div">
                <p><b>Mailing Address </b></p>
                <div className="custom-checkbox">
                    <input 
                        className="form-check-input" 
                        type="checkbox" 
                        checked={isMailingAddressSame} 
                        id="flexCheckChecked" 
                        disabled ={ !isEditable }
                        onChange = {(event) => {
                            const val = event.target.checked
                            setIsMailingAddressSame(val)
                            setCustomerData((prev) => ({
                                ...prev,
                                mailingAddressFlag: val 
                            }));
                        }}
                        
                        />
                    <label className="form-check-label" for="flexCheckChecked" > &nbsp; Same as Loss Address</label>
                </div> 
            </div>
           
            <div className = "row">
                <div className="col-md-4">
                    <div className="input-div email-div">
                        <div className="label">
                            <span>Address 1 {customerDataErrors.mailingAddress.address1 && <FontAwesomeIcon icon="fa-solid fa-asterisk"/>}</span>
                            <div class="invalid_text">{customerDataErrors.mailingAddress.address1?"enter valid address1":""}</div>
                        </div>
                        <ReactSelectAsync 
                            className="basic-single"
                            classNamePrefix="custom-select"
                            isDisabled ={ (!isEditable || !isMailingEditable)}
                            value = {customerData.mailingAddress? {label:customerData.mailingAddress.address1, value:customerData.mailingAddress.address1}: null}
                            loadOptions ={getlocationAutoComplete}                            
                            onChange = {(option) => handleInputChange("mailingAddress","address1",option? option.value:"")}
                            // onFocus = {(event) => validateField("lossAddress","address1",event.target.value)}
                            />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-div mobile-div">
                        <div className="label">
                            <span>Address 2 {customerDataErrors.mailingAddress.address2 && <FontAwesomeIcon icon="fa-solid fa-asterisk"/>}</span>
                            <div class="invalid_text">{customerDataErrors.mailingAddress.address2?"enter valid address2":""}</div>
                        </div>
                        <input 
                            className={`job-details-input  ${customerDataErrors.mailingAddress.address2? 'invalid' : ''}`}
                            type="text" 
                            disabled ={ (!isEditable || !isMailingEditable) }
                            value = {customerData.mailingAddress.address2}
                            onChange = {(event) => handleInputChange("mailingAddress","address2",event.target.value)}
                            onFocus = {(event) => validateField("mailingAddress","address2",event.target.value)}
                            />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-div alternate-number-div">
                        <div className="label">
                            <span>Country {customerDataErrors.mailingAddress.country && <FontAwesomeIcon icon="fa-solid fa-asterisk"/>}</span>
                            <div class="invalid_text">{customerDataErrors.mailingAddress.country?"enter valid country":""}</div>
                        </div>
                        <input 
                            className={`job-details-input  ${customerDataErrors.mailingAddress.country? 'invalid' : ''}`}
                            type="text" 
                            disabled ={ true }
                            value = {"USA"}
                            onChange = {(event) => handleInputChange("mailingAddress","country",event.target.value)}
                            onFocus = {(event) => validateField("mailingAddress","country",event.target.value)}
                            />
                    </div>
                </div>
            </div>
            <div className = "row">
                <div className="col-md-4">
                    <div className="input-div email-div">
                        <div className="label">
                            <span>City {customerDataErrors.mailingAddress.city && <FontAwesomeIcon icon="fa-solid fa-asterisk"/>}</span>
                            <div class="invalid_text">{customerDataErrors.mailingAddress.city?"enter valid city":""}</div>
                        </div>
                        <input 
                            className={`job-details-input  ${customerDataErrors.mailingAddress.city? 'invalid' : ''}`}
                            type="text" 
                            disabled ={ (!isEditable || !isMailingEditable) } 
                            value = {customerData.mailingAddress.city}
                            onChange = {(event) => handleInputChange("mailingAddress","city",event.target.value)}
                            onFocus = {(event) => validateField("mailingAddress","city",event.target.value)}
                            />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-6">
                            <div className="input-div ">
                                <div className="label">
                                    <span>State {customerDataErrors.mailingAddress.state && <FontAwesomeIcon icon="fa-solid fa-asterisk"/>}</span>
                                </div>
                                <input 
                                    className={`job-details-input  ${customerDataErrors.mailingAddress.state? 'invalid' : ''}`}
                                    type="text" 
                                    disabled ={ (!isEditable || !isMailingEditable) }
                                    maxLength={2}
                                    value = {customerData.mailingAddress.state}
                                    onChange = {(event) => handleInputChange("mailingAddress","state",event.target.value)}
                                    onFocus = {(event) => validateField("mailingAddress","state",event.target.value)}
                                    />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="input-div ">
                                <div className="label">
                                    <span>Zip Code {customerDataErrors.mailingAddress.zipCode && <FontAwesomeIcon icon="fa-solid fa-asterisk"/>}</span>
                                </div>
                                <input 
                                    className={`job-details-input  ${customerDataErrors.mailingAddress.zipCode? 'invalid' : ''}`}
                                    type="text" 
                                    disabled ={ (!isEditable || !isMailingEditable) }
                                    value = {customerData.mailingAddress.zipCode}
                                    onChange = {(event) => handleInputChange("mailingAddress","zipCode",event.target.value)}
                                    onFocus = {(event) => validateField("mailingAddress","zipCode",event.target.value)}
                                    />
                            </div>
                        </div>
                    </div>
                </div>  

                <div className="col-md-4">
                    <div className="input-div alternate-number-div">
                        <div className="label">
                            <span>County {customerDataErrors.mailingAddress.county && <FontAwesomeIcon icon="fa-solid fa-asterisk"/>}</span>
                            <div class="invalid_text">{customerDataErrors.mailingAddress.county?"enter valid county":""}</div>
                        </div>
                        <input 
                            className={`job-details-input  ${customerDataErrors.mailingAddress.county? 'invalid' : ''}`}
                            type="text" 
                            disabled ={ (!isEditable || !isMailingEditable) }
                            value = {customerData.mailingAddress.county}
                            onChange = {(event) => handleInputChange("mailingAddress","county",event.target.value)}
                            onFocus = {(event) => validateField("mailingAddress","county",event.target.value)}
                            />
                    </div>
                </div>
            </div>
            <div className="cancelSave">
                    { !shouldEdit && shouldCancelSave &&(
                        <div className="button-footer">
                            <button className="cancelButton" onClick = {() => {handleCancelButton()}}>Cancel</button>
                            <button className="saveButton"onClick={() => sendEditedData()}>Save</button>
                        </div>
                    )}
                </div> 
            </div>)}
        </div>
      <div>    
    </div>
  </section>
  );
};

export default  CustomerInformation;
