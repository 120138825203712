import React, { useState, useRef } from 'react';
import ReactSelectAsync from 'react-select/async';
import './SchedularCommons.css';
import { format } from 'date-fns';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import EventModal from './EventModal';

const SchedularDaily = ({ data, addEvent }) => {
    console.log(data)
    const today = new Date();
    const dateRange = Array.from({ length: 11 }, (_, index) => {
        const date = new Date(today);
        date.setDate(today.getDate() - 5 + index);
        return date;
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTimeFrames, setSelectedTimeFrames] = useState({});
    const [isSelecting, setIsSelecting] = useState(false);
    const [currentUserIndex, setCurrentUserIndex] = useState(null);
    const [startIndex, setStartIndex] = useState(null);
    const [selectedTimeFrameObject,setSelectedTimeFrameObject] = useState({})
    const gridRef = useRef(null);

    const getHalfHourIndex = (date) => {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        return hours * 2 + Math.floor(minutes / 30);
    };

    const handleMouseDown = (event, dateIndex, timeIndex, userIndex) => {
        setIsSelecting(true);
        setCurrentUserIndex(userIndex);
        setStartIndex(timeIndex);
        const selectedBlocks = { [userIndex]: { dateIndex, startIndex: timeIndex, endIndex: timeIndex } };

        setSelectedTimeFrames({ ...selectedBlocks });
    };

    const handleMouseMove = (e, dateIndex, timeIndex, userIndex) => {
        if (isSelecting && userIndex === currentUserIndex) {
            const selectedBlocks = { [userIndex]: { dateIndex, startIndex, endIndex: timeIndex } };
            setSelectedTimeFrames({ ...selectedBlocks });
        }
    };

    const handleMouseUp = () => {
        if (isSelecting) {
            setIsSelecting(false);
            logSelectedTimeFrames(selectedTimeFrames);
        }
    };

    const handleMouseLeave = () => {
        if (isSelecting) {
            setIsSelecting(false);
        }
    };

    const logSelectedTimeFrames = (selectedBlocks) => {
        const timeFrames = Object.entries(selectedBlocks).map(([userIndex, { dateIndex, startIndex, endIndex }]) => {
            const selectedDate = dateRange[dateIndex];
            
            const validStartIndex = typeof startIndex === 'number' ? startIndex : 0;
            const validEndIndex = typeof endIndex === 'number' ? endIndex + 1 : validStartIndex + 1;
    
            const startHour = Math.floor(validStartIndex / 2);
            const startMinute = (validStartIndex % 2) * 30;
            const endHour = Math.floor(validEndIndex / 2);
            const endMinute = (validEndIndex % 2) * 30;
    
            return {
                user: data[userIndex].name,
                date: format(selectedDate, 'MM.dd.yyyy'),
                start: `${startHour.toString().padStart(2, '0')}:${startMinute.toString().padStart(2, '0')}`,
                end: `${endHour.toString().padStart(2, '0')}:${endMinute.toString().padStart(2, '0')}`
            };
        })[0];
        
        setIsModalOpen(true);
        setSelectedTimeFrameObject(timeFrames)
    };

    const renderToolTip = (props, value) => {
        return (
            <Tooltip id='tooltip' {...props} className='custom-tooltip'>
                {value}
            </Tooltip> 
        )
    }

    return (
       
        <div className="schedular-daily-view" onMouseUp={handleMouseUp} onMouseLeave={handleMouseLeave}>
            {isModalOpen && (<div className='modal-project-details event-modal' onClick={()=>setIsModalOpen(false)}>
                <div className='modal-project-details-content' onClick={(event)=>{event.stopPropagation()}}>
                    <div className='modal-body'>
                        <EventModal timeFrame = {selectedTimeFrameObject} addEvent = {addEvent} setIsModalOpen={setIsModalOpen}/>
                    </div>
                </div>
            </div>)}
            <div className="row">
                <div className="col-md-3 schedular-left-div">
                    <div className='events-people-search'>
                        <ReactSelectAsync
                            className='basic-single'
                            classNamePrefix={'custom-select'}
                        />
                    </div>
                    <div className='events-people-list'>
                        {data.map((user, idx) => (
                            <div className='events-people-list-item' key={idx}>
                                <div className='user-img'></div>
                                <div className='user-name'>{user.name}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col-md-9 schedular-right-div" ref={gridRef}>
                    <div className='schedular-grid-view'>
                        <div className='daily-header'>
                            {dateRange.map((date, dateIndex) => (
                                <div className='daily-item-bundled' key={dateIndex}>
                                    <div className='daily-header-item'>
                                        <div className='daily-date-item'>
                                            <span>{format(date, 'EEEE').toUpperCase()}</span>
                                            &nbsp; &nbsp;<span>{format(date, 'MM.dd.yyyy')}</span>
                                        </div>
                                    </div>
                                    <div className='daily-timeline-view'>
                                        <div className='daily-timeline-times'>
                                            {Array.from({ length: 48 }, (_, idx) => {
                                                const hours = Math.floor(idx / 2);
                                                const minutes = idx % 2 === 0 ? '00' : '30';
                                                const formattedHour = hours.toString().padStart(2, '0');
                                                const time = `${formattedHour}:${minutes}`;
                                                return (
                                                    <div className='text-holder' key={idx}>
                                                        {time}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {data.map((user, userIndex) => (
                                            <div className='daily-timeline-events' key={userIndex}>
                                                {Array.from({ length: 48 }, (_, idx) => {
                                                    const eventElements = user.events.map((event, eventIndex) => {
                                                        const eventStart = new Date(event.eventStartDate);
                                                        const eventEnd = new Date(event.eventEndDate);
                                                        const startIdx = getHalfHourIndex(eventStart);
                                                        const endIdx = getHalfHourIndex(eventEnd);

                                                        if (idx >= startIdx && idx < endIdx) {
                                                            return (
                                                                <div
                                                                    className='display-event-item'
                                                                    style={{ backgroundColor: event.colorCode, borderRightColor: event.colorCode }}
                                                                    key={eventIndex}
                                                                >
                                                                    
                                                                    {(idx == startIdx && 
                                                                    <OverlayTrigger placement='top' overlay={(props) => renderToolTip(props, "Detailed description of this event")}>
                                                                        <div className='desc' style={{width: `${(endIdx - startIdx)*40}px`}}>
                                                                            <div className='title'>{event.eventDescription}</div> 
                                                                            <div className='time'>{format(eventStart, 'HH:mm') + ' - '+ format(eventEnd, 'HH:mm')}</div>
                                                                        </div>
                                                                    </OverlayTrigger>                                                                    
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    });

                                                    return (
                                                        <div className='daily-timeline-grid' 
                                                             key={idx} 
                                                             onMouseDown={(e) => handleMouseDown(e, dateIndex, idx, userIndex)}
                                                             onMouseMove={(e) => handleMouseMove(e, dateIndex, idx, userIndex)}
                                                        >
                                                            {eventElements}
                                                            {/* Highlight selected blocks */}
                                                            {selectedTimeFrames[userIndex] && selectedTimeFrames[userIndex].dateIndex === dateIndex &&
                                                                <div className='highlighted-selection'
                                                                     style={{
                                                                         gridColumn: `span ${selectedTimeFrames[userIndex].endIndex - selectedTimeFrames[userIndex].startIndex}`,
                                                                         gridRow: `span 1`,
                                                                         backgroundColor: 'rgba(0, 123, 255, 0.5)',
                                                                         position: 'absolute',
                                                                         left: `${selectedTimeFrames[userIndex].startIndex * (100 / 48)}%`,
                                                                         width: `${(selectedTimeFrames[userIndex].endIndex - selectedTimeFrames[userIndex].startIndex) * (100 / 48)}%`,
                                                                     }}
                                                                />
                                                            }
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SchedularDaily;
