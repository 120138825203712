import Select from 'react-select';
import ReactSelectAsync from 'react-select/async';
import './PostingEvents.css';
import SchedularDaily from './SchedularUtils/SchedularDaily';
import SchedularWeekly from './SchedularUtils/SchedularWeekly';
import { useEffect, useState } from 'react';
import EventModal from './SchedularUtils/EventModal';
import useAuthService from '../../../services/AuthService';
const PostingEvents = ()=>{
    const views = [{label:"Daily",value:"daily"},{label:"Weekly",value:'weekly'}]
    const [openEventModal,setOpenEventModal] = useState(false)
    const [selectedView,setSelectedView] = useState('daily');
    const [rolesSelection, setRolesSelection] = useState([])
    const [skillSelection,setSkillSelection] = useState([]);
    const {fetchEventRoles, fetchEventSkills} = useAuthService();
      // Generate random event data with startDate and endDate
      const generateRandomEvents = () => {
        // const today = new Date();
        // const people = ["John Doe", "Jane Smith", "Alice Johnson", "Bob Brown", "Mike Davis"];
        // const allEvents = []; // To track all generated events for overlap checking
    
        // const getRandomColorCode = () => {
        //     // Assuming this function generates a random color code
        //     const letters = '0123456789ABCDEF';
        //     let color = '#';
        //     for (let i = 0; i < 6; i++) {
        //         color += letters[Math.floor(Math.random() * 16)];
        //     }
        //     return color;
        // };
    
        // const addDays = (date, days) => {
        //     const result = new Date(date);
        //     result.setDate(result.getDate() + days);
        //     return result;
        // };
    
        // const isOverlapping = (newEventStart, newEventEnd) => {
        //     return allEvents.some(event => {
        //         return (newEventStart < event.eventEndDate && newEventEnd > event.eventStartDate);
        //     });
        // };
    
        // return people.map(person => {
        //     const guaranteedEventStartDate = new Date(today);
        //     guaranteedEventStartDate.setHours(Math.floor(Math.random() * 24)); // Random start hour today
        //     const guaranteedEventEndDate = new Date(guaranteedEventStartDate);
        //     guaranteedEventEndDate.setHours(guaranteedEventEndDate.getHours() + Math.floor(Math.random() * 4) + 1); // Event duration between 1 to 4 hours
            
        //     // Track the guaranteed event
        //     allEvents.push({
        //         eventStartDate: guaranteedEventStartDate,
        //         eventEndDate: guaranteedEventEndDate
        //     });
    
        //     // Generate additional random events (up to 3 more)
        //     const additionalEvents = [];
        //     let additionalCount = Math.floor(Math.random() * 4);
    
        //     while (additionalEvents.length < additionalCount) {
        //         const eventStartDate = addDays(today, Math.floor(Math.random() * 6)); 
        //         eventStartDate.setHours(Math.floor(Math.random() * 24)); // Random start hour
    
        //         const eventEndDate = new Date(eventStartDate);
        //         eventEndDate.setHours(eventEndDate.getHours() + Math.floor(Math.random() * 4) + 1); // Event duration between 1 to 4 hours
    
        //         if (!isOverlapping(eventStartDate, eventEndDate)) {
        //             additionalEvents.push({
        //                 eventStartDate: eventStartDate,
        //                 eventEndDate: eventEndDate,
        //                 eventDescription: `Meeting with ${person}`,
        //                 colorCode: getRandomColorCode()
        //             });
    
        //             // Track the new event
        //             allEvents.push({
        //                 eventStartDate: eventStartDate,
        //                 eventEndDate: eventEndDate
        //             });
        //         }
        //     }
    
        //     // Combine guaranteed event with additional events
        //     return {
        //         name: person,
        //         events: [
        //             {
        //                 eventStartDate: guaranteedEventStartDate,
        //                 eventEndDate: guaranteedEventEndDate,
        //                 eventDescription: `Guaranteed meeting with ${person} today`,
        //                 colorCode: getRandomColorCode()
        //             },
        //             ...additionalEvents,
        //         ]
        //     };
        // });
        return [];
    };
      
    //const data = generateRandomEvents(); // Generate random events for people
    const [data, setData] = useState([
        {
            name: "John Doe",
            events: [
                {
                    eventStartDate: "2024-10-12T06:35:59.168Z",
                    eventEndDate: "2024-10-12T09:35:59.168Z",
                    eventDescription: "Guaranteed meeting with John Doe today",
                    colorCode: "#4FC1C1"
                },
                {
                    eventStartDate: "2024-10-12T03:35:59.168Z",
                    eventEndDate: "2024-10-12T05:35:59.168Z",
                    eventDescription: "Meeting with John Doe",
                    colorCode: "#A57BB4"
                },
                {
                    eventStartDate: "2024-10-17T06:35:59.168Z",
                    eventEndDate: "2024-10-17T10:35:59.168Z",
                    eventDescription: "Meeting with John Doe",
                    colorCode: "#22502D"
                }
            ]
        },
        {
            name: "Jane Smith",
            events: [
                {
                    eventStartDate: "2024-10-12T01:35:59.168Z",
                    eventEndDate: "2024-10-12T05:35:59.168Z",
                    eventDescription: "Guaranteed meeting with Jane Smith today",
                    colorCode: "#5D201E"
                }
            ]
        },
        {
            "name": "Alice Johnson",
            "events": [
                {
                    "eventStartDate": "2024-10-12T04:35:59.168Z",
                    "eventEndDate": "2024-10-12T07:35:59.168Z",
                    "eventDescription": "Guaranteed meeting with Alice Johnson today",
                    "colorCode": "#05ACCE"
                },
                {
                    "eventStartDate": "2024-10-15T02:35:59.168Z",
                    "eventEndDate": "2024-10-15T04:35:59.168Z",
                    "eventDescription": "Meeting with Alice Johnson",
                    "colorCode": "#72B179"
                },
                {
                    "eventStartDate": "2024-10-13T03:35:59.168Z",
                    "eventEndDate": "2024-10-13T04:35:59.168Z",
                    "eventDescription": "Meeting with Alice Johnson",
                    "colorCode": "#4109F7"
                }
            ]
        },
        {
            "name": "Bob Brown",
            "events": [
                {
                    "eventStartDate": "2024-10-11T14:35:59.168Z",
                    "eventEndDate": "2024-10-11T18:35:59.168Z",
                    "eventDescription": "Guaranteed meeting with Bob Brown today",
                    "colorCode": "#16354D"
                }
            ]
        },
        {
            "name": "Mike Davis",
            "events": [
                {
                    "eventStartDate": "2024-10-11T14:35:59.168Z",
                    "eventEndDate": "2024-10-11T16:35:59.168Z",
                    "eventDescription": "Guaranteed meeting with Mike Davis today",
                    "colorCode": "#4099FA"
                },
                {
                    "eventStartDate": "2024-10-14T16:35:59.168Z",
                    "eventEndDate": "2024-10-14T20:35:59.168Z",
                    "eventDescription": "Meeting with Mike Davis",
                    "colorCode": "#AEC15C"
                },
                {
                    "eventStartDate": "2024-10-11T07:35:59.168Z",
                    "eventEndDate": "2024-10-11T11:35:59.168Z",
                    "eventDescription": "Meeting with Mike Davis",
                    "colorCode": "#C2D62A"
                }
            ]
        }
    ]);
    const addEvent = (eventObject) => {
        console.log(eventObject)
        setData((prevData) => {
            // Destructure the event details from eventObject
            const { name, event } = eventObject;
    
            // Find the user in the current data
            const userIndex = prevData.findIndex(user => user.name === name);
    
            // If user exists, add the event
            if (userIndex !== -1) {
                // Create a copy of the current data
                const updatedData = [...prevData];
                // Add the new event to the user's events
                updatedData[userIndex].events.push(event);
                return updatedData;
            } else {
                // If the user doesn't exist, you can choose to either do nothing or add a new user
                return prevData; // or create a new user object
            }
        });
    }
    const getEventRoles = async () => {
        let listOfRoles = [];
        await fetchEventRoles().then((data)=>{
            listOfRoles = data;
        }).catch((err)=>{
            console.err("Not able to fetch list of roles getEventRoles() failed"+err);
        });
        setRolesSelection(listOfRoles.map((item)=>({
            label:item?.name,
            value:item?.id
        })))
        setRolesSelection((prev = [])=>([
            {
                label:'None',
                value:null
            },
            ...prev]
        ))
    }
    const getEventSkills = async () => {
        let listOfSkills = [];
        await fetchEventSkills().then((data)=>{
            console.log(data)
            listOfSkills = data?.skills;
        }).catch((err)=>{
            console.err("Not able to fetch event skills ",err);
        })
        setSkillSelection(listOfSkills);
        setSkillSelection((prev = [])=>([{
            label:'None',
            value:''
        },
        ...prev]))
    }
    useEffect(()=>{
        getEventRoles()
        getEventSkills()
    },[])
    return (
        <div className='EventSection'>
            {openEventModal && (
            <div className='modal-project-details event-modal' onClick={()=>setOpenEventModal(false)}>
                <div className='modal-project-details-content' onClick={(event)=>{event.stopPropagation()}}>
                    <div className='modal-body'>
                        <EventModal timeFrame = {{}} addEvent = {addEvent}/>
                    </div>
                </div>
            </div>)}
            <div className='row'>
                <div className='col-md-3'>
                        <Select
                            className='basic-single'
                            classNamePrefix={'custom-select'}
                            options={skillSelection}
                            placeholder="Search people by Skill"
                            onChange={(option)=>setSelectedView(option.value)}
                        />
                </div>
                <div className='col-md-3'>
                    <Select
                            className='basic-single'
                            classNamePrefix={'custom-select'}
                            options={rolesSelection}
                            placeholder="Search people by Role"
                            onChange={(option)=>setSelectedView(option.value)}
                        />
                </div>
                <div className='col-md-3'>
                    <Select
                            className='basic-single'
                            classNamePrefix={'custom-select'}
                            options={views}
                            defaultValue={views[0]}
                            onChange={(option)=>setSelectedView(option.value)}
                        />
                </div>
                <div className='col-md-3 align-center'>
                    <button className='eventButton' onClick={()=>{setOpenEventModal(true)}}>Create Event</button>
                </div>
            </div>
            {selectedView == 'daily' && (<SchedularDaily data={data} addEvent={addEvent}/>)}
            {selectedView == 'weekly' && (<SchedularWeekly data={data}/>) }
        </div>
    );
}

export default PostingEvents;