import React from "react";
import { useSelector } from "react-redux";
import config from '../config.json';

const useAuthService = () => {
  const userSession = useSelector((state) => state.AuthReducer?.userSession || {});

  const makeRequest = async (url, method, body = null, checkNoContent = true) => {
    try {
      const options = {
        method,
        headers: {
          'Content-Type': 'application/json',
          'X-Session-Token': userSession?.token || ""
        }
      };
      if (body) {
        options.body = JSON.stringify(body);
      }

      const response = await fetch(url, options);
      
      
      if (checkNoContent && response.status === 204) {
        console.log("No content received.");
        return [];
      }
      return await response.json();
    } catch (error) {
      console.error(`Error in ${method} request to ${url}:`, error);
      throw error;
    }
  };

  const login = (username, password) => {
    const url = `${config.backendAPI}/main/login/`;
    return makeRequest(url, 'POST', { email: username, password });
  };

  const logout = () => {
    const url = `${config.backendAPI}/main/logout/`;
    return makeRequest(url, 'POST');
  };

  const updatePassword = (currentPassword, newPassword) => {
    const url = `${config.backendAPI}/main/update_password/`;
    return makeRequest(url, 'POST', { current_password: currentPassword, new_password: newPassword });
  };

  const forgotPassword = (username) => {
    const url = `${config.backendAPI}/main/new_password_email/`;
    return makeRequest(url, 'POST', { user_name: username }, false);
  };

  const projectType = () => {
    const url = `${config.backendAPI}/crm/projectTypes/`;
    return makeRequest(url, 'POST');
  };

  const propertyType = () => {
    const url = `${config.backendAPI}/crm/propertyTypes/`;
    return makeRequest(url, 'POST');
  };

  const referralSources = () => {
    const url = `${config.backendAPI}/crm/referralSources/`;
    return makeRequest(url, 'POST');
  };

  const insuranceCompanies = () => {
    const url = `${config.backendAPI}/crm/insuranceCompany/`;
    return makeRequest(url, 'POST');
  };
  
  const getprojectSubtypes = (value) => {
    const url = `${config.backendAPI}/crm/projectSubtypeClasses/`;
    return makeRequest(url, 'POST',{project_type_id: value});
  }

  const getOrganisationList = () => {
    const url = `${config.backendAPI}/crm/customerOrganizationNames/`;
    return makeRequest(url, 'POST');
  }
  const getContactOrganisationList = () => {
    const url = `${config.backendAPI}/crm/contactOrganizationNames/`;
    return makeRequest(url, 'POST');
  }
  const getCustomerContact = (value) => {
    const url = `${config.backendAPI}/crm/customerUser/`;
    return makeRequest(url, 'POST',{organization_id:value});
  }
  const getResidentialCustomer = () => {
    const url = `${config.backendAPI}/crm/customer/`;
    return makeRequest(url, 'POST');
  }
  const getCustomerInfo = (value) => {
    const url = `${config.backendAPI}/crm/customerInfo/`;
    return makeRequest(url, 'POST',{customer_id:value});
  }
  const editCustomer = (customerData) => {
    const url = `${config.backendAPI}/crm/editUser/`;
    return makeRequest(url, 'POST',customerData);
  }
  const updateCustomer = (customerData) => {
    const url = `${config.backendAPI}/crm/editCustomerUser/`;
    return makeRequest(url, 'POST',customerData);
  }
  const editContactUser = (contactData) => {
    const url = `${config.backendAPI}/crm/editContactUser/`;
    return makeRequest(url, 'POST',contactData);
  }
  const editProjectInformation = (projectData) => {
    const url = `${config.backendAPI}/crm/editProjectDetails/`;
    return makeRequest(url,'POST',projectData)
  }
  const getOrganisationType = ()=>{
    const url = `${config.backendAPI}/crm/organizationTypes/`;
    return makeRequest(url, 'POST');
  }
  const getContactPerson = (value) => {
    const url = `${config.backendAPI}/crm/contactUser/`;
    return makeRequest(url, 'POST',{organization_id:value});
  }
  const getIndividualContactPerson = (value) => {
    const url = `${config.backendAPI}/crm/individualContacts/`;
    return makeRequest(url, 'POST',{organization_id:value});
  }
  const getRoles = () => {
    const url = `${config.backendAPI}/crm/roles/`;
    return makeRequest(url, 'POST');
  }
  const getStaffOrganisation = (value) => {
    const url = `${config.backendAPI}/crm/getOrganizationFromRoles/`;
    return makeRequest(url, 'POST',{role_id:value});
  }
  const addNewRole = (value) => {
    const url = `${config.backendAPI}/crm/addNewRole/`;
    return makeRequest(url, 'POST',{role_name:value});
  }
  const getMembers = (value1,value2) => {
    const url = `${config.backendAPI}/crm/getStaffMember/`;
    
    return makeRequest(url, 'POST',{role_id:value1,org_id:value2});
  }
  const addOrganisation = (organisationData) => {
    const url = `${config.backendAPI}/crm/addNewOrganization/`;
    return makeRequest(url, 'POST',organisationData)
  }
  const getRelationTypes = () => {
    const url = `${config.backendAPI}/crm/relationshipTypes/`;
    return makeRequest(url, 'POST')
  }
  const addContactPerson= (personData) => {
    const url = `${config.backendAPI}/crm/addNewContact/`;
    return makeRequest(url, 'POST', personData)
  }
  const addStaffMember= (staffData) => {
    const url = `${config.backendAPI}/crm/addStaffMember/`;
    return makeRequest(url, 'POST', staffData)
  }
  const getCustomerProjectInfo=(projectId) => {
    const url = `${config.backendAPI}/crm/getProjectCustomerDetails/`;
    return makeRequest(url, 'POST',{projectId})
  }
  const getViewProjectContacts=(projectId) => {
    const url = `${config.backendAPI}/crm/getProjectContacts/`;
    return makeRequest(url, 'POST',{projectId})
  }
  const getViewAssignedStaff=(projectId) => {
    const url = `${config.backendAPI}/crm/getProjectAssignedStaff/`;
    return makeRequest(url, 'POST',{projectId})
  }
  const getTemplates=() => {
    const url = `${config.backendAPI}/crm/getTemplates/`;
    return makeRequest(url, 'GET')
  }
  const addProjectContact = (projectId, contactId) => {
    const url = `${config.backendAPI}/crm/addProjectContact/`;
    return makeRequest(url,'POST',{projectId:projectId, contactId:contactId})
  }
  const deleteProjectContact = (projectId, contactId) => {
    console.log(projectId,contactId)
    const url = `${config.backendAPI}/crm/removeProjectContact/`;
    return makeRequest(url,'POST',{projectId:projectId, contactId:contactId})
  }

  const getProjectList = () => {
    const url = `${config.backendAPI}/crm/getProjectList/`;
    return makeRequest(url, 'POST').then(response => {
      return {data: response};
    });
  };
  const getProjectMilestonesTasks=(projectId) => {
    console.log("get",projectId)
    const url = `${config.backendAPI}/crm/milestone/getProjectMilestonesTasks/`;
    return makeRequest(url, 'POST',{projectId}).then(response => {
      return {data: response};
    });
  }
  const activateMilestones=(projectId) => {
    console.log("activate",projectId)
    const url = `${config.backendAPI}/crm/milestone/activateAllProjectMilestones/`;
    return makeRequest(url, 'POST',{projectId:projectId}).then(response => {
      return {data: response};
    });
  }

  const fetchEventRoles = () => {
    const url = `${config.backendAPI}/crm/roles/`;
    return makeRequest(url,'POST');
  }

  const fetchEventSkills= () => {
    const url = `${config.backendAPI}/crm/skills/`;
    return makeRequest(url,'POST');
  }
  return {
    login,
    logout,
    updatePassword,
    forgotPassword,
    projectType,
    propertyType,
    referralSources,
    insuranceCompanies,
    getprojectSubtypes,
    getOrganisationList,
    getCustomerContact,
    getContactOrganisationList,
    getResidentialCustomer,
    getCustomerInfo,
    editCustomer,
    getOrganisationType,
    getContactPerson,
    getIndividualContactPerson,
    getRoles,
    addNewRole,
    getStaffOrganisation,
    getMembers,
    addOrganisation,
    getRelationTypes,
    addContactPerson,
    editContactUser,
    addStaffMember,
    getCustomerProjectInfo,
    getViewProjectContacts,
    getViewAssignedStaff,
    getTemplates,
    addProjectContact,
    deleteProjectContact,
    getProjectList,
    updateCustomer,
    editProjectInformation,
    getProjectMilestonesTasks,
    activateMilestones,
    fetchEventRoles,
    fetchEventSkills
  };
};

export default useAuthService;
