import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import ReactSelectAsync from "react-select/async";

const EventModal = ({timeFrame, addEvent, setIsModalOpen}) =>{
    console.log(timeFrame);
    // Create Date objects for start and end dates
    const [startDateTime,setStartDateTime] = useState(new Date());
    const [endDateTime,setEndDateTime] = useState(new Date());
    useEffect(()=>{
        // console.log(Object.keys(timeFrame))
        if(Object.keys(timeFrame).length !== 0){
            // Create Date objects for start and end dates
            console.log(timeFrame)
            const startDate = new Date(timeFrame.date);
            const endDate = new Date(timeFrame.date);

            // Assuming timeFrame has startTime and endTime as string in HH:MM format
            const [startHours, startMinutes] = timeFrame?.start.split(':');
            const [endHours, endMinutes] = timeFrame?.end.split(':');

            startDate.setHours(startHours, startMinutes);
            endDate.setHours(endHours, endMinutes);
            setStartDateTime(startDate)
            setEndDateTime(endDate)
        }
        
    },[])
    const formatDateForInput = (date) => {
        return date.toISOString().slice(0, 16); // Format as YYYY-MM-DDTHH:MM
    };
    const [eventObject,setEventObject] = useState({
        name: "",
        start:"",
        end:"",
        description:""
    })
    const setEventObjectData = (field,value) => {
        setEventObject((prev)=>({
            ...prev,
            [field]:value
        }))
    }
    const generateRandomColor = () => {
        const randomColor = Math.floor(Math.random() * 16777215).toString(16);
        return `#${randomColor.padStart(6, '0')}`; // Ensure it has 6 characters
    };
    const scheduleEvent = () => {
        if(!eventObject.name){
            alert("Enter event name in order to schedule an event")
            return;
        }
        const eventData = {
            name:"",
            event:{}
        };
        eventData.name = timeFrame.user;
        eventData.event.eventDescription = eventObject.name;

        // Create Date objects for start and end dates
        const startDate = new Date(timeFrame.date);
        const endDate = new Date(timeFrame.date);

        // Assuming timeFrame has startTime and endTime as string in HH:MM format
        const [startHours, startMinutes] = timeFrame.start.split(':');
        const [endHours, endMinutes] = timeFrame.end.split(':');

        startDate.setHours(startHours, startMinutes);
        endDate.setHours(endHours, endMinutes);

        eventData.event.eventStartDate = startDate.toISOString();
        eventData.event.eventEndDate = endDate.toISOString();
        eventData.event.colorCode = generateRandomColor();
        // Call addEvent with the created eventData
        setIsModalOpen(false)
        addEvent(eventData);
    };

    return (
        <div className="EventModalSection">
            <div className="EventHeading">
                <h2>Create Event</h2>
            </div>
          
            <div className="eventGroup">
                <div className="input-div">
                    <div className="label">Start Date:</div>
                    <input 
                        className="job-details-input" 
                        value={formatDateForInput(startDateTime)} 
                        type='datetime-local' 
                        onChange={(e) => setStartDateTime(new Date(e.target.value))}
                    />
                </div>
                <div className="input-div">
                    <div className="label">End Date:</div>
                    <input 
                        className="job-details-input" 
                        value={formatDateForInput(endDateTime)} 
                        type='datetime-local' 
                        onChange={(e) => setEndDateTime(new Date(e.target.value))}
                    />
                </div>
            </div>
            <div className='eventGroup'>
                <div className="input-div" style={{width:'100%'}}>
                    <div>
                        Event Name: 
                    </div>
                    <input className="job-details-input" onChange={(e)=>setEventObjectData("name",e.target.value)}/>
                </div>
            </div>
                  
            <div className="input-div">
                <div className="label">
                    Attendees: 
                </div>
                
                    <ReactSelectAsync 
                        className="basic-single"
                        classNamePrefix="custom-select"
                    /> 
                    <div className="attendees">
                        <div className="attendee_name">
                            <span className="attendee_text">John Doe</span><FontAwesomeIcon className="fa-cross" icon="fa-solid fa-times" />
                        </div>
                        <div className="attendee_name">
                        <span className="attendee_text">John Sno</span> <FontAwesomeIcon className="fa-cross" icon="fa-solid fa-times" />
                        </div>
                        <div className="attendee_name">
                        <span className="attendee_text">{timeFrame.user}</span> <FontAwesomeIcon className="fa-cross" icon="fa-solid fa-times" />
                        </div>
                    </div>
                
            </div>
            <div className='input-div'>
                <div className="label">
                    Description:
                </div>
                <textarea />
            </div>
            <div className="eventGroupButton">
                <button className="saveButton" onClick={()=>{scheduleEvent()}}> Schedule </button>
            </div>
        </div>
    )
}

export default EventModal;