import React, { useState, useRef } from "react";
import './ImageToolBarSelection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faImages, faFileLines, faShare } from '@fortawesome/free-solid-svg-icons';
import TagsModal from './TagsModel';
import PrintDialog from "./PrintDialog";
import ReactToPrint from 'react-to-print';


const ImageToolBarSelection = ({ imagecount, onToolbarClose, selectedPhotos}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isTagsModalOpen, setIsTagsModalOpen] = useState(false); 
    const dropdownRef = useRef(null);
    const [tags, setTags] = useState([]); // State to store selected tags
    const [layoutOption, setLayoutOption] = useState(1);
    const componentRef = useRef();// This will reference the PrintDialog content
    const printRef = useRef(null);
    const [isPrintOptionsOpen, setIsPrintOptionsOpen] = useState(false); // State for print options dropdown


    // Function to handle mouse enter and leave events
    const handleMouseEnter = () => {
        setIsOpen(true);
    };

    const handleMouseLeave = () => {
        setIsOpen(false);
    };
    const openTagsModal = () => {
        setIsTagsModalOpen(true);
    };

    const closeTagsModal = () => {
        setIsTagsModalOpen(false);
    };

    const handleSaveTags = (selectedTags) => {
        setIsTagsModalOpen(false); 
        setTags(prevTags => [...prevTags, ...selectedTags]);
        
    };
       // Function to trigger print with specific layout
       const handlePrintOptionClick = (layout) => {
        setLayoutOption(layout);
        setTimeout(() => {
            if (printRef.current) {
                printRef.current.handlePrint(); // Call the print method on ReactToPrint component
            }
        }, 0);
    };
    return (
        <>
        <div className="toolbar-overlay">
            <div className="close-icon" onClick={onToolbarClose}>
                <FontAwesomeIcon icon={faTimes} />
                <span className="icon-text">Close</span>
            </div>
            <div className="select-count">
                <span className="icon-text">{imagecount} Selected</span>
            </div>
            <div 
                className="image-icon" onMouseLeave={handleMouseLeave}>
                <FontAwesomeIcon icon={faImages} />
                <span className="icon-text" onClick={() => setIsOpen(!isOpen)}>More Actions</span>
                {isOpen && (
                    <div className="options" ref={dropdownRef} onMouseLeave={handleMouseLeave}>
                        <a href="#" onClick={openTagsModal}>Tag</a>
                        <a href="#">Duplicate</a>
                        <div className="print-option-container">
                            <a href="#" onClick={(e) => { e.preventDefault(); setIsPrintOptionsOpen(!isPrintOptionsOpen); }}>Print</a>
                            {isPrintOptionsOpen &&(
                            <div className="print-options-menu">
                                <a href="#" onClick={(e) => { e.preventDefault(); handlePrintOptionClick(1); }}>1 per Page</a>
                                <a href="#" onClick={(e) => { e.preventDefault(); handlePrintOptionClick(2); }}>2 per Page</a>
                                <a href="#" onClick={(e) => { e.preventDefault(); handlePrintOptionClick(3); }}>4 per Page</a>
                            </div>
                            )}
                        </div>
                        <a href="#">Share</a>
                        <a href="#">Cover Photo</a>
                        <a href="#">Delete</a>
                    </div>
                )}
            </div>

            <div className="file-icon">
                <FontAwesomeIcon icon={faFileLines} />
                <span className="icon-text">Reports</span>
            </div>
            <div className="share-icon">
                <FontAwesomeIcon icon={faShare} />
                <span className="icon-text">Share</span>
            </div>
        </div>
        {isTagsModalOpen && (
                <TagsModal onClose={closeTagsModal}  onSave={handleSaveTags}/>
            )}

        <div style={{ display: 'none' }}>
            <ReactToPrint
                trigger={()=><div></div>} //No trigger needed; handled programmatically
                content={() => componentRef.current}
                onBeforePrint={() => console.log('Preparing to print...')}
                onAfterPrint={() => console.log('Print completed.')}
                ref={printRef}
            />
            <PrintDialog ref={componentRef} selectedPhotos={selectedPhotos} layoutOption={layoutOption}/>
        </div>
        </>
    );
};

export default ImageToolBarSelection;
