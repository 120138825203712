import './ProjectListButtons.css';
const ProjectListButtons = ({activeSection, setActiveSection})=>{
    return (
        <div className="ProjectListButtonsSection">
            <button 
                className={`SectionButton ${activeSection === "lead" ? "active" : ""}`} 
                onClick={() => setActiveSection("lead")} >
                Lead
            </button>
            <button 
                className={`SectionButton ${activeSection === "inqueue" ? "active" : ""}`} 
                onClick={() => setActiveSection("inqueue")} >
                In-Queue
            </button>
            
            <button 
                className={`SectionButton ${activeSection === "active" ? "active" : ""}`} 
                onClick={() => setActiveSection("active")}>
                Active
            </button>
            
            <button 
                className={`SectionButton ${activeSection === "closed" ? "active" : ""}`} 
                onClick={() => setActiveSection("closed")} >
                Closed
            </button>
            <button 
                className={`SectionButton ${activeSection === "lost" ? "active" : ""}`} 
                onClick={() => setActiveSection("lost")} >
                Lost
            </button>
            <button 
                className={`SectionButton ${activeSection === "all" ? "active" : ""}`} 
                onClick={() => setActiveSection("all")}>
                All
            </button>
        </div>
    );
}

export default ProjectListButtons;