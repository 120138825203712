import { useState, useEffect } from 'react';
// import ProjectListData from '../../../data/ProjectListData.json';
// import React from 'react';
import './ProjectList.css';
import { Link } from 'react-router-dom';
import useAuthService from '../../../services/AuthService';

const ProjectList = ({ searchQuery }) => {
  const [projectList, setProjectList] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const authService = useAuthService();

  useEffect(() => {
    authService.getProjectList()
      .then((response) => {
        if (Array.isArray(response.data)) {
          setProjectList(response.data);
        } else {
          console.error('Invalid data format:', response.data);
          setError(response.data);
        }
        setIsLoading(false);
        // console.log(response.data);
    }).catch(error => {
      console.error('Error fetching project list:', error);
      setError(error);
      setIsLoading(false);
    });
  }, [authService]);

  const handleRowClick = (index) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }
  };

  const filteredData = projectList && projectList.length > 0 ? projectList.filter((item) =>
    Object.values(item).some((value) =>
      value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  ) : [];

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {isLoading &&  (<div className={`loader-container`}> <div className={`loader`}></div> </div>)}
      {filteredData.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Project Name</th>
              <th>Claim Number</th>
              <th>Customer Name</th>
              <th>Project Type</th>
              <th>Status</th>
              <th>Address</th>
              <th>Email</th>
              <th>Phone Number</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <>
                <tr
                  key={index}
                  onClick={() => handleRowClick(index)}
                  className={expandedRows.includes(index) ? 'expandedRow' : ''}
                >
                  <td><Link 
                    to="/viewProject"
                    state={{projectId: item.projectId, projectName:item.projectName}}
                    >
                      {item.projectName}
                    </Link>
                  </td>
                  <td>{item.insuranceClaimNumber}</td>
                  <td>{item.customerName}</td>
                  <td>{item.projectTypeName}</td>
                  <td>{item.projectStatusName}</td>
                  <td>{item.address}</td>
                  <td>{item.email}</td>
                  <td>{item.phoneMobile}</td>
                </tr>
                {expandedRows.includes(index) && (
                  <tr className='expandedRowCollapsible'>
                    <td colSpan="8">
                    <div className='row'> 
                    <div className="form-group col-md-4">
                      <p>Subtype</p>
                      <p><strong>{item.subtypeClassName}</strong></p>
                    </div>
                    <div className="form-group col-md-4">
                      <p>Property Type</p>
                      <p><strong>{item.propertyTypeName}</strong></p>
                    </div>
                    <div className="form-group col-md-4">
                      <p>Loss Date</p>
                      <p><strong>{item.lossDate}</strong></p>
                    </div>
                    <div className="form-group col-md-4">
                      <p>Deductible</p>
                      <p><strong>{item.deductible}</strong></p>
                    </div>
                    <div className="form-group col-md-4">
                      <p>Insurance policy</p>
                      <p><strong>{item.insurancePolicy}</strong></p>
                    </div>
                    <div className="form-group col-md-4">
                      <p>Project Contacts</p>
                      <p><strong>{item.contactName}</strong></p>
                    </div>
                    <div className="form-group col-md-4">
                      <p>Sandbox</p>
                      <p><strong>{item.sandbox}</strong></p>
                    </div>
                    </div>
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No data available.</p>
      )}
    </div>
  );
};

export default ProjectList;
