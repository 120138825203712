import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faTag } from '@fortawesome/free-solid-svg-icons';
import './PostingMedia.css'; // Make sure to link the CSS file
import { useState, useEffect,useRef } from 'react';
import { formatDateWithSuffix } from '../../utils/dateUtils';
import PhotoModal from './PhotoModal'; // Import the Modal component
import ImageToolBarSelection from './ImageToolBarSelection';
import TagsModal from './TagsModel';

const PostingMedia = () => {
    const dropdownRef = useRef(null);
    const [selectedFolderIndex, setSelectedFolderIndex] = useState(0);
    const [selectedFolder, setSelectedFolder] = useState("All");
    const [photos, setPhotos] = useState({ sortedDates: [], groupedPhotos: {} });
    const [selectedPhotos, setSelectedPhotos] = useState(new Set());
    const [tags, setTags] = useState(["2nd Floor Bathroom", "stairs", "2nd floor Hallway", "garage"]); // Example tags
    const [modalData, setModalData] = useState({ imageUrl: null, imageIndex: null, allImages: [] }); // State to handle modal image, index, and all images
    const [imageSelection,setImageToolBar] = useState(false)
    const [selectCount, setImageSelectCount] = useState(0);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isImageDropdownOpen, setIsImageDropdownOpen] = useState(false);
    const [isTagsModalOpen, setIsTagsModalOpen] = useState(false); 
    
    // State for selected button types
    const [selectedType, setSelectedType] = useState('All');
    const [selectedOrder, setSelectedOrder] = useState('Newest');

    const chooseFolderIndex = (idx) => {
        setSelectedFolderIndex(idx);
        setSelectedFolder(`${idx === 0 ? 'All' : `Folder ${idx}`}`);
    };

    useEffect(() => {
        const loadPhotos = () => {
            const context = require.context('./photos', false, /\.(jpg|jpeg|png)$/);
            const photosData = context.keys().map(key => ({
                url: context(key),
                uploadDate: new Date() // Replace with actual upload date if available
            }));

            // Group photos by date
            const groupedPhotos = photosData.reduce((acc, photo) => {
                const dateKey = formatDateWithSuffix(photo.uploadDate);
                if (!acc[dateKey]) {
                    acc[dateKey] = [];
                }
                acc[dateKey].push(photo.url);
                return acc;
            }, {});
            const sortedDates = Object.keys(groupedPhotos).sort((a, b) => new Date(b) - new Date(a));

            return { sortedDates, groupedPhotos };
        };

        const { sortedDates, groupedPhotos } = loadPhotos();
        setPhotos({ sortedDates, groupedPhotos });
    }, []);

    const handleCheckboxChange = (photoUrl) => {
        setSelectedPhotos(prev => {
            const newSelectedPhotos = new Set(prev);
            if (newSelectedPhotos.has(photoUrl)) {
                newSelectedPhotos.delete(photoUrl);
            } else {
                newSelectedPhotos.add(photoUrl);
            }
            setImageToolBar(newSelectedPhotos.size>0)
            setImageSelectCount(newSelectedPhotos.size)
            return newSelectedPhotos;    
        });
    };

    const handleTagClick = (tag) => {
        console.log(`Tag clicked: ${tag}`);
    };

    const openModal = (photoUrl, photoIndex) => {
        const allImages = photos.sortedDates.flatMap(date => photos.groupedPhotos[date]);
        setModalData({ imageUrl: photoUrl, imageIndex: photoIndex, allImages });
    };

    const closeModal = () => {
        setModalData({ imageUrl: null, imageIndex: null, allImages: [] });
    };

    const closeToolbar = () => {
        setImageToolBar(false); 
        setSelectedPhotos(new Set());
        setImageSelectCount(0); 
    }
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };


   
   const handleEllipse = (idx) => {
    setIsImageDropdownOpen(prevState => ({
        ...prevState,
        [idx]: !prevState[idx], 
    }));
};
    const handleTypeClick = (type) => {
        setSelectedType(type);
    };

    // Function to handle Order By button click
    const handleOrderClick = (order) => {
        setSelectedOrder(order);
    };

    const openTagsModal = (idx) => {
        setIsTagsModalOpen(true);
        setIsImageDropdownOpen(prev => ({ ...prev, [idx]: false }));
    };

    const closeTagsModal = () => {
        setIsTagsModalOpen(false);
    };

    const handleSaveTags = (selectedTags) => {
        setIsTagsModalOpen(false); 
        setTags(prevTags => [...prevTags, ...selectedTags]);
        
    };

    return (
        <div className="PostingMediaSection">
            <div className='row'>
                {imageSelection &&(
            <ImageToolBarSelection imagecount={selectCount} onToolbarClose={closeToolbar} selectedPhotos={Array.from(selectedPhotos)}/>)}
                <div>
                    <button className="create-filters" onClick={toggleDropdown}>
                        Filter
                    </button>
                </div>
                {isDropdownOpen && (
                    <div className="filter-options" onMouseLeave={toggleDropdown}> 
                        <span className="filter-headers">Type</span>
                        <div className='image-type'>
                            <button
                                className={`button-type ${selectedType === 'All' ? 'active' : ''}`}
                                onClick={() => handleTypeClick('All')}
                            >
                                All
                            </button>
                            <button
                                className={`button-type ${selectedType === 'Photos' ? 'active' : ''}`}
                                onClick={() => handleTypeClick('Photos')}
                            >
                                Photos
                            </button>
                            <button
                                className={`button-type ${selectedType === 'Videos' ? 'active' : ''}`}
                                onClick={() => handleTypeClick('Videos')}
                            >
                                Videos
                            </button>
                        </div>
                        <hr className="break-page-list" />
                        <span className="filter-headers">Order By</span>
                        <div className='image-type'>
                            <button
                                className={`button-type ${selectedOrder === 'Newest' ? 'active' : ''}`}
                                onClick={() => handleOrderClick('Newest')}
                            >
                                Newest
                            </button>
                            <button
                                className={`button-type ${selectedOrder === 'Oldest' ? 'active' : ''}`}
                                onClick={() => handleOrderClick('Oldest')}
                            >
                                Oldest
                            </button>
                        </div>
                    </div>
                )}
                <div className='col-md-3'>
                    <div className='file-manager-left'>
                        <div className='file-manager-upload-button'>
                            <button className='upload-button'>Upload Photo(s)</button>
                        </div>
                        <div className='tags-section'>
                            {tags.map((tag, index) => (
                                <button key={index} className='tag-button' onClick={() => handleTagClick(tag)}>
                                    <FontAwesomeIcon icon={faTag} className='tag-icon' />
                                    {tag}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='col-md-9'>
                    <div className='file-manager-right'>
                        {photos.sortedDates && photos.sortedDates.length > 0 ? (
                            photos.sortedDates.map((date, dateIndex) => (
                                <div key={dateIndex} className='date-group'>
                                    <h4 className='date-heading'>{date}</h4>
                                    <div className='photo-grid'>
                                        {photos.groupedPhotos[date].map((photoUrl, idx) => {
                                            const photoIndex = photos.sortedDates.flatMap(date => photos.groupedPhotos[date]).indexOf(photoUrl);
                                            return (
                                                <div key={idx} className='photo-item' onMouseLeave={ ()=> setIsImageDropdownOpen(prev => ({ ...prev, [idx]: false }))} >
                                                    <input
                                                        type='checkbox'
                                                        id={`checkbox-${idx}`}
                                                        checked={selectedPhotos.has(photoUrl)}
                                                        onChange={() => handleCheckboxChange(photoUrl)}
                                                        className='photo-checkbox-input'
                                                    />
                                                    <FontAwesomeIcon className='ellipses' icon={faEllipsisV} onClick={() => handleEllipse(idx)} />
                                                    {isImageDropdownOpen[idx] && (
                                                    <div className="ellipse-options" ref={dropdownRef}>
                                                        <a href="#" onClick={()=>openTagsModal(idx)}>Tag </a>
                                                        <a href="#">Duplicate</a>
                                                        <a href="#" >Share</a>
                                                        <a href="#">Cover Photo</a>
                                                        <a href="#">Delete</a>
                                                    </div>
                                                    )}
                                                    <label htmlFor={`checkbox-${idx}`} className='photo-overlay'></label>
                                                    <img
                                                        src={photoUrl}
                                                        alt={`Photo ${idx}`}
                                                        className='photo-icon'
                                                        onClick={() => openModal(photoUrl, photoIndex)} // Open modal on image click
                                                    />
                                                </div>
                                               
                                            );
                                        })}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No photos available</p>
                        )}
                    </div>
                </div>
            </div>
            {isTagsModalOpen && (
                <TagsModal onClose={closeTagsModal}  onSave={handleSaveTags}/>
            )}

            {modalData.imageUrl && (
                <PhotoModal
                    imageUrl={modalData.imageUrl}
                    imageIndex={modalData.imageIndex}
                    allImages={modalData.allImages} 
                    onClose={closeModal}
                />
            )}

        </div>
    );
};

export default PostingMedia;
