// src/LoginView.js

import React, { useState } from 'react';
import logo from "../botlogo.png";
import './Login.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'; 
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useAuthService from '../services/AuthService';
import { setUserSession } from '../Redux/Reducers/AuthReducer';

const LoginView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const {login} = useAuthService();
  const [load,setLoad] = useState(false);
  const [userText, setUserText] = useState('');
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoad(true);
    await login(username,password)
    .then((data) => {
      //console.log(data);
      if(data && data.token){
        //console.log({token: data.token, update_password: data.update_password});
        navigate('/viewProjectList');
        dispatch(setUserSession({token: data.token, update_password: data.update_password}));        
      }
      else{
        setUserText("Invalid credentials");
      }
      setLoad(false);
    })
    .catch((error)=>{ setUserText("Not able to login", error); setLoad(false);});
  };


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleForgotPassword=()=>{
    navigate('/forgotPassword')
  }

  return (
    <div className="login-view">
      <img className="logo" src={logo} alt="Company Logo" />
      {load && (<div className={`loader-container`}> <div className={`loader`}></div> </div>)}
      <div className="login-box">
        <div className="login-title">Log in to your account</div>
        <span className='warning'>{userText}</span>
        {/* {error && <div className="error-message">{error}</div>} */}
        <form onSubmit={handleSubmit}>
          <label className="loginLabel" htmlFor="username">Username</label>
          <input 
            className='loginInput'
            type="text" 
            id="username" 
            name="username" 
            required 
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />

          <label className="loginLabel" htmlFor="password">Password</label>
          <div className="password-container">
            <input 
              className='loginInput'
              type={showPassword ? "text" : "password"}
              id="password" 
              name="password" 
              required 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span 
              onClick={togglePasswordVisibility} 
              className="show-password-icon"
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </span>
          </div>

          <div className="wrap">
            <button className="loginSubmit" type="submit" disabled={false}>
              {false ? 'Loading...' : 'Submit'}
            </button>
          </div>
        </form>
        <p>
          <a href="#" style={{ textDecoration: 'none' }} onClick={handleForgotPassword}>
            Forgot password?
          </a>
        </p>
      </div>
    </div>
  );
};

export default LoginView;
