import React, { useState, useRef, useEffect } from 'react';
import './ProjectInfo.css'; // Assuming you are using a separate CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import config from '../../../config.json';
import useAuthService from '../../../services/AuthService';

const ProjectInfo = ({initialHeight=400,data,projectId}) => {
    console.log(projectId)
    const { getProjectMilestonesTasks } = useAuthService();
    const [projectDetailsInfo, setProjectDetailsInfo] = useState([]);
    const [height, setHeight] = useState(initialHeight);
    const [tasks, setTasks] = useState([]);
    const [isLoadingTasks, setIsLoadingTasks] = useState(true);
    const [errorTasks, setErrorTasks] = useState(null);


    useEffect(()=>{
        setHeight(initialHeight);
    },[initialHeight])

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                setIsLoadingTasks(true);
                const { data: milestonesData } = await getProjectMilestonesTasks(projectId);
                const allTasks = milestonesData.reduce((acc, milestone) => {
                    if (milestone.tasks && milestone.tasks.length > 0) {
                        return acc.concat(milestone.tasks);
                    }
                    return acc;
                }, []);
                setTasks(allTasks);
            } catch (error) {
                setErrorTasks(error);
            } finally {
                setIsLoadingTasks(false);
            }
        };
        fetchTasks();
    }, [projectId]);

    useEffect(()=>{
        setProjectDetailsInfo([
            { name: "Project Type:", value: data?.projectTypeName || 'N/A' },
            {name:"Project Status:", value:data?.projectStatus || 'N/A'},
            {name:"Property Type:", value:data?.propertyTypeName || 'N/A'},
            {name:"Address:",value:data?.projectAddressLine1 + ', '+ data?.projectCity+  ', ' + data?.projectState + ' '+ data?.projectZipCode},
            {name:"Referral Source:",value:data?.referralSource || 'N/A'},
            {name:"Insurance Company:", value:data?.insuranceCompany || 'N/A'},
            {name:"Insurance Policy:",value:data?.insurancePolicy || 'N/A'},
            {name:"Deductible:", value:data?.deductible || 'N/A'},
            {name:"Insurance Claim Number:",value:data?.insuranceClaim || 'N/A'},
            {name:"Loss Adjuster Reference:",value:data?.lossAdjuster || 'N/A'},
            {name:"Sandbox:",value:data?.miscNotes || 'N/A'}
        ]);
    },[data])

    // console.log(projectDetailsInfo);
    
    const containerRef = useRef(null);

    
    const handleMouseDown = (e) => {
        e.preventDefault();
        const startY = e.clientY;
        const startHeight = containerRef.current.offsetHeight;
        
        const handleMouseMove = (e) => {
            const newHeight = startHeight + (e.clientY - startY);
            setHeight(newHeight);
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const openAddress = (name,value)=>{
        if(name==='Address:')
            window.open(`${config.googleSearchAPI}${value}`);
    }
    return (
        <div className="ProjectDetailsSection">
            <div className={`resizable-container transition`}  ref={containerRef} style={{ height }}>
                <div className="resizable-content">
                    <div className = 'row SectionSplit'>
                        <div className='col-md-6 '>
                            <div className='SectionSplitHeading'> Project Details</div>
                            <div className = 'SectionSplitContent'>
                                <div className='row'>
                                {
                                projectDetailsInfo.map((item,index)=>(
                                    <div className='col-md-4'>
                                        <div className='project-details-heading'>
                                            {item.name}
                                        </div>
                                        <div className={`project-details-value ${item.name === 'Address:'? 'address':''}`} onClick={()=>openAddress(item.name,item.value)}>
                                            {item.value}
                                        </div>
                                    </div>
                                ))
                                }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="SectionSplitHeading">Tasks</div>
                            <div className="SectionSplitContent">
                                {isLoadingTasks && <div> Loading Tasks... </div>}
                                {errorTasks && <div> Error fetching tasks </div>}
                                {!isLoadingTasks && !errorTasks && tasks.length === 0 && <div>No tasks available</div>}
                                {!isLoadingTasks && !errorTasks && tasks.map((task) => (                                
                                <div className="task-item" key={task.taskId}>                                    
                                    <FontAwesomeIcon icon="fa-solid fa-check" />                                                                    
                                    {task.taskName}                                                  
                                </div>
                                ))}                                                              
                            </div>
                        </div>                                      
                    </div>
                    
                </div>
                <div className="resize-handle" onMouseDown={handleMouseDown}>
                    <div className="resize-handler-inside">

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProjectInfo;

