import React, { useState,useRef,useEffect } from "react";
import logo from "../../logo.jpg";
import './ProjectHeader.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUserSession } from "../../Redux/Reducers/AuthReducer";
import useAuthService from '../../services/AuthService';
import { faUser } from '@fortawesome/free-solid-svg-icons'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ProjectHeader = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {logout} = useAuthService();
    const [load,setLoad] = useState(false)
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu)
    };

    const handleupdatePassword=()=>{
        navigate('/updatePassword')
    }

    const handleLogout = async () => {
        try {
            // Assuming clearUserSession involves async operations
            setLoad(true);
            logout().then((data) => {
              if((data && data.logout) || data.errors){
                dispatch(setUserSession({}));
                localStorage.removeItem('reduxState');
                navigate('/'); 
              }
              setLoad(false);
            }).catch((error) => {
                setLoad(false);
                console.error("Not able to logout ", error);
            })
            

        } catch (error) {
            // Handle any errors that occurred during logout
            console.error('Logout failed:', error);
            // Optionally, provide user feedback here
        }
    };

    return (
        <div className="projectHeader">
          {load && (<div className={`loader-container`}> <div className={`loader`}></div> </div>)}
            <img className="logo" src={logo} alt="Company Logo" />
            
            <div className="profileMenu" onMouseLeave={toggleMenu}>
               <div>
                <button className="profileIcon" onClick={toggleMenu}>
                    <FontAwesomeIcon icon={faUser} className="profileIconSpace" /> My Account
                </button>
                {showMenu && (
                    <div className="dropdownMenu">
                    <button onClick={handleupdatePassword}>Update Password</button>
                    <button onClick={handleLogout}>Logout </button>
                    </div>
                )}
                </div>
            </div>
        </div>
            
    );
}

export default ProjectHeader;
