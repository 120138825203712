import React, { useState } from 'react';
import logo from "../botlogo.png";
import './Login.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useAuthService from '../services/AuthService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const ForgotPasswordView = () => {
  const [email,setEmail]= useState('')
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {forgotpassword} = useAuthService();



  const handleSubmit=async(event)=>{
    event.preventDefault();
    await forgotpassword(email)
    .then((data) => {console.log(data)
    })

  }
  return(
    <div className="login-view">
      <img className="logo" src={logo} alt="Company Logo" />
      <div className="login-box">
        <div className="login-title"><FontAwesomeIcon icon="fa-solid fa-arrow-left" onClick={()=>(navigate('/'))}/>&nbsp;&nbsp;Forgot Password</div>
        <form >
          <p>Enter the email address associated with your account and we'll send you a link to reset password.</p>
          <label className="loginLabel" htmlFor="username">Enter email</label>
          <input 
            className='loginInput'
            type="text" 
            id="email" 
            name="email" 
            required 
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="wrap">
            <button className="loginSubmit" type="submit" onClick = {handleSubmit} disabled={false}>
              {false ? 'Loading...' : 'Submit'}
            </button>
          </div>
        </form>
      </div>
    </div>

  )
}
export default ForgotPasswordView;
