import { useState,useEffect } from 'react';
import CustomerInformation from '../../CreateProject/CustomerInformation';
import ProjectInformation from '../../CreateProject/ProjectInformation';
import './PostingDetails.css'
import ProjectContacts from '../../CreateProject/ProjectContacts';
import AssignedStaff from '../../CreateProject/AssignedStaff';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrash,faPen } from '@fortawesome/free-solid-svg-icons';
import useAuthService from '../../../services/AuthService';

const PostingDetails = ({data,retrieveData, projectId}) => {
    
    const {getViewProjectContacts, getViewAssignedStaff,addProjectContact,deleteProjectContact} = useAuthService();
    const [projectDetails, setProjectDetails]= useState({})
    const [projectContactDetails, setProjectContactDetails]= useState([]);
    const [assignedStaffDetails, setAssignedStaffDetails]= useState([]);
    const [propertyType,setPropertyType] = useState();
    const [showModal, setShowModal] = useState(false);
    const [editableComponent,setEditableComponent] = useState('')
    const [projectContactToEdit,setProjectContactToEdit] = useState({});
    const [editProjectContact,setEditProjectContact] = useState(false)
    useEffect(()=>{
        setProjectDetails(data)
    },[data])


    const fetchProjectContacts = async () => {
        const types = await getViewProjectContacts(projectId); 
        console.log(types);
        setProjectContactDetails(types);
    };

    useEffect(() => {
        fetchProjectContacts(); 
        fetchAssignedStaff();  
    }, [projectId]);


    const fetchAssignedStaff = async () => {
        const types = await getViewAssignedStaff(projectId); 
        setAssignedStaffDetails(types)
        console.log(types)
    };
    
    const getPropertyType = (value)=>{
        setPropertyType(value);
    }

    const openModal = (value) => {
        if(value === 'customer_info'){
            setShowModal(true);
            setEditableComponent('customer_info')
        }
        else if(value === 'project_info'){
            setShowModal(true)
            setEditableComponent('project_info')
        }
        else if(value === 'project_contacts'){
            setShowModal(true)
            setEditableComponent('project_contacts')
        }
        else if(value === 'staff_info'){
            setShowModal(true)
            setEditableComponent('staff_info')
        }
            
    }
    function transformData(input) {
        return input.map(item => ({
            pcOrganizationName:item.organizationName|| "",
            pcFirstName: item.firstName || "",
            pcLastName: item.lastName || "",
            pcRelationshipType: item.relationshipType || "",
            pcEmail: item.email || "",
            pcPhone: item.address1 || "",
            pcAlternatePhone: item.address2 || "",
            pcAddressLine1: item.city || "",
            pcAddressLine2: item.county || "",
            pcCity: item.state || ""
        }));
    }
    const appendAddedContacts = (contactId,contacts) => {
        if(contacts.length > 0){
            addProjectContact(projectId,contactId).then((res)=>{
                fetchProjectContacts(projectId)
            }).catch((err) => {
                console.log(err);   
            })
            setProjectContactDetails((prev) => [...prev,...transformData(contacts)]) 
            setShowModal(false);     
        }
    };
    const handleDelete = async (item, index) => {
        const res = await deleteProjectContact(projectId, item.pcId);
        setProjectContactDetails(prevDetails => 
            prevDetails.filter((_, idx) => idx !== index)
        );
    };

    const handleDeleteStaff = async (item, index) => {
       console.log(item,index)
       const res = await deleteProjectContact(projectId, item.staffMemberId);
       console.log(res)
       setAssignedStaffDetails(prevDetails => 
        prevDetails.filter((_, idx) => idx !== index)
    );
    };
    


    const lossAddressObj = projectDetails?.customer_info?.address?.find(addr => addr.Loss)?.Loss;


    const appendAddedStaff = (staffMember, listOfAssignedStaff) => {
        // console.log(staffMember,listOfAssignedStaff);
        setShowModal(false);
        // if(assignedStaffList.length > 0){
        addProjectContact(projectId,staffMember?.value).then((res)=>{
            fetchAssignedStaff()
        }).catch((err) => {
            console.log(err);   
        })
        setAssignedStaffDetails((prev) => ([...prev,...listOfAssignedStaff]))
        setShowModal(false)
    }

    const handleEditProjectContact = (projectContact) => {
        // console.log("Hi I am being called")
        setEditProjectContact(true);
        setProjectContactToEdit(projectContact);      
    }

    return (
        <div className='PostingDetailsSection' >
            {showModal && (
            <div className='modal-project-details' onClick={()=>setShowModal(false)}>
                 
                <div className='modal-project-details-content' onClick={(event)=>{event.stopPropagation()}}>
                <span className="close" onClick={()=>setShowModal(false)}>&times;</span>
                    <div className='modal-body'>
                        { editableComponent === 'customer_info' && (
                            <CustomerInformation 
                                data={projectDetails.customer_info || "NA"} 
                                onClose={() => {
                                    setShowModal(false);
                                    retrieveData()
                                    }}
                                projectId = {projectId}
                                />
                            )}
                        { editableComponent === 'project_info' &&(<ProjectInformation data={projectDetails} sendPropertyType={getPropertyType} onClose={()=>{setShowModal(false); retrieveData()}} projectId = {projectId}/>)}
                        {editableComponent === 'staff_info' && (<AssignedStaff showAddedStaff = {false} sendAddedStaff = {appendAddedStaff}/>)}
                        {editableComponent ==='project_contacts' && <ProjectContacts showAddedContacts={false} sendAddedContacts={appendAddedContacts} onlyViewEditProjectContact={false} projectContactToEdit={projectContactToEdit} setShowModal = {setEditProjectContact}/>}
                    </div>
                </div>
            </div>)}
            {editProjectContact && <ProjectContacts showAddedContacts={false} sendAddedContacts={appendAddedContacts} onlyViewEditProjectContact={true} projectContactToEdit={projectContactToEdit} setShowModal = {setEditProjectContact}/>}
            <div className='row'>
                <div className='col-md-6 section-left'>
                    <div className='section-heading'>
                        Customer Information
                        <FontAwesomeIcon icon="fa-solid fa-pen" onClick={() => openModal('customer_info')}/>
                    </div>
                    <div className="row project-details-row">
                        <div className='col-md-4'>
                            <div className="project-info-item">
                                <span className="item-heading"> Property Type </span>
                                <span className='item-value'>{projectDetails?.propertyTypeName || 'N/A'} </span>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="project-info-item">
                                <span className="item-heading"> First Name </span>
                                <span className='item-value'>{projectDetails?.customer_info?.firstName || 'N/A'} </span>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="project-info-item">
                                <span className="item-heading"> Last Name </span>
                                <span className='item-value'>{projectDetails?.customer_info?.lastName || 'N/A'} </span>
                            </div>
                        </div>
                    </div>                
                    <div className='row project-details-row'>
                        <div className='col-md-4'>
                            <div className="project-info-item">
                                <span className="item-heading">Email Address </span>
                                <span className='item-value'>{projectDetails?.customer_info?.email || 'N/A'} </span>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="project-info-item">
                                <span className="item-heading"> Primary Phone Number</span>
                                <span className='item-value'>{projectDetails?.customer_info?.phoneNumber || 'N/A'} </span>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="project-info-item">
                                <span className="item-heading"> Alternate Phone Number </span>
                                <span className='item-value'>{projectDetails?.customer_info?.alternateNumber || 'N/A'} </span>
                            </div>
                        </div>
                    </div>
                    <div className='row project-details-row'>
                        <div className='col-md-6'>
                            <div className="project-info-item">
                                <span className="item-heading"> Loss Address:  </span>
                                <span className='item-value address'>
                                    {lossAddressObj 
                                        ? `${lossAddressObj.address1},  ${lossAddressObj.city}, ${lossAddressObj.state} ${lossAddressObj.zipCode}`
                                        : "N/A"}
                                    </span>

                            </div>
                        </div>         
                        <div className='col-md-6'>
                            <div className="project-info-item">
                                <span className="item-heading"> Mailing Address:  </span>
                                <span className='item-value address'> 
                                {projectDetails?.customer_info?.address.find(addr => addr.Mailing) 
                                        ? `${projectDetails.customer_info.address.find(addr => addr.Mailing).Mailing.address1},  ${projectDetails.customer_info.address.find(addr => addr.Mailing).Mailing.city}, ${projectDetails.customer_info.address.find(addr => addr.Mailing).Mailing.state} ${projectDetails.customer_info.address.find(addr => addr.Mailing).Mailing.zipCode}`
                                        : "N/A"}
                                </span>
                            </div>
                        </div>   
                          
                    </div>                    
                </div>
                <div className='col-md-6'>
                    <div className='section-heading'>
                        Project Information
                        <FontAwesomeIcon icon="fa-solid fa-pen" onClick={()=>{openModal('project_info')}}/>
                    </div>
                    <div className="row project-details-row">
                        <div className='col-md-3'>
                            <div className="project-info-item">
                                <span className="item-heading"> Project Type </span>
                                <span className='item-value'> {projectDetails?.projectTypeName ?? 'N/A'}</span>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="project-info-item">
                                <span className="item-heading"> Project Subtype </span>
                                <span className='item-value'> {projectDetails?.projectSubtypeClassName ?? "N/A"} </span>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="project-info-item">
                                <span className="item-heading">Referral Source</span>
                                <span className='item-value'> {projectDetails?.referralSource ?? "N/A"}</span>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="project-info-item">
                                <span className="item-heading"> Loss Data</span>
                                <span className='item-value'> {projectDetails?.lossDate ?? "N/A"}</span>
                            </div>
                        </div> 
                        
                    </div>
                                        
                    <div className="project-details-subheading">Insurance Information:</div>
                    <div className='row project-details-row'>
                        <div className='col-md-4'>
                            <div className="project-info-item">
                                <span className="item-heading">Company Name</span>
                                <span className='item-value'> {projectDetails?.insuranceCompany ?? "N/A"}</span>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="project-info-item">
                                <span className="item-heading"> Insurance Policy</span>
                                <span className='item-value'>{projectDetails?.insurancePolicy ?? "N/A"}</span>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="project-info-item">
                                <span className="item-heading"> Claim Number </span>
                                <span className='item-value'>{ projectDetails?.insuranceClaim ?? "N/A"} </span>
                            </div>
                        </div>
                    </div>
                    <div className='row project-details-row'>
                        <div className='col-md-4'>
                            <div className="project-info-item">
                                <span className="item-heading">Deductible</span>
                                <span className='item-value'>{projectDetails?.deductible ?? "N/A"}</span>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="project-info-item">
                                <span className="item-heading">Miscellenous Note</span>
                                <span className='item-value'> {projectDetails?.miscNotes ?? "N/A"}</span>
                            </div>
                        </div>
                    </div>                
                </div>
            </div>
            <br/>
            <div className='row'>
                <div className='section-heading'>
                    Project Contacts             
                    <button className='add' onClick={()=> openModal('project_contacts')}> Add </button>      
                </div>
                <div className='row project-contacts'>
                    <table>
                        <tr>
                            <th>Relation</th>
                            <th>Relation Type</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>Address</th>
                            <th style={{textAlign:'center'}}>Edit/Remove</th>
                        </tr>
                    
                        {projectContactDetails.map((item,idx)=>(
                            <tr>
                            <td> {item.pcOrganizationName || 'N/A'}</td>
                            <td> {item.pcRelationshipType|| 'N/A'}</td>
                            <td> {item.pcFirstName|| 'N/A'}</td>
                            <td> {item.pcLastName|| 'N/A'}</td>
                            <td> {item.pcEmail|| 'N/A'}</td>
                            <td> {item.pcPhone|| 'N/A'}</td>
                            <td> {item.pcAddressLine1|| 'N/A'+', '+item.pcCity+item.pcState|| 'N/A'}</td>
                            <td>
                                <button className = "delete"  onClick={() => handleDelete(item,idx)}>
                                    <FontAwesomeIcon icon={faTrash} size="sm"></FontAwesomeIcon>
                                </button>
                                <span className="separator"> | </span>
                                <button className = "delete" onClick={()=>{handleEditProjectContact(item)}}>
                                    <FontAwesomeIcon icon={faPen}  size="sm"></FontAwesomeIcon>
                                </button>

                            </td>
                            </tr>
                        ))}
                        
                    </table>
                </div>
            </div>
            <br/>
            
            <div className='row'>
                <div className='section-heading'>
                    Assigned Staff         
                    <button className='add' onClick = {()=> openModal('staff_info')}> Add </button>       
                </div>
                <div className='row project-contacts'>
                    <table>
                        <tr>
                            <th>Staff Role</th>
                            <th>Organization</th>
                            <th>Staff Member</th>
                            <th style={{textAlign:'center'}}>Remove</th>                    
                        </tr>
                    
                        {assignedStaffDetails.map((item,idx)=>(
                            <tr>
                            <td> {item.roleName || 'N/A'}</td>
                            <td> {item.organizationName || 'N/A'}</td>
                            <td> {item.staffMemberName }</td>
                            <td> 
                            <button className = "delete"  onClick={() => handleDeleteStaff(item,idx)}>
                                    <FontAwesomeIcon icon={faTrash} size="sm"></FontAwesomeIcon>
                                </button> </td>
                            </tr>
                        ))}
                    
                    </table>
                </div>
            </div>
        </div>
    );
}

export default PostingDetails;