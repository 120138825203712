import React, { useState } from 'react';
import './schedular.css'; // Import your CSS file
import { dailyData, weeklyData, monthlyData } from './generateRandomData'


const Schedular = () => {
  const [view, setView] = useState('daily'); // Default view
  const [selectedPerson, setSelectedPerson] = useState(null); // Initially no person selected

  // Get all schedules for the current view
  const getSchedules = () => {
    switch (view) {
      case 'daily':
        return dailyData;
      case 'weekly':
        return weeklyData;
      case 'monthly':
        return monthlyData;
      default:
        return [];
    }
  };

  // Get the schedules for the selected person or all people if none selected
  const schedules = selectedPerson
    ? getSchedules().filter(person => person.name === selectedPerson)
    : getSchedules();

  return (
    <div className="SchedulerWrapper">
      <div className="peopleList">
        {getSchedules()?.map(person => (
          <div
            key={person.name}
            onClick={() => setSelectedPerson(person.name)}
            className={selectedPerson === person.name ? 'active' : ''}
          >
            {person.name}
          </div>
        ))}
        <div
          onClick={() => setSelectedPerson(null)} // Show all people’s events
          className={selectedPerson === null ? 'active' : ''}
        >
          All
        </div>
      </div>
      <div className="scheduleView">
        <div className="viewSelector">
          <button onClick={() => setView('daily')}>Daily</button>
          <button onClick={() => setView('weekly')}>Weekly</button>
          <button onClick={() => setView('monthly')}>Monthly</button>
        </div>
        <div className="gridSchedule">
          <h2>{selectedPerson ? `Schedule for ${selectedPerson}` : 'All Schedules'}</h2>
          <div className="gridContainer">
            {schedules.flatMap(person => person.events).map((event, index) => (
              <div
                key={index}
                className="gridItem"
                style={{ backgroundColor: event.color }}
              >
                <h4>{event.title}</h4>
                <p><strong>Venue:</strong> {event.venue}</p>
                <p><strong>Start:</strong> {event.startDate} {event.startTime}</p>
                <p><strong>End:</strong> {event.endDate} {event.endTime}</p>
                <p><strong>Attendees:</strong> {event.attendees.join(', ')}</p>
              </div>
            )) || <div className="gridItem">No events</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schedular;
