import React, { useState } from 'react';
import './ShareModal.css'; 

const ShareModal = ({ fileLink, closeModal }) => {
  const [link, setLink] = useState(fileLink); 
  const [taggedUsers, setTaggedUsers] = useState(''); 
  const [isCopied, setIsCopied] = useState(false);
  
  // Predefined names array
  const names = ['John Doe','John Cooper', 'Patrick Jane', 'Alice'];
  const [suggestions, setSuggestions] = useState([]); // State for suggestions

  const copyLink = () => {
    navigator.clipboard.writeText(link);
    setIsCopied(true);
  };

  const handleShare = () => {
    if (taggedUsers) {
      console.log(`Sharing with: ${taggedUsers}`);
    }
    closeModal();
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setTaggedUsers(value);

    // Check if the last character is '@'
    if (value.endsWith('@')) {
      setSuggestions(names); // Show all names if '@' is typed
    } else {
      // Hide suggestions if there's no '@' or after
      if (value.includes('@')) {
        const input = value.split('@').pop(); // Get the part after '@'
        const filteredSuggestions = names.filter(name => name.toLowerCase().includes(input.toLowerCase()));
        setSuggestions(filteredSuggestions);
      } else {
        setSuggestions([]); // Clear suggestions if there's no '@'
      }
    }
  };

  const selectSuggestion = (name) => {
    setTaggedUsers(taggedUsers.replace(/@\w*$/, `@${name} `)); // Replace the last username with the selected name
    setSuggestions([]); // Clear suggestions after selection
  };

  return (
    <div className="modal">
      <div className="content">
        <h2>Share File</h2>
        <div className="link-container">
          <input type="text" value={link} readOnly />
          <button onClick={copyLink}>{isCopied ? 'Copied!' : 'Copy Link'}</button>
        </div>

        <div className="tag-input-container">
          <input 
            className='tag-text'
            type="text" 
            placeholder="Tag people with @name" 
            value={taggedUsers} 
            onChange={handleInputChange} 
          />
          {suggestions.length > 0 && (
            <ul className="suggestions-list">
              {suggestions.map((name, index) => (
                <li key={index} onClick={() => selectSuggestion(name)}>
                  {name}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="modal-footer">
          <button onClick={closeModal}>Cancel</button>
          <button onClick={handleShare}>Share</button>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
