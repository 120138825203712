import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faTrash} from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CreateProject.css'; 
import Select from 'react-select';
import AddRole from "./CreateProjectModal/AddRole";
import AddNewOrganisation from "./CreateProjectModal/AddNewOrganisation";
import AddNewPerson from "./CreateProjectModal/AddNewPerson";
import useAuthService from '../../services/AuthService';


const AssignedStaff = ({showAddedStaff = true, sendAddedStaff}) => {
    const {getRoles,addNewRole,getStaffOrganisation,getMembers,addStaffMember} = useAuthService();
    const [isStaffOpen, setIsStaffOpen] = useState(true);
    const [roles, setRoles] = useState([]);
    const [organizations, setOrganisations] = useState({});
    const [organizationOptions, setOrganisationOptions] = useState([]);
    const [staffOptions,setStaffOptions] = useState([])
    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedOrganisation, setSelectedOrganisation] = useState(null);
    const [selectedStaffMember, setSelectedStaffMember] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [assignedStaffList, setAssignedStaffList] = useState([]);
    const [isNewRole, setIsNewRole] = useState(null);
    const[isNewOrganisation,setIsNewOrganisation] = useState(null)
    const[isNewMember,setIsNewMember] = useState(null)
    const newOption = { value: 'New', label: 'New' };
    const [roleAdded, setRoleAdded] = useState(false);
    const [tempStore, settempStore] = useState({

    })

    

    
    useEffect(() => {
        const fetchRoles = async () => {
          const types = await getRoles();  
          const formattedOptions = types.map(type => ({
            value: type.id,
            label: type.name,
          }));
          const updatedOptions = [newOption, ...formattedOptions];
          setRoles(updatedOptions);
        };
        fetchRoles();
        setRoleAdded(false)
      }, [roleAdded]);


      const fetchStaffOrganisation = async (value) => {
        const types = await getStaffOrganisation(value);  
        const formattedOptions = types.map(type => ({
          value: type.id,
          label: type.name,
        }));
        const updatedOptions = [newOption, ...formattedOptions];
        setOrganisationOptions(updatedOptions)
      };

      const sendMemberDetails = async (staffData) => {
        const types = await addStaffMember(staffData);  
      };


      const fetchStaffMembers = async (value1,value2) => {
        const types = await getMembers(value1,value2);  
        const formattedOptions = types.map(type => ({
          value: type.id,
          label: type.name,
        }));
       const updatedOptions = [newOption, ...formattedOptions];
       setStaffOptions(updatedOptions)
      };


      const sendNewRole = async (value) => {
        const types = await addNewRole(value);  
        setRoleAdded(true)
      };

    
    const handleRoleChange = (selectedOption) => {
        settempStore((prevStore) => ({
            ...prevStore,     
            roleId: selectedOption.value  
        }));
        setSelectedRole(selectedOption);
        fetchStaffOrganisation(selectedOption.value)
        setSelectedOrganisation('')
    };
    const handleDeleteRow = (index) => {
        const updatedList = assignedStaffList.filter((_, i) => i !== index);
        setAssignedStaffList(updatedList); 
    };

    const handleOrganisationChange = (selectedOption) => {
        setSelectedOrganisation(selectedOption);
        if(selectedOption.value == 'New'){
            setIsNewOrganisation(true)
        }
        else{
            fetchStaffMembers(selectedRole.value,selectedOption.value)
            settempStore((prevStore) => ({
                ...prevStore,     
                orgId: selectedOption.value  
            }));
        }
        setSelectedStaffMember('')
    };

    const handleMemberChange = (selectedOption) => {
        settempStore((prevStore) => ({
            ...prevStore,     
            memeberId: selectedOption.value  
        }));
        setSelectedStaffMember(selectedOption);
        if(selectedOption.value=="New"){
            setIsNewMember(true)
        }
    };
    const saveModal = (roleDetails) => {
        setIsNewRole(false)
        setIsNewMember(false)
        setIsNewOrganisation(false)
        
        
        if (selectedRole && selectedRole.value==='New'){
            sendNewRole(roleDetails)
            setSelectedRole('')
        }
        else if (selectedOrganisation && selectedOrganisation.value==='New'){
            console.log(roleDetails)
            setOrganisationOptions((prevOptions) => [
                ...prevOptions,
                { label: roleDetails.organizationName, value: roleDetails.orgId }
            ]);
            setSelectedOrganisation('')
        }
        else if(selectedStaffMember && selectedStaffMember.value==='New'){ 
            settempStore((prevStore) => ({
                ...prevStore,     
                ...roleDetails    
            }));
            setStaffOptions((prevOptions)=>[
                ...prevOptions,
                {label:roleDetails.firstName,value:roleDetails.firstName}
            ])
            setSelectedStaffMember('')
        }
    };
    useEffect(()=>{
        console.log(tempStore)
    },[tempStore])


    
    const closeModal = () => {
        setIsNewMember(false)
        setIsNewRole(false)
        setIsNewOrganisation(false)
        if (selectedRole && selectedRole.value=='New'){
            setSelectedRole('')
        }
        else if (selectedOrganisation && selectedOrganisation.value=='New'){
            setSelectedOrganisation('')
        }
        else if(selectedStaffMember && selectedStaffMember.value=='New'){
            setSelectedStaffMember('')
        }
       
    };


    const handleAddStaff = () => {
        sendMemberDetails(tempStore)
        if (selectedRole && selectedOrganisation && selectedStaffMember) {
            const newStaff = {
                roleName: selectedRole.label,
                organizationName: selectedOrganisation.label,
                staffMemberName: selectedStaffMember.label,
            };
            setAssignedStaffList(prevList => {
                const updatedList = [newStaff, ...prevList];
                if (!showAddedStaff) {
                    console.log(updatedList); 
                    sendAddedStaff(selectedStaffMember, updatedList);
                }
                return updatedList; 
            });
            setSelectedRole(null);
            setSelectedOrganisation(null);
            setSelectedStaffMember(null);
            setIsDisabled(false);
        } else {
            console.error("Please select a role, organization, and staff member.");
        }
        settempStore('')
        setStaffOptions([])
        setOrganisationOptions([])
    };

    const toggleStaffDetails = () => {
        setIsStaffOpen(!isStaffOpen);
    };
    // useEffect(()=>{
    //     // console.log(showAddedStaff,assignedStaffList)
    //     sendAddedStaff(selectedStaffMember, assignedStaffList);
    //     setSelectedStaffMember(null)
    // },[assignedStaffList])
    return (
        <section className="customer-information-bottom-div">
            <div className="heading-edit-button-div">
                <div className="staff-information-section-heading staff-information-label">
                    <button onClick={toggleStaffDetails}>
                        <FontAwesomeIcon icon={isStaffOpen ? faChevronUp : faChevronDown} />
                        <span className="assigned-staff-div"> Assigned Staff</span>
                    </button>
                </div>
            </div>

            <div className={`staff-information-bottom-values-div staff-information ${isStaffOpen ? 'show' : ''}`}>
            {showAddedStaff && assignedStaffList.length > 0 && <div className="table-container">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Staff Role</th>
                                    <th>Organization</th>
                                    <th>Staff Member</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {assignedStaffList.map((staff, index) => (
                                    <tr key={index}>
                                        <td>{staff.role}</td>
                                        <td>{staff.organization}</td>
                                        <td>{staff.member}</td>
                                        <td>
                                            <button className = "delete" onClick={() => handleDeleteRow(index)}>
                                                <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}

                <div className="row">
                    <div className="col-md-3">
                        <div className="input-div staff-role-type-div">
                            <div className="label">Staff Role</div>
                            <Select
                                className="basic-single"
                                classNamePrefix="custom-select"
                                value={selectedRole}
                                onChange={(option) => {
                                    if (option.value === 'New') {
                                        setSelectedRole(option);
                                        setIsNewRole(true)
                                    } else {
                                        handleRoleChange(option)
                                    }
                                }}
                                menuPosition="fixed"
                                menuPortalTarget={document.body}
                                options={roles}
                                filterOption={(candidate, input) => {
                                    if (candidate.label === "New") return true;
                                    const label = candidate.label || '';
                                    const searchValue = input || '';
                                    return label.toLowerCase().includes(searchValue.toLowerCase());
                                  }}
                            />
                            <AddRole  show={isNewRole}  handleSave={saveModal} handleClose={closeModal}></AddRole>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="input-div staff-subcontractor-type-div">
                            <div className="label"> Organization</div>
                            <Select
                                className="basic-single"
                                classNamePrefix="custom-select"
                                value={selectedOrganisation}
                                menuPosition="fixed"
                                menuPortalTarget={document.body}
                                options={organizationOptions}
                                onChange={handleOrganisationChange}
                                isDisabled={isDisabled}
                                filterOption={(candidate, input) => {
                                    if (candidate.label === "New") return true;
                                    const label = candidate.label || '';
                                    const searchValue = input || '';
                                    return label.toLowerCase().includes(searchValue.toLowerCase());
                                  }}
                            />
                             <AddNewOrganisation  handleSave={saveModal} handleClose={closeModal} show={isNewOrganisation} ></AddNewOrganisation>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="input-div staff-member-div">
                            <div className="label">Staff Member</div>
                            <Select
                                className="basic-single"
                                classNamePrefix="custom-select"
                                menuPosition="fixed"
                                value = {selectedStaffMember}
                                menuPortalTarget={document.body}
                                onChange={handleMemberChange}
                                options={staffOptions}
                                filterOption={(candidate, input) => {
                                    if (candidate.label === "New") return true;
                                    const label = candidate.label || '';
                                    const searchValue = input || '';
                                    return label.toLowerCase().includes(searchValue.toLowerCase());
                                  }}
                            />
                            <AddNewPerson show={isNewMember} handleSave={saveModal} handleClose={closeModal} ></AddNewPerson>
                        </div>
                    </div>

                    <div className="col-md-1">                        
                        <div className="addButton">
                            <button onClick={handleAddStaff}>Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AssignedStaff;
