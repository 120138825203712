import React, { useState } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import useAuthService from '../services/AuthService';
import { useDispatch } from 'react-redux';
import { setUserSession } from '../Redux/Reducers/AuthReducer';

const UpdatePasswordView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {updatepassword} = useAuthService();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [userText, setUserText] = useState('');
  const [load,setLoad] = useState(false);

  const togglePasswordVisibility = (name) => {
    if(name==="current"){
      setShowCurrentPassword(!showCurrentPassword)
    }
    else if(name==="new"){
      setShowNewPassword(!showNewPassword);
    }
    
    else{
      setShowConfirmPassword(!showConfirmPassword)
    }
  };


  const handleSubmit = async(e) => {
    e.preventDefault(); 
    if (password !== confirmpassword) {
      setUserText("Passwords do not match!");
      return;
    }
    else{
      setLoad(true);
        await updatepassword(currentPassword,password)
        .then((data) =>{
          if(data && data.token){
            console.log(data.token)
            navigate('/viewProjectList');
            dispatch(setUserSession({token: data.token, update_password: false}));
          }
          else{
            setUserText("Invalid current password",data);
          }
          setLoad(false); 
        }).catch((error) => {
          setUserText("unable to change the password... Try sometime later"+error);
          setLoad(false);
        })
    }
  };

  return (
    <div className="login-view">
      {load && (<div className={`loader-container`}> <div className={`loader`}></div> </div>)}
      <div className="login-box">
     
       
        <div className="login-title" >
           <FontAwesomeIcon  icon="fa-solid fa-arrow-left" onClick={()=>(navigate('/viewProjectList'))}/>
        <div className="update-password">Update your password</div>
        </div>
        <form onSubmit={handleSubmit}>
        <label className="loginLabel" htmlFor="password">Current Password</label>
        <span className='warning'>{userText}</span>
          <div className="password-container">
            <input
              className='loginInput'
              type={showCurrentPassword ? "text" : "password"}
              id="current_password"
              name="current_password"
              required
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <span
              onClick={()=>togglePasswordVisibility("current")}
              className="show-password-icon"
            >
              <FontAwesomeIcon icon={showCurrentPassword ? faEyeSlash : faEye} />
            </span>
          </div>
          <label className="loginLabel" htmlFor="password">New Password</label>
          <div className="password-container">
            <input
              className='loginInput'
              type={showNewPassword ? "text" : "password"}
              id="password"
              name="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span
              onClick={()=>togglePasswordVisibility("new")}
              className="show-password-icon"
            >
              <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye} />
            </span>
          </div>

          <label className="loginLabel" htmlFor="confirmPassword">Confirm Password</label>
          <div className="password-container">
            <input
              className='loginInput'
              type={showConfirmPassword ? "text" : "password"}
              id="confirmPassword"
              name="confirmPassword"
              required
              value={confirmpassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <span
              onClick={()=>togglePasswordVisibility("confirm")}
              className="show-password-icon"
            >
              <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
            </span>
          </div>
          <div className="wrap">
            <button className="loginSubmit" type="submit" onClick = {handleSubmit} disabled={false}>
              {false ? 'Loading...' : 'Submit'}
            </button>
          </div>

        </form>
      </div>
    </div>
  );
};

export default UpdatePasswordView;
