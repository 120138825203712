import React, { useState,useEffect } from "react";
import './Modal.css'; 
import 'react-phone-number-input/style.css'
import ReactSelectAsync from "react-select/async";
import { locationAutoComplete } from "../../../services/locationService";
import Select from 'react-select';
import PhoneInput from 'react-phone-number-input';
import useAuthService from '../../../services/AuthService';





const AddNewOrganisation = ({ show, handleClose,handleSave}) => {
  const {getOrganisationType,addOrganisation} = useAuthService();
  const [organizationTypeOptions, setOrganizationTypeOptions] = useState([]);
  const [organizationDetails, setOrganizationDetails] = useState({
    organizationName: "",
    organizationType:"",
    phoneNumber:"",
    address1: "",
    address2: "",
    country: "",
    city: "",
    state: "",
    zipCode: "",
    county: ""
});

  useEffect(() => {
    const fetchOrganizationType = async () => {
      const types = await getOrganisationType();  
      const formattedOptions = types.map(type => ({
        value: type.id,
        label: type.name,
      }));
      setOrganizationTypeOptions(formattedOptions)
    };
    fetchOrganizationType();
  }, []);


  const sendOrganizationDetails = async (organizationDetails) => {
    const types = await addOrganisation(organizationDetails); 
    setOrganizationDetails((prevDetails) => ({
      ...prevDetails, 
      orgId:  types.organization_id        
  }));
  };

    


    useEffect(()=>{
      console.log(organizationDetails)
    },[organizationDetails])

  
    const handleInputChange = (e) => {
      if(e.target){
        const { name, value } = e.target;
        setOrganizationDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value
        }));
      }
      else{
        setOrganizationDetails((prevDetails) => ({
          ...prevDetails,
          phoneNumber: e,
      }));
      }
    };

  const handleSelectChange = (selectedOption) => {
    setOrganizationDetails((prevDetails) => ({
        ...prevDetails,
        organizationType: selectedOption.value, 
    }));
};

  
    const getlocationAutoComplete = async (data) => {
      let suggestions = []
      if(data.length > 8){
          await locationAutoComplete(data).then((listData)=>{                
              suggestions = listData;
          })
      }
      return suggestions.map((item)=>({        
          label: item.description,
          value:item
      }))
  }
    const handleInputChange1 = (value) => {
      setOrganizationDetails((prev)=>({
        ...prev,
        address1:value.address1,      
        address2: value.address2,
        city: value.city,
        country: value.city,
        state: value.state,
        zipCode: value.zipCode,
        county: value.county
      }))
    }
    const handleCloseClick=() =>{
        setOrganizationDetails({})
        handleClose(organizationDetails)
    }
    const handleSaveClick = async () => {
        await sendOrganizationDetails(organizationDetails)
        handleSave(organizationDetails); 
        setOrganizationDetails('')
    };

    if (!show) {
        return null;
    }

    return (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseClick}>
              &times;
            </span>
            <div className="modal-header">
              <h5>Add New Organization</h5>
            </div>
            <div className="modal-body">
              <div className="form-row">
                <div className="form-group col-md-4">
                  <label>Organization Name</label>
                  <input
                    className="organization-details-input"
                    name="organizationName"
                    type="text"
                    value={organizationDetails.organizationName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-md-4">
                  <div>Organization Type</div>
                  <Select
                    className="basic-single"
                    classNamePrefix="custom-select"
                    menuPosition="fixed"  
                    menuPortalTarget={document.body} 
                    value={organizationTypeOptions.find(option => option.value === organizationDetails.organizationType)} 
                    options={organizationTypeOptions}
                    onChange={(selectedOption) => handleSelectChange(selectedOption)}

                />
                </div>
                <div className="form-group col-md-4">
                <div> Office Phone Number</div>
                <PhoneInput
                  international
                  defaultCountry="US"
                  value={organizationDetails.phoneNumber}
                  countryCallingCodeEditable={false}
                  displayInitialValueAsLocalNumber
                  onChange={(value) => handleInputChange(value)} // Pass the phone number directly
                />

                </div>

              </div>
              <div className="form-row">
                <div className="form-group col-md-4">
                  <div>Address 1</div>
                  <ReactSelectAsync
                    className="basic-single"
                    classNamePrefix={'custom-select'}
                    name="address1"
                    type="text"
                    loadOptions={getlocationAutoComplete}
                    value={organizationDetails.address1?{label: organizationDetails.address1 ,value:organizationDetails.address1}:null}
                    onChange={(option)=>handleInputChange1(option.value)}
                  />
                </div>
                <div className="form-group col-md-4">
                  <div>Address 2</div>
                  <input
                    className="organization-details-input"
                    name="address2"
                    type="text"
                    value={organizationDetails.address2}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-md-4">
                  <div>Country</div>
                  <input
                    className="organization-details-input"
                    name="country"
                    disabled={true}
                    type="text"
                    value={organizationDetails.country || "USA"}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-4">
                  <div>City</div>
                  <input
                    className="organization-details-input"
                    name="city"
                    type="text"
                    value={organizationDetails.city}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-md-4">
                  <div className="row">
                    <div className="col-md-6">
                      <div>State</div>
                      <input
                        className="organization-details-input"
                        name="state"
                        type="text"
                        value={organizationDetails.state}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <div>Zipcode</div>
                      <input
                        className="organization-details-input"
                        name="zipCode"
                        type="text"
                        value={organizationDetails.zipCode}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <div>County</div>
                  <input
                    className="organization-details-input"
                    name="county"
                    type="text"
                    value={organizationDetails.county}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button className="Close" onClick={handleCloseClick}>
                Cancel
              </button>
              <button className="Save" onClick={handleSaveClick}>
                Save
              </button>
            </div>
          </div>
        </div>
      );
    };
    
    export default AddNewOrganisation;