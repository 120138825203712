import React, { useState } from 'react';
import './TagsModel.css'; 
import Select from 'react-select';

const TagsModal = ({ onClose, onSave }) => {
    const [selectedTags, setSelectedTags] = useState([]);

    const options = [
        { value: "Dining Room", label: "Dining Room" },
        { value: "First Floor", label: "First Floor" }
    ];

    const handleTagChange = (selectedOptions) => {
        setSelectedTags(selectedOptions ? selectedOptions.map(option => option.value) : []);
    };

    const handleSave = () => {
        onSave(selectedTags);
    };

    return (
        <div className="tags-modal-overlay">
            <div className="tags-modal-content">
                <span className="close" onClick={onClose}>
                    &times;
                </span>
                <h3 className="tags-header">Add Tags</h3>

                <Select
                    className="basic-single tags"
                    classNamePrefix="custom-select"
                    menuPosition="fixed"
                    menuPortalTarget={document.body}
                    options={options}
                    isMulti={true}
                    onChange={handleTagChange}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />

                <div className="button-footer">
                    <button className="cancelButton" onClick={onClose}> 
                        Cancel
                    </button>
                    <button className="saveButton" onClick={handleSave}> 
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TagsModal;
