export const formatDateWithSuffix = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const day = date.getDate();
    
    // Helper function to get suffix for the day
    const getSuffix = (day) => {
        if (day > 3 && day < 21) return 'th'; // catch 4-20
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    // Format date parts separately
    const formattedMonth = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
    const formattedYear = new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(date);
    const formattedDay = day + getSuffix(day);

    return `${formattedMonth} ${formattedDay}, ${formattedYear}`;
};
