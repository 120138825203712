import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAsterisk, faArrowLeft, faCheck, faClockRotateLeft, faTrash, faFileSignature, faPen, faComments, faUpload, faTimes, faCircleCheck, faFolder, faPlus, faFile } from '@fortawesome/free-solid-svg-icons';
import {  Store } from './Redux/Store';
import { Provider } from 'react-redux';



library.add({faArrowLeft, faAsterisk, faClockRotateLeft, faTrash, faFileSignature, faFile, faComments, faUpload,faCheck,faPlus, faPen, faTimes, faCircleCheck, faFolder});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={Store}>     
    <App />      
  </Provider>
);
