import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './PostingDocuments.css'
import { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const PostingDocuments = ()=>{
    const [selectedFolderIndex,setSelectedFolderIndex] = useState(0);
    const [selectedFolder, setSelectedFolder] = useState("All");
    const chooseFolderIndex = (idx) => {
        setSelectedFolderIndex(idx);
        setSelectedFolder(`${idx==0? 'All': `Folder ${idx}`}`);
    }
    const renderToolTip = (props, value) => {
        return (
            <Tooltip id='tooltip' {...props} className='custom-tooltip'>
                {value}
            </Tooltip> 
        )
    }
    return (
        <div className="PostingDocumentsSection">
            <div className = 'row'>
                <div className='col-md-3'>
                    <div className = 'file-manager-left'>
                        <div className='file-manager-upload-button'>
                            <button class='upload-button'> Upload File(s)</button>
                        </div>
                        <div className='folders-section'>
                            <div className='folder-heading'>
                                FOLDERS
                                <button class='add-folder-button'> + Add </button>
                            </div>
                            <div className='folders-list'>
                                {Array.from({length: 6},(_,idx)=>(
                                <div className = {`folder-item ${selectedFolderIndex === idx? 'selected' : ''}`} 
                                    key = {idx}
                                    onClick={() => chooseFolderIndex(idx)}
                                    >
                                    <FontAwesomeIcon icon='fa-solid fa-folder'/>
                                    <span> {`${idx==0? 'All': `Folder ${idx}`}`} </span>
                                </div>))}
                            </div>
                        </div>
                    </div>                
                </div>
                <div className='col-md-9'>
                    <div className='file-manager-right'>
                        <div className='file-manager-heading'>
                            {selectedFolder}
                            
                        </div>
                        <div className = 'row files-list'>
                            {
                            Array.from({length:10},(_,idx) => (
                                <div className='col-md-3'>
                                    <div className='file-wrapper'>
                                        <div className='file-item'>
                                            <div className='file-img'>
                                                <div className='signature'> 
                                                    signature needed
                                                </div>
                                                <FontAwesomeIcon icon='fa-solid fa-file' />
                                            </div>                                                
                                            <div className='file-details'>
                                                <div className='file-details-name'>
                                                    File Name 1
                                                </div>                                                    
                                                <div className='file-meta-details'>
                                                    <span>uploaded by: </span> <br/>
                                                    <span>created at:</span>
                                                </div>
                                                <div className = 'file-details-footer'>
                                                    <OverlayTrigger placement='top' overlay={(props) => renderToolTip(props, "Upload this file again")}>
                                                        <span className='fa-solid-icons upload-icon'><FontAwesomeIcon icon='fa-solid fa-upload'/></span>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger placement='top' overlay={(props) => renderToolTip(props, 'Display comments')}>
                                                        <span className='fa-solid-icons '><FontAwesomeIcon icon='fa-solid fa-comments'/> {0}</span>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger placement='top' overlay={(props) => renderToolTip(props, 'File revision history')}>
                                                        <span className='fa-solid-icons'><FontAwesomeIcon icon='fa-solid fa-clock-rotate-left'/></span>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger placement='top' overlay={(props) => renderToolTip(props, 'Signatures')}>
                                                        <span className='fa-solid-icons'><FontAwesomeIcon icon='fa-solid fa-file-signature'/></span>
                                                    </OverlayTrigger>                                                        
                                                    <OverlayTrigger placement='top' overlay={(props) => renderToolTip(props, 'Delete')}>
                                                        <span className='fa-solid-icons'><FontAwesomeIcon icon='fa-solid fa-trash'/></span>       
                                                    </OverlayTrigger>                                         
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>                                       
                            ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PostingDocuments;