import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./Reducers/AuthReducer";
import { saveState, loadState } from "./localStorage";

// Load the state from localStorage
const preloadedState = loadState();

export const Store = configureStore({
  reducer: {
    AuthReducer: AuthReducer
  },
  preloadedState, 
});

Store.subscribe(() => {
  saveState(Store.getState());
});
