import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTextHeight, faCropAlt, faTimes, faArrowUp, faCircle, faSquare, faStrikethrough, faArrowsUpDownLeftRight, faCheck, faDroplet } from '@fortawesome/free-solid-svg-icons';
import './Editor.css';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { configureStore } from '@reduxjs/toolkit';



const Editor = ({ imageSrc, onClose }) => {
    const canvasRef = useRef(null);
    const [tool, setTool] = useState(null);
    const [drawing, setDrawing] = useState(false);
    const [dragging, setDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [shapes, setShapes] = useState([]);
    const [imageData, setImageData] = useState(null);
    const [drawPoints, setDrawPoints] = useState([]); 
    const [showTextModal, setShowTextModal] = useState(false);
    const [modalText, setModalText] = useState('');
    const [selectedShapeIndex, setSelectedShapeIndex] = useState(null); 
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
    const [cropEnable,setCropEnable]=useState(false)
    const[cropCancel,setcropCancel]=useState(false)
    const [imageUrl, setImageUrl] = useState(null);
    const cropperRef = useRef(null);
    const [cropDone, setCropDone] = useState(false);
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);
    const [colorChange,setColorChange] = useState('red');
    const [colorDropdown,setColorDropDown] = useState(false);
    const colors = ['red', 'green', 'yellow', 'blue'];
    const [xStateCropDone,setStateCropDone] = useState(false)
    const [xStateCropCancel,setStateCropCancel] = useState(false)


    useEffect(() => {
        console.log(cropDone,cropCancel,imageSrc)
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d', { willReadFrequently: true });
        ctx.lineWidth = 5;
        ctx.strokeStyle = colorChange;
    
        const img = new Image();
        
        if(cropDone){
            img.src = croppedImageUrl
        }
        else if(cropCancel){
            img.src = imageUrl;
        }
        else if(imageSrc){
            img.src = imageSrc;
        }
        
        
        img.onload = () => {
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;
            const imgWidth = img.width;
            const imgHeight = img.height;
    
            const aspectRatio = imgWidth / imgHeight;
            let drawWidth = canvasWidth;
            let drawHeight = canvasWidth / aspectRatio;
    
            if (drawHeight > canvasHeight) {
                drawHeight = canvasHeight;
                drawWidth = canvasHeight * aspectRatio;
            }
    
            const x = (canvasWidth - drawWidth) / 2;
            const y = (canvasHeight - drawHeight) / 2;
    
            ctx.drawImage(img, x, y, drawWidth, drawHeight);
            setImageData(ctx.getImageData(0, 0, canvas.width, canvas.height));
            setImageUrl(canvas.toDataURL('image/png'));
        };
        setCropDone(false)
    
        img.onerror = () => {
            console.error('Failed to load image');
        };
    }, [imageSrc,xStateCropDone, xStateCropCancel]);

    const handleToolChange = (selectedTool) => {
        setTool(selectedTool);
        setDragging(false)
        setDrawing(false)
        setCropEnable(false);
        setShowTextModal(false);
        if (selectedTool === 'crop') {
            setCropEnable(true);
        }
        else if (selectedTool === 'move') {
            // setDragging(true);
            setDrawing(false);
        }
        else if (selectedTool === 'text') {
            setShowTextModal(true);

        }
        else if (selectedTool === 'arrow') {
            setDrawing(true)
            console.log(drawing,dragging)
            const newArrow = {
                type: 'arrow',
                startX: canvasRef.current.width / 4,
                startY: canvasRef.current.height / 4,
                endX: canvasRef.current.width / 4,
                endY: canvasRef.current.height / 4,
                rotation: 0 
            };
            setShapes([...shapes, newArrow]);
            setSelectedShapeIndex(shapes.length);
        }
        setTool(selectedTool);
    };


    const startDrawing = (event) => {
        const { offsetX, offsetY } = event.nativeEvent;
    
        if (tool === 'move') {
            setDrawing(false)
            const clickedShapeIndex = shapes.findIndex(shape => {
                if (shape.type === 'circle') {
                    const distance = Math.sqrt(Math.pow(offsetX - shape.startX, 2) + Math.pow(offsetY - shape.startY, 2));
                    return distance <= shape.radius;
                } else if (shape.type === 'rectangle') {
                    return offsetX >= shape.startX && offsetX <= shape.startX + shape.width &&
                           offsetY >= shape.startY && offsetY <= shape.startY + shape.height;
                } else if (shape.type === 'line') {
                    const dx = shape.endX - shape.startX;
                    const dy = shape.endY - shape.startY;
                    const length = Math.sqrt(dx * dx + dy * dy);
                    const distanceToPoint = Math.abs(dy * offsetX - dx * offsetY + shape.endX * shape.startY - shape.endY * shape.startX) / length;
                    return distanceToPoint < 5; 
                } 
                else if (shape.type === 'text') {
                    const textWidth = shape.text.length * 10; 
                    return offsetX >= shape.x && offsetX <= shape.x + textWidth &&
                           offsetY >= shape.y - 16 && offsetY <= shape.y; 
                }
                return false;
                // 
            });
    
            if (clickedShapeIndex !== -1) {
                // Start dragging the selected shape
                
                setSelectedShapeIndex(clickedShapeIndex);
                const shape = shapes[clickedShapeIndex];
                setDragOffset({
                    x: offsetX - (shape.type === 'text' ? shape.x : shape.startX),
                    y: offsetY - (shape.type === 'text' ? shape.y : shape.startY)
                });
                setDragging(true);
            }
        } else if (tool) {
            setDrawing(true);
            setDragging(false);
            setStartX(offsetX);
            setStartY(offsetY);
            if (tool === 'draw') {
                setDrawPoints([{ x: offsetX, y: offsetY }]);
            }
        }
    };
    useEffect(() => {
        drawOnCanvas();
      }, [shapes]);
    
      const drawOnCanvas = (event = null) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.strokeStyle = colorChange;
        
        if (!drawing && !dragging) return;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        if (imageData) {
            ctx.putImageData(imageData, 0, 0);
        }
        shapes.forEach((shape, index) => {
            console.log(shape)
            ctx.beginPath();
            if (index === selectedShapeIndex && dragging && tool === 'move') {
                console.log(event.nativeEvent.offsetX,dragOffset.x,shape.x )
                const dx = event ? event.nativeEvent.offsetX - (dragOffset.x + (shape.type === 'text' ? shape.x : shape.startX)) : 0;
                const dy = event ? event.nativeEvent.offsetY - (dragOffset.y + (shape.type === 'text' ? shape.y : shape.startY)) : 0;
                console.log(dx,dy)
                if (shape.type === 'text') {
                    shape.x += dx;
                    shape.y += dy;
                }
                if (shape.type === 'line') {
                    shape.startX += dx;
                    shape.startY += dy;
                    shape.endX += dx;
                    shape.endY += dy;
                } else {
                    shape.startX += dx;
                    shape.startY += dy;
                }
    
                setDragOffset({
                    x: event ? event.nativeEvent.offsetX - (shape.type === 'text' ? shape.x : shape.startX) : 0,
                    y: event ? event.nativeEvent.offsetY - (shape.type === 'text' ? shape.y : shape.startY) : 0
                });

            }
            if (shape.type === 'circle') {
                ctx.arc(shape.startX, shape.startY, shape.radius, 0, Math.PI * 2);
            } else if (shape.type === 'rectangle') {
                ctx.rect(shape.startX, shape.startY, shape.width, shape.height);
            } else if (shape.type === 'line') {
                ctx.moveTo(shape.startX, shape.startY);
                ctx.lineTo(shape.endX, shape.endY);
            } else if (shape.type === 'draw') {
                ctx.moveTo(shape.points[0].x, shape.points[0].y);
                for (let i = 1; i < shape.points.length; i++) {
                    ctx.lineTo(shape.points[i].x, shape.points[i].y);
                }
            } else if (shape.type === 'text') {
                ctx.font = 'bold 20px Arial'; 
                ctx.fillStyle = colorChange; 
                ctx.fillText(shape.text, shape.x, shape.y); 
            }
            else if (shape.type === 'arrow') {
                const angle = shape.rotation; 
                const length = 100;
                ctx.save();
                ctx.translate(shape.startX, shape.startY);
                ctx.rotate(angle);
                ctx.beginPath();
                ctx.moveTo(0, 0);
                ctx.lineTo(length, 0);
                ctx.stroke();
    
                ctx.beginPath();
                ctx.moveTo(length - 10, -5);
                ctx.lineTo(length, 0);
                ctx.lineTo(length - 10, 5);
                ctx.fill();
                ctx.restore();
            }
            ctx.stroke();
        });

        if (tool === 'draw' && event) {
            const newPoints = [...drawPoints, { x: event.nativeEvent.offsetX, y: event.nativeEvent.offsetY }];
            setDrawPoints(newPoints);
    
            ctx.beginPath();
            ctx.moveTo(newPoints[0].x, newPoints[0].y);
            for (let i = 1; i < newPoints.length; i++) {
                ctx.lineTo(newPoints[i].x, newPoints[i].y);
            }
            ctx.stroke();
        } else if (tool === 'circle' && event) {
            const radius = Math.sqrt(Math.pow(event.nativeEvent.offsetX - startX, 2) + Math.pow(event.nativeEvent.offsetY - startY, 2));
            ctx.beginPath();
            ctx.arc(startX, startY, radius, 0, Math.PI * 2);
            ctx.stroke();
        } else if (tool === 'rectangle' && event) {
            const width = event.nativeEvent.offsetX - startX;
            const height = event.nativeEvent.offsetY - startY;
            ctx.beginPath();
            ctx.rect(startX, startY, width, height);
            ctx.stroke();
        } else if (tool === 'line' && event) {
            ctx.beginPath();
            ctx.moveTo(startX, startY);
            ctx.lineTo(event.nativeEvent.offsetX, event.nativeEvent.offsetY);
            ctx.stroke();
        }
        setImageUrl(canvas.toDataURL('image/png'));
    };
    

    const endDrawing = (event) => {
        if (tool === 'move' && dragging) {
            setDragging(false);
            setSelectedShapeIndex(null);
        } else if (tool) {
            setDrawing(false);
            const { offsetX, offsetY } = event.nativeEvent;
    
            let newShape = null;
            if (tool === 'draw') {
                newShape = { type: tool, points: drawPoints };
            } else if (tool === 'text') {
                const text = prompt("Enter your text:"); 
                newShape = {
                    type: tool,
                    x: startX,
                    y: startY,
                    text: text || '', 
                };
            } else {
                newShape = {
                    type: tool,
                    startX,
                    startY,
                    endX: tool === 'line' ? offsetX : 0,
                    endY: tool === 'line' ? offsetY : 0,
                    radius: tool === 'circle' ? Math.sqrt(Math.pow(offsetX - startX, 2) + Math.pow(offsetY - startY, 2)) : 0,
                    width: tool === 'rectangle' ? offsetX - startX : 0,
                    height: tool === 'rectangle' ? offsetY - startY : 0,
                };
            }
    
            setShapes([...shapes, newShape]);
        }
    };
    
    
    const handleCrop = () => {
        if (cropperRef.current) {
            const cropper = cropperRef.current.cropper;
            const croppedCanvas = cropper.getCroppedCanvas();
            const s = croppedCanvas.toDataURL('image/png');
            setCroppedImageUrl(s)
        }


    };

    const handleDownload = () => {
        setStateCropDone(!xStateCropDone)
        setCropDone(true)
        setCropEnable(false)
    };
    const handleclose=()=>{
        setStateCropCancel(!xStateCropCancel)
        setCropEnable(false)
        setcropCancel(true)
       
    }
    const handleColorSelect = (color) => {
        setColorChange(color); 
       
    };
    const toggleColorDropdown = () => {
        setColorDropDown(prevState => !prevState);
    };
    
    return (
        <div className="editor-modal-overlay" onClick={onClose}>
            <div className="editor-modal-content" onClick={e => e.stopPropagation()}>
                <div className="editor-container">
                    {!cropEnable && <div className="editor-toolbar">
                        <div className={`toolbar-item ${tool === 'draw' ? 'selected' : ''}`} data-gloss="Draw" onClick={() => handleToolChange('draw')}>
                            <FontAwesomeIcon icon={faStrikethrough} />
                        </div>
                        <div 
                            className={`toolbar-item ${tool === 'color' ? 'selected color' : ''}`} 
                            data-gloss='Color' 
                            onClick={toggleColorDropdown} // Toggle dropdown visibility
                        >
                            <div className="color-picker-container">
                                <FontAwesomeIcon icon={faDroplet} />
                                {colorDropdown && (
                                    <div className="color-picker">
                                        {colors.map(color => (
                                            <div
                                                key={color}
                                                className="color-option"
                                                style={{ backgroundColor: color }}
                                                onClick={() => handleColorSelect(color)} 
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>


                        <div  className={`toolbar-item ${tool === 'line' ? 'selected' : ''}`}  data-gloss="Line" onClick={() => handleToolChange('line')}>
                            <FontAwesomeIcon icon={faPen} />
                        </div>
                        <div className={`toolbar-item ${tool === 'circle' ? 'selected' : ''}`}  data-gloss="Circle" onClick={() => handleToolChange('circle')}>
                            <FontAwesomeIcon icon={faCircle} />
                        </div>
                        <div className={`toolbar-item ${tool === 'rectangle' ? 'selected' : ''}`}  data-gloss="Rectangle" onClick={() => handleToolChange('rectangle')}>
                            <FontAwesomeIcon icon={faSquare} />
                        </div>
                        <div className={`toolbar-item ${tool === 'text' ? 'selected' : ''}`} data-gloss="Text" onClick={() => handleToolChange('text')}>
                            <FontAwesomeIcon icon={faTextHeight} />
                        </div>
                        <div className={`toolbar-item ${tool === 'crop' ? 'selected' : ''}`} data-gloss="Crop" onClick={() => handleToolChange('crop')}>
                            <FontAwesomeIcon icon={faCropAlt} />
                        </div>
                        <div className={`toolbar-item ${tool === 'arrow' ? 'selected' : ''}`} data-gloss="Arrow Up" onClick={() => handleToolChange('arrow')}>
                            <FontAwesomeIcon icon={faArrowUp} />
                        </div>
                        <div
                        className={`toolbar-item ${tool === 'move' ? 'selected move' : ''}`}
                        data-gloss="Move"
                        onClick={() => handleToolChange('move')}
                        >
                        <FontAwesomeIcon icon={faArrowsUpDownLeftRight} /> 
                        </div>

                        <div className="toolbar-item" data-gloss="Close Editor" onClick={onClose}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>}
                    {cropEnable && <div className="editor-toolbar">
                            <div className='toolbar-item' data-gloss = "Done" onClick={handleDownload}>
                                <FontAwesomeIcon icon={faCheck} style={{padding:'1px',boxShadow:'none',color:'#008000'}} />
                            </div>
                            <div className= 'toolbar-item' data-gloss="Close" onClick={()=>setCropEnable(false)}><FontAwesomeIcon icon={faTimes} style={{color:'#ff0000'}} /></div>
                        
                    </div>}
                    {cropEnable && <Cropper
                        ref={cropperRef}
                        src={imageUrl}
                        style={{ height: 800, width: 800 }}
                        initialAspectRatio={9 / 16}
                        crop={handleCrop}
                        />}
                    {!cropEnable && <div className="editor-canvas">
                        <canvas
                            className='canvas'
                            ref={canvasRef}
                            width="800"
                            height="800"
                            onMouseDown={startDrawing}
                            onMouseMove={drawOnCanvas}
                            onMouseUp={endDrawing}
                        />
                         </div>
                    }
                      
                        {showTextModal && (
                        <div className="text-modal-overlay">
                            <div className="text-modal-content">
                            <div className="label">Enter Text</div>
                                <input
                                    type="text"
                                    value={modalText}
                                    onChange={(e) => setModalText(e.target.value)}
                                />
                                 <div className="button-container">
                                    <button onClick={() => {
                                        setDrawing(true);
                                        setShowTextModal(false);
                                        const newShape = {
                                        type: 'text',
                                        text: modalText,
                                        x: 350, 
                                        y: 350, 
                                        };
                                        setShapes([...shapes, newShape]);
                                        setTool(null)
                                    }}>Confirm</button>
                                    <button onClick={() => setShowTextModal(false)}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    )}         
                </div>
            </div>
        </div>
    );
};

export default Editor;
