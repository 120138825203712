import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    userSession : {}
}

const AuthReducer = createSlice({
    name:'AuthReducer',
    initialState,
    reducers:{
        setUserSession:(state,action) => {
            console.log(action.payload)
            state.userSession = action.payload
        }
    }
});

export const {setUserSession, clearUserSession} = AuthReducer.actions;

export default AuthReducer.reducer;

