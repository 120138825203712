import React, { useState } from 'react';
import './PhotoModal.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSearchPlus, faSearchMinus, faExpand, faDownload, faEdit, faChevronLeft, faChevronRight, faTag, faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons';
import Editor from './Editor';
import TagsModal from './TagsModel';

const PhotoModal = ({ imageUrls, imageIndex, allImages, onClose }) => {
    const [currentIndex, setCurrentIndex] = useState(imageIndex || 0);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isEditorOpen, setIsEditorOpen] = useState(false);
    const [isTagsModalOpen, setIsTagsModalOpen] = useState(false); 
    const [tags, setTags] = useState([]); // State to store selected tags
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [dragging, setDragging] = useState(false);
    const [lastPosition, setLastPosition] = useState({ x: 0, y: 0 });


    const handleEdit = () => {
        setIsEditorOpen(true);
    };

    const handleZoomIn = () => {
        setZoomLevel(prevZoom => Math.min(prevZoom + 0.2, 3));
    };

    const handleZoomOut = () => {
        setZoomLevel(prevZoom => Math.max(prevZoom - 0.2, 1));
        if (zoomLevel <= 1.2) {
            setPosition({ x: 0, y: 0 }); // Reset position when zooming out to 1x
        }
    };

    const toggleFullScreen = () => {
        setIsFullScreen(prev => !prev);
    };

    const goToPrevious = () => {
        setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : allImages.length - 1));
    };

    const goToNext = () => {
        setCurrentIndex(prevIndex => (prevIndex < allImages.length - 1 ? prevIndex + 1 : 0));
    };

    const handleDownload = () => {
        const imageUrl = allImages[currentIndex];
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = `image-${currentIndex + 1}.jpg`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const closeEditor = () => {
        setIsEditorOpen(false);
    };

    const openTagsModal = () => {
        setIsTagsModalOpen(true);
    };

    const closeTagsModal = () => {
        setIsTagsModalOpen(false);
    };

    const handleSaveTags = (selectedTags) => {
        setIsTagsModalOpen(false); 
        setTags(prevTags => [...prevTags, ...selectedTags]);
        
    };

    const handleMouseDown = (e) => {
        if (zoomLevel > 1) {
            setDragging(true);
            setLastPosition({
                x: e.clientX - position.x,
                y: e.clientY - position.y,
            });
             window.addEventListener('mousemove', handleMouseMove);
             window.addEventListener('mouseup', handleMouseUp);
 
             e.preventDefault();
        }
    };
    
    const handleMouseMove = (e) => {
        if (dragging && zoomLevel > 1) {
            setPosition({
                x: e.clientX - lastPosition.x,
                y: e.clientY - lastPosition.y,
            });
        }
    };
    
    const handleMouseUp = () => {
        setDragging(false);

        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
    };
    

    return (
        <>  
            <div className="media-modal-overlay" onClick={onClose}>
                <div className="media-modal-content" onClick={e => e.stopPropagation()}>
                <span className="close" onClick={onClose}>
                    &times;
                </span>
                    {!isEditorOpen ? (
                        <div className='col-md-9 left-pane'>
                            <div className='media-strip'>
                                <div className='media-toolbar'>
                                    <button className='toolbar-button' data-gloss="Edit" onClick={handleEdit}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                    <button className='toolbar-button' data-gloss="Zoom In" onClick={handleZoomIn}>
                                        <FontAwesomeIcon icon={faSearchPlus} />
                                    </button>
                                    <button className='toolbar-button' data-gloss="Zoom Out" onClick={handleZoomOut}>
                                        <FontAwesomeIcon icon={faSearchMinus} />
                                    </button>
                                    <button className='toolbar-button' data-gloss="Full Screen" onClick={toggleFullScreen}>
                                        <FontAwesomeIcon icon={faExpand} />
                                    </button>
                                    <button className='toolbar-button' data-gloss="Download" onClick={handleDownload}>
                                        <FontAwesomeIcon icon={faDownload} />
                                    </button>
                                    <button className='toolbar-button' data-gloss="Delete" onClick={() => console.log('Delete')}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </div>
                            </div>
                            <div className='media-modal-left'>
                                <img 
                                    src={allImages[currentIndex]} 
                                    alt="Enlarged" 
                                    className="modal-image" 
                                    style={{ transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`,cursor: zoomLevel > 1 ? "grab" : "default" }}
                                    onMouseDown={handleMouseDown}
                                    onMouseMove={handleMouseMove}
                                    onMouseUp={handleMouseUp}
                                    onMouseLeave={handleMouseUp} 
                                />
                                <button className='nav-button left' onClick={goToPrevious}>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </button>
                                <button className='nav-button right' onClick={goToNext}>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                            </div>
                        </div>
                    ) : null}

                    {!isFullScreen && !isEditorOpen && (
                        <div className='col-md-3'>
                            <div className='media-modal-right'>
                                <div className='project-header'>
                                    <a href='#' className='photo-title'>John Doe (Liberty Mutual) Sedgwick</a>
                                    <p className='photo-address'>98 N 1st Street . San Jose, CA 95113</p>
                                </div>
                                <div className='name-header'>
                                    <div className="circle-icon">
                                        <span className="icon-text">NL</span>
                                    </div>
                                    <div className='sub-name-header'>
                                        <a href='#' className='uploader-name'>Nick Lucchesi</a>
                                        <p className='upload-date'>01/01/2024, 2.03 PM</p>
                                    </div>
                                </div>

                                <div className='tag-header'>
                                    <button className="add-tags-button" onClick={openTagsModal}>
                                        <FontAwesomeIcon icon={faTag} className="button-icon" /> Add Tags
                                    </button>
                                    <div className='tags-section'>
                                        {tags.map((tag, index) => (
                                            <button key={index} className='tag-button'>
                                                <FontAwesomeIcon icon={faTag} className='tag-icon' />
                                                {tag}
                                            </button>
                                        ))}
                                    </div>
                                </div>

                                <hr></hr>
                                
                                <div className='modal-description'>
                                    <p className='description-header'>Description</p> 
                                    <textarea className="description-box" />
                                </div>

                                <div className='modal-comments'>
                                    <p className='comments-header'>Comments</p>
                                    <div className='comments-wrapper'>
                                    <div className="textarea-container">
                                            <button className="at-button">@</button>
                                            <textarea className="comments-box" />
                                            <hr className="line-separator" />
                                            <button className="post-button">
                                                <FontAwesomeIcon icon={faPaperPlane} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <span className={isFullScreen?'closeonzoom':'close'} onClick={onClose}>&times;</span>
                </div>
            </div>

            {isEditorOpen && (
                <div onClick={closeEditor}>
                    <div onClick={e => e.stopPropagation()}>
                        <Editor imageSrc={allImages[currentIndex]} onClose={closeEditor} />
                    </div>
                </div>
            )}

            {isTagsModalOpen && (
                <TagsModal onClose={closeTagsModal}  onSave={handleSaveTags}/>
            )}
        </>
    );
};

export default PhotoModal;
