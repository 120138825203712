const generateRandomData = () => {
    const names = ["Alice", "Bob", "Charlie", "David", "Eve", "Frank", "Grace", "Hannah", "Ian", "Jack"];
    const venues = ["Conference Room A", "Zoom", "Office", "Café", "Main Hall", "Auditorium", "Library", "Rooftop", "Lounge", "Workshop Room"];
    const titles = [
      "Meeting", "Workshop", "Call", "Presentation", "Seminar", "Training", "Networking Event", "Team Building", "Webinar", "Product Launch"
    ];
  
    const colors = {
      Meeting: "#FFDDC1",  // Light orange
      Workshop: "#C1FFD1", // Light green
      Call: "#C1E3FF",     // Light blue
      Presentation: "#FBCBCA", // Light pink
      Seminar: "#D5B7F0", // Light purple
      Training: "#E0F0B6", // Light yellow
      Networking: "#F9F7F0", // Light gray
      Team: "#D0E1F9",    // Light cyan
      Webinar: "#D6F0D9", // Light mint
      Launch: "#F8E2D1"   // Light peach
    };
  
    const getRandomDate = () => {
      const start = new Date();
      start.setDate(start.getDate() + Math.floor(Math.random() * 10));
      return start.toISOString().split('T')[0];
    };
  
    const getRandomTime = () => `${Math.floor(Math.random() * 12) + 1}:00 ${Math.random() > 0.5 ? 'AM' : 'PM'}`;
  
    const getRandomAttendees = (idCount) => {
      let attendees = [];
      for (let i = 0; i < idCount; i++) {
        attendees.push(`Person${Math.floor(Math.random() * 10) + 1}`);
      }
      return [...new Set(attendees)];
    };
  
    const generateEvents = (count) => {
      const events = [];
      for (let i = 0; i < count; i++) {
        const title = titles[Math.floor(Math.random() * titles.length)];
        events.push({
          title,
          venue: venues[Math.floor(Math.random() * venues.length)],
          startDate: getRandomDate(),
          startTime: getRandomTime(),
          endDate: getRandomDate(),
          endTime: getRandomTime(),
          attendees: getRandomAttendees(Math.floor(Math.random() * 5) + 1),
          color: colors[title] || "#FFFFFF" // Default to white if title not found
        });
      }
      return events;
    };
  
    const data = names.map(name => ({
      name,
      events: generateEvents(5) // Generate 5 events for each person
    }));
  
    return {
      dailyData: data.map(person => ({
        ...person,
        events: person.events.slice(0, 1) // For daily view, show only 1 event
      })),
      weeklyData: data,
      monthlyData: data.map(person => ({
        ...person,
        events: person.events.slice(0, 3) // For monthly view, show up to 3 events
      }))
    };
  };
  
  const { dailyData, weeklyData, monthlyData } = generateRandomData();
  