import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown,faTrash,faPen } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CreateProject.css'; 
import Select from 'react-select';
import AddNewOrganisation from "./CreateProjectModal/AddNewOrganisation";
import { isValidEmail,isValidPhone,isMandatoryFieldValid } from "../../services/utils";
import ReactSelectAsync from "react-select/async";
import { locationAutoComplete } from "../../services/locationService";
import PhoneInput from 'react-phone-number-input';
import useAuthService from '../../services/AuthService';


const ProjectContacts = ({showAddedContacts = true, sendAddedContacts, onlyViewEditProjectContact = false, projectContactToEdit = null, setShowModal = null}) => {
    const {getContactOrganisationList,getContactPerson,getRelationTypes,addContactPerson,getCustomerInfo} = useAuthService();
    const [isProjectContactOpen, setIsProjectContactOpen] = useState(true);
    const [isEditable, setIsEditable] = useState(false);
    const [addedContacts, setAddedContacts] = useState([]);
    const [tempAddedContacts, setTempAddedContacts] = useState([]);
    const [isNewOrganisation, setIsNewOrganisation] = useState(false);
    const [searchOrganisation,setSearchOrganisation] = useState(null)
    const[isNewContact,setIsNewContact]=useState(true)
    const [searchContact,setSearchContact] = useState(null)
    const[onEdit,setOnEdit]=useState(false)
    const [organisationOptions, setOrganisationOptions] = useState([]);
    const [editIndex, setEditIndex] = useState(null); 
    const [isOpenModal,setIsOpenModal] = useState(false);
    const [contactOptions,setContactOptions] = useState([]);
    const [relationshipOptions,setRelationshipOptions] = useState([]);
    const [relationshipTypeName,setRelationshipTypeName] = useState([])
    const [relationshipTypeNameEdit,setRelationshipTypeNameEdit] = useState([])

    const [id,setId]=useState([])
    const [reload,setreload]=useState(false)

    const [initial, setInitial] = useState({
        relationshipId:'',
        firstName: "",
        lastName: "",
        relationshipType:"",
        email: "",
        AlternateNumber: "",
        phoneNumber: "",
        address1: "",
        address2: "",
        country: "",
        city: "",
        state: "",
        zipCode: "",
        county: "",
        organizationId:""

    });
    const [projectContactDetails, setProjectContactDetails]=useState([])

    const errors = {
    firstName:false,
    lastName:false,
    relationType:false,
    email: false,
    mobile: false,
    alternateNumber: false,
   }
   const [projectContactDetailErrors, setProjectContactDetailErrors] = useState(errors)


   useEffect(() => {
    const fetchContactOrganisationList = async () => {
      const types = await getContactOrganisationList();
      const newOptions = [
        { value: 'New', label: 'New Organisation' },
      ];
      const formattedOptions = types?.map(type => ({
        value: type.id,
        label: type.name,
      }));
      const updatedOptions = [...newOptions, ...formattedOptions];
      setOrganisationOptions(updatedOptions); 
    };
    fetchContactOrganisationList();
    setSearchOrganisation('')
    setreload(false);
  }, [reload]);


  const fetchContactPerson = async (value) => {
    console.log(value);
    const types = await getContactPerson(value);
    const newOptions = [
        { value: 'New', label: 'New' },
      ];
    const formattedOptions = types?.map(type => ({
        value: type.id,
        label: type.name,
      }));
    const updatedOptions = [...newOptions, ...formattedOptions];
    setContactOptions(updatedOptions)
  };


  const addNewContactPerson=async(value)=>{
    console.log(value);
    const types = await addContactPerson(value);
  }
  
  const displaylistContacts=async(value)=>{
    const types = await getCustomerInfo(value)
    console.log(types)
    setRelationshipTypeName(types.relationshipType)
    setTempAddedContacts((prevContacts) => [
        ...prevContacts,
        {
          ...types, 
          address1: types.address ? (types.address[0].Default?.address1 || types.address[0]['Loss & Mailing']?.address1 || types.address[0].Mailing?.address1): null ,
          address2: types.address ? (types.address[0].Default?.address2 || types.address[0]['Loss & Mailing']?.address2 || types.address[0].Mailing?.address2):null,
          city: types.address ? (types.address[0].Default?.city || types.address[0]['Loss & Mailing']?.city || types.address[0].Mailing?.city):null,
          county: types.address ? (types.address[0].Default?.county || types.address[0]['Loss & Mailing']?.county || types.address[0].Mailing?.county):null,
          state: types.address ? (types.address[0].Default?.state || types.address[0]['Loss & Mailing']?.state || types.address[0].Mailing?.state) :null,
          zipCode: types.address ? (types.address[0].Default?.zipCode|| types.address[0]['Loss & Mailing']?.zipCode || types.address[0].Mailing?.zipCode):null,
          country: types.address ? (types.address[0].Default?.country || types.address[0]['Loss & Mailing']?.country || types.address[0].Mailing?.country):null,
        }
      ]);
      
  }



  useEffect(() => {
    const fetchRelationType = async () => {
      const types = await getRelationTypes();
      
      const formattedOptions = types?.map(type => ({
        value: type.id,
        label: type.name,
      }));
      setRelationshipOptions(formattedOptions); 
    };
    fetchRelationType();
  }, []);

const handleAddChange = () => {
    addNewContactPerson(projectContactDetails);
    fetchContactPerson(id)
    setIsEditable(false)
    setSearchContact(null)
    resetInputFields()
    setIsOpenModal(false)
    
};
const saveModal = (details) => {
    setreload(true)
    setIsNewOrganisation(false);
};
   
    const handleDeleteContact = (index) => {
        setAddedContacts((prevContacts) => prevContacts.filter((_, i) => i !== index));
    };


    const toggleProjectContactDetails = () => {
        setIsProjectContactOpen(!isProjectContactOpen);
    };

    
    const handleEditContact = (index) => {
        const contactToEdit = addedContacts[index];
        setProjectContactDetails(prevDetails => ({
            ...prevDetails,  
            firstName: contactToEdit.firstName || prevDetails.firstName,
            lastName: contactToEdit.lastName || prevDetails.lastName,
            relationshipType: contactToEdit.relationshipType || prevDetails.relationshipType,
            email: contactToEdit.email || prevDetails.email,
            phoneNumber: contactToEdit.phoneNumber || prevDetails.phoneNumber,
            alternateNumber: contactToEdit.alternateNumber || prevDetails.alternateNumber,
            organizationName:contactToEdit.organizationName || prevDetails.organizationName,
            address1: contactToEdit.address1 || prevDetails.address1,
            address2: contactToEdit.address2 || prevDetails.address2 || '',
            city: contactToEdit.city || prevDetails.city || '',
            country: contactToEdit.country || prevDetails.country || '',
            state: contactToEdit.state || prevDetails.state || '',
            zipCode: contactToEdit.zipCode || prevDetails.zipCode || '',
            county: contactToEdit.county || prevDetails.county || '',
          }));
          
        setIsEditable(true); 
        setOnEdit(true)
        setEditIndex(index); 
        setIsOpenModal(true)
    };
    useEffect(()=>{
        console.log(projectContactDetails)      
    },[projectContactDetails])

const handleSave = () => {
    if(editIndex !== null){
        const updatedContacts = [...addedContacts];
        updatedContacts[editIndex] = projectContactDetails;
        setAddedContacts(updatedContacts);
        setOnEdit(false);
        setIsEditable(false);
    } else {
        setAddedContacts([...addedContacts, projectContactDetails]);
    }    
    resetInputFields()
    setIsOpenModal(false)
};
useEffect(()=>{    
    sendAddedContacts && sendAddedContacts(searchContact?.value, addedContacts);
    console.log(searchContact?.value)
    setSearchContact(null);
},[addedContacts])


const resetInputFields = () => {
    setProjectContactDetails(initial);
    setIsNewContact(null)
    const errors = {
        firstName:false,
        lastName:false,
        relationType:false,
        email: false,
        mobile: false,
        alternateNumber: false,
       }
       setProjectContactDetailErrors(errors)
   
};
   const handleCancel = () =>{
    setSearchContact(null)
    resetInputFields()
    setOnEdit(null)
    setIsEditable(false);
    setIsOpenModal(false)
   }
    

    const handleOrganisation = (selectedOption) => {
        setContactOptions([])
        setTempAddedContacts('')
        setId(selectedOption.value)
        setProjectContactDetails('')
        setProjectContactDetailErrors(errors)
        setSearchOrganisation(selectedOption)
        setIsEditable(false)
        setSearchContact(null)
        if (selectedOption.value === "New") {
            setIsNewOrganisation(true);
        } 
        else {
            setIsNewOrganisation(false); 
            fetchContactPerson(selectedOption.value)
            setProjectContactDetails((prevDetails) => ({
                ...prevDetails,
                organizationId: selectedOption.value
            }));
        }
    };

    const handleContact = (selectedOption) => {
        setSearchContact(selectedOption)
        setRelationshipTypeNameEdit('')
        if (selectedOption.value === "New") {
            setIsNewContact(true)
            setIsEditable(true)
            setIsOpenModal(true)
        } 
        else {
            displaylistContacts(selectedOption.value)
            setIsNewContact(false)
            setIsEditable(false)

        }
        console.log(searchContact);
    };

    const closeModal = () => {
        setIsNewOrganisation(false);  
        setSearchOrganisation(null)
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProjectContactDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value
        }));
        validateField(value, name);  
    };

    const handleCountryChange = (name,value)=>{
        setProjectContactDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value
        }));
        validateField(value, name);
    }
        
    const handleRelationshipChange = (selectedOption) => {
        setRelationshipTypeNameEdit(selectedOption.label)
        setProjectContactDetails((prevDetails) => ({
            ...prevDetails,
            ['relationshipType']: selectedOption ? selectedOption.value : ""
        }));
    };
    const validateField = (value, field) => {
        if (field === 'email') {
            setProjectContactDetailErrors((prev) => ({
                ...prev,
                email: !isValidEmail(value)
            }));
        }
        if (field === 'mobile'){
            setProjectContactDetailErrors((prev) => ({
                ...prev,
                mobile: !isValidPhone(value)
            }));
        }
        if (field === "alternateNumber"){
            setProjectContactDetailErrors((prev) => ({
                ...prev,
                alternateNumber: !isValidPhone(value)
            }));
        }
    };
    const getlocationAutoComplete = async (data) => {
        let suggestions = []
        if(data.length > 8){
            await locationAutoComplete(data).then((listData)=>{                
                suggestions = listData;
            })
        }
        return suggestions.map((item)=>({        
            label: item.description,
            value:item
        }))
    }
    
    const handleAddress = (option) => {   
        let value = option.value;
        setProjectContactDetails(prev =>({
            ...prev,                                
            address1: value.address1,
            address2: value.address2,
            city: value.city,
            country: value.city,
            state: value.state,
            zipCode: value.zipCode,
            county: value.county
        }));
    }
    useEffect(() => {
        console.log(addedContacts);
    }, [addedContacts]);
    
    useEffect(()=>{
        if(onlyViewEditProjectContact){
            // setProjectContactDetail(projectContactToEdit);
            setProjectContactDetails(prevDetails => ({
                ...prevDetails,  
                firstName: projectContactToEdit.pcFirstName || prevDetails.firstName,
                lastName: projectContactToEdit.pcLastName || prevDetails.lastName,
                relationshipType: projectContactToEdit.pcRelationshipType || prevDetails.relationshipType,
                email: projectContactToEdit.pcEmail || prevDetails.email,
                phoneNumber: projectContactToEdit.pcPhone || prevDetails.phoneNumber,
                alternateNumber: projectContactToEdit.pcAlternatePhone || prevDetails.alternateNumber,
                organizationName:projectContactToEdit.pcOrganizationName || prevDetails.organizationName,
                address1: projectContactToEdit.pcAddressLine1 || prevDetails.address1,
                address2: projectContactToEdit.pcAddressLine2 || prevDetails.address2 || '',
                city: projectContactToEdit.pcCity || prevDetails.city || '',
                country: projectContactToEdit.pcCountry || prevDetails.country || '',
                state: projectContactToEdit.pcState || prevDetails.state || '',
                zipCode: projectContactToEdit.pcZipCode || prevDetails.zipCode || '',
                county: projectContactToEdit.pcCounty || prevDetails.county || '',
              }));
            setRelationshipTypeNameEdit(projectContactToEdit?.pcRelationshipType)
            setIsEditable(true)
            
            setIsOpenModal(true);
        }
    },[onlyViewEditProjectContact,projectContactToEdit])
    
    const handleSaveEditProjectContact = () => {
        console.log(projectContactDetails)
    }
    
    return (
        <section className="project-contacts-information-bottom-div">
            {!onlyViewEditProjectContact && (<div className="heading-edit-button-div">
                <div className="project-contacts-section-heading project-contact-candidates-label">
                    <button onClick={toggleProjectContactDetails}>
                        <FontAwesomeIcon icon={isProjectContactOpen ? faChevronUp : faChevronDown} />
                        <span className="project-contacts-div"> Project Contacts</span>
                    </button>
                </div>
            </div>)}
        <div className={`project-contacts-bottom-values-div project-contacts-information ${isProjectContactOpen ? 'show' : ''}`}>
        {!onlyViewEditProjectContact && showAddedContacts && addedContacts.length > 0 && (
        <div className="added-contacts-table">
            <table className="table">
                <thead>
                    <tr>
                        <th>Organization/Individual</th>
                        <th>Relation Type</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Address</th>
                        <th>Remove</th>
                    </tr>
                </thead>
                <tbody>
                    {addedContacts.map((contact, index) => (
                        <tr key={index}>
                            <td>{contact.organizationName || 'N/A'}</td>
                            <td>{contact.relationshipType || 'N/A'}</td>
                            <td>{contact.firstName || 'N/A'}</td>
                            <td>{contact.lastName || 'N/A'}</td>
                            <td>{contact.email || 'N/A'}</td>
                            <td>{contact.phoneNumber || 'N/A'}</td>
                            <td>{contact.address1 || 'N/A'+ ","+contact.city || 'N/A' }
                            </td>
                            <td>
                                <button className = "delete"  onClick={() => handleDeleteContact(index)}>
                                    <FontAwesomeIcon icon={faTrash} size="sm"></FontAwesomeIcon>
                                </button>
                                <span className="separator"> | </span>
                                <button className = "delete" >
                                    <FontAwesomeIcon icon={faPen} onClick={() => handleEditContact(index)} size="sm"></FontAwesomeIcon>
                                </button>

                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )}
            
            {!onlyViewEditProjectContact && (<div className = "row">
                <div className="col-md-4">
                    <div className="input-div client-type-div" >
                        <div className="label">Select/Add Organization/Individual</div>
                            <Select
                            className="basic-single"
                            classNamePrefix="custom-select"
                            onChange={handleOrganisation}
                            menuPosition="fixed"  
                            menuPortalTarget={document.body}  
                            options={organisationOptions}
                            value={searchOrganisation}
                            filterOption={(candidate, input) => {
                                if (candidate.label === 'New') return true;
                                const label = candidate.label || '';
                                const searchValue = input || '';
                                return label.toLowerCase().includes(searchValue.toLowerCase());
                            }}
                            
                            />
                            <AddNewOrganisation handleSave={saveModal} show={isNewOrganisation} handleClose={closeModal} />
                    </div>
                </div>  
                
                
                <div className="col-md-4">
                    <div className="input-div client-type-div" >
                        <div className="label">Select / Add Contact</div>
                            <Select
                            className="basic-single"
                            classNamePrefix="custom-select"
                            menuPosition="fixed"  
                            menuPortalTarget={document.body}
                            options={contactOptions}
                            onChange={handleContact}
                            value={searchContact}
                            />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="input-div client-type-div" >
                        <div className="label">Relationship Type</div>
                        <Select
                            className="basic-single"
                            classNamePrefix="custom-select"
                            menuPosition="fixed"
                            menuPortalTarget={document.body}
                            value={{ value: relationshipTypeName, label: relationshipTypeName }} 
                            isDisabled={true} 
                        />
                    </div>
                </div>
                <div className="col-md-1">
                {(!isNewContact && !isEditable )&& <div className="addButton">
                    <button onClick={()=>{
                        setAddedContacts((prevContacts) => [...prevContacts, ...tempAddedContacts]);                                        
                        
                        setSearchOrganisation(null);
                        setRelationshipTypeNameEdit(relationshipTypeName);
                        setRelationshipTypeName('')}}> 
                        Add 
                    </button>
                </div>}
                </div>
               
            </div>)}
            {onlyViewEditProjectContact && isOpenModal && (
            <div className="modal" onClick={()=>{setIsOpenModal(false); setShowModal(false);}}>
                <div className="modal-content" onClick={(event) => {event.stopPropagation()}}>
                <span className="close" onClick={()=>setIsOpenModal(false)}>&times;</span>
                
                <div className = "row">
                    <div className="col-md-4">
                        <div className="input-div customer-firstname-div">
                            <div className="label">
                                <span>First Name</span>
                                <div className="invalid_text">{projectContactDetailErrors.firstName? "required":""}</div>
                            </div>
                            <input 
                            className= {`job-details-input ${projectContactDetailErrors.firstName?"invalid":""}`}
                            name="firstName"
                            type="text" 
                            disabled ={ !isEditable } 
                            onChange={handleInputChange} 
                            value={projectContactDetails.firstName}/>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="input-div customer-lastName-div">
                            <div className="label">
                                <span>Last Name </span>
                                <div className="invalid_text">{projectContactDetailErrors.lastName? "required":""}</div>
                            </div>
                            <input 
                            className= {`job-details-input ${projectContactDetailErrors.lastName?"invalid":""}`}
                            name = "lastName" 
                            type="text" 
                            disabled ={ !isEditable }
                            onChange={handleInputChange} 
                            value={projectContactDetails.lastName}/>
                        </div>
                    </div>
                    {isEditable && 
                    <div className="col-md-4">
                        <div className="input-div client-type-div" >
                            <div className="label">
                                <span>Relationship Type</span>
                            </div>
                        <Select
                            className="basic-single"
                            classNamePrefix="custom-select"
                            menuPosition="fixed"
                            menuPortalTarget={document.body}
                            options={relationshipOptions}
                            onChange={handleRelationshipChange}
                            value={{ value: projectContactDetails.relationshipType, label: relationshipTypeNameEdit }} 
                        />
                        </div>
                    </div>}
                </div>       
     

            <div className = "row">
                <div className="col-md-4">
                    <div className="input-div email-div">
                        <div className="label">
                            <span>Email address{projectContactDetailErrors.email && <FontAwesomeIcon icon="fa-solid fa-asterisk" />}</span>
                            <div className="invalid_text">{projectContactDetailErrors.email? "required":""}</div>
                        </div>
                        <input 
                        oninput = {(event) => validateField(event.target.value,"email")} 
                        className= {`job-details-input ${projectContactDetailErrors.email?"invalid":""}`}
                        type="text"
                        name = "email" 
                        disabled ={!isEditable } 
                        onChange={handleInputChange} 
                        value={projectContactDetails.email}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-div mobile-div">
                        <div className="label">
                            <span>Primary Phone Number</span>
                            <div className="invalid_text">{projectContactDetailErrors.mobile? "required":""}</div>
                        </div>
                        <PhoneInput
                            international
                            defaultCountry="US"
                            value={projectContactDetails.phoneNumber}
                            countryCallingCodeEditable={false}
                            disabled ={ !isEditable } 
                            name = "phoneNumber" 
                            onChange={(value) =>
                                handleCountryChange('phoneNumber',value)
                              }
                            />
                    </div>                   
                </div>
                <div className="col-md-4">
                    <div className="input-div alternate-number-div">
                        <div className="label">
                            <span>Alternate Phone Number{projectContactDetailErrors.alternateNumber && <FontAwesomeIcon icon="fa-solid fa-asterisk" />}</span>
                            <div className="invalid_text">{projectContactDetailErrors.alternateNumber? "required":""}</div>
                        </div>
                        <PhoneInput
                        international
                        defaultCountry="US"
                        value={projectContactDetails.alternateNumber}
                        disabled ={ !isEditable } 
                        countryCallingCodeEditable={false}
                        name = "alternateNumber" 
                        onChange={(value) =>
                            handleCountryChange("alternateNumber",value)
                            }
                        />
                    </div>
                </div>
            </div>
            <div className = "row">
                <div className="col-md-4">
                    <div className="input-div email-div">
                        <div className="label">Address 1</div>
                        <ReactSelectAsync
                            className="basic-single"
                            classNamePrefix="custom-select"
                            isDisabled ={!isEditable}
                            value = {{label:projectContactDetails.address1, value:projectContactDetails.address1}}
                            loadOptions ={getlocationAutoComplete}                            
                            onChange = {(option) => handleAddress(option)}
                            // onFocus = {(event) => validateField("lossAddress","address1",event.target.value)}
                            />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-div mobile-div">
                        <div className="label">Address 2</div>
                        <input className="job-details-input" type="text" name = "address2" disabled ={ !isEditable } onChange={handleInputChange} value={projectContactDetails.address2}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input-div alternate-number-div">
                        <div className="label">Country</div>
                        <input className="job-details-input"  name = "country" type="text" disabled ={ true } onChange={handleInputChange} value={"USA"}/>
                    </div>
                </div>
            </div>
            <div className = "row">
                <div className="col-md-4">
                    <div className="input-div email-div">
                        <div className="label">City</div>
                        <input className="job-details-input" name = "city" type="text" disabled ={ !isEditable } onChange={handleInputChange} value={projectContactDetails.city} />
                    </div>
                </div>
                <div className="col-md-4">
                <div className="row">
                    <div className="col-6">
                        <div className="input-div">
                            <div className="label">State</div>
                            <input className="job-details-input" name = "state" type="text" disabled ={ !isEditable } onChange={handleInputChange} value={projectContactDetails.state}/>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="input-div">
                            <div className="label">Zipcode</div>
                            <input className="job-details-input" name = "zipCode" type="text" disabled ={ !isEditable } onChange={handleInputChange} value={projectContactDetails.zipCode}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                    <div className="input-div alternate-number-div">
                        <div className="label">County</div>
                        <input className="job-details-input" name= "county" type="text" disabled ={ !isEditable } onChange={handleInputChange} value={projectContactDetails.county}/>
                    </div>
            </div>   
            </div>  
            {!onEdit && searchContact && searchContact.value == "New" && 
            <div className="button-footer">
                <button className="cancelButton" onClick = {handleCancel}>Cancel</button>
                <button className="saveButton" onClick={handleAddChange}>Save</button>
                
            </div>
            }
            {onEdit &&
            <div className="button-footer">
                <button className="cancelButton" onClick = {handleCancel}>Cancel</button>
                <button className="saveButton" onClick={handleSave}>Save</button>
            </div>
            }
            {onlyViewEditProjectContact &&
            <div className="button-footer">
                <button className="cancelButton" onClick = {()=>{setIsOpenModal(false); setShowModal(false)}}>Cancel</button>
                <button className="saveButton" onClick={handleSaveEditProjectContact}>Save</button>
            </div>
            }
         </div>
         
        </div>)}
            
            
            
        </div>
           
    </section>
    )
        
}
export default ProjectContacts;