import React from 'react';
import { BrowserRouter, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import ProjectView from './views/ProjectView';
import CreateProjectView from './views/CreateProjectView';
import ProjectListView from './views/ProjectListView';
import Schedular from './views/Schedular';
import LoginView from './views/LoginView';
import ForgotPasswordView from './views/ForgotPasswordView';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProjectHeader from './components/ProjectHeader/ProjectHeader';
import {  useSelector } from 'react-redux';
import UpdatePasswordView from './views/UpdatePasswordView';



const AppContent = () => {
  const location = useLocation();
  const showHeader = !['/', '/login','/updatePassword'].includes(location.pathname);
  const authReducer = useSelector((state) => state.AuthReducer);
  const userSession = authReducer?authReducer.userSession : {};
  return (
    <div className="App">
      {userSession.token && showHeader && <ProjectHeader />}
      <Routes>
        
        {!userSession.token && (
          <>
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="forgotPassword" element={<ForgotPasswordView/>}/>
          </>
        )}
        
        {userSession.token && (
          <>
            <Route path="createProject" element={<CreateProjectView />} />
            <Route path="viewProject" element={<ProjectView />} />
            <Route path="viewProjectList" element={<ProjectListView />} />
            <Route path="schedular" element={<Schedular />} />
            <Route path="updatePassword" element={<UpdatePasswordView/>}/>
            
          </>
        )}

        
        {!userSession.token ? <Route path="/" element={<LoginView />} />: <Route path="*" element={<Navigate to="/viewProjectList" />} />}
      </Routes>
    </div>
  );
};

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

export default App;
