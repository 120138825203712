import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown,faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import './CreateProject.css'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import CurrencyInput from '../Dropdowns/CurrencyInput'; 
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useAuthService from '../../services/AuthService'



const ProjectInformation = ({sendPropertyType,data,onClose, projectId}) => {
    const [loading,setLoading]=useState(true)
    const [startDate, setStartDate] = useState();
    const [isProjectOpen, setIsProjectOpen] = useState(true);
    const [dateValue,setDateValue] = useState(new Date())
    const [projectSubTypeOptionsDisable,setProjectSubTypeOptionsDisable] = useState(true)
    const datePickerRef = useRef()
    const {projectType,propertyType,referralSources,editProjectInformation, insuranceCompanies,getprojectSubtypes,activateMilestones} = useAuthService();
   const [projectTypeOptions, setProjectTypeOptions] = useState([]);
   const [propertyTypeOptions, setPropertyTypeOptions] = useState([]);
   const [selectedPropertyType, setSelectedPropertyType] = useState(null);
   const [referralOptions,setReferralOptions] = useState([]);
   const [insuranceCompaniesList,setInsuranceCompaniesList] = useState([]);
   const [projectSubTypeOptions,setProjectSubTypeOptions] = useState([]);
   const [projectSubTypes, setProjectSubTypes] = useState([]);
   const [selectedSubTypeValues, setSelectedSubTypeValues] = useState({});

   const [projectInformation, setProjectInformation] = useState({
    referralSource: '',
    lossDate: "",
    insuranceCompany: "",
    insurancePolicy: "",
    insuranceClaim: "",
    deductible: 0,
    projectTypeId:"",
    selectedPropertyType:"",
    miscNotes: ""
})


   useEffect(() => {
    const fetchProjectTypes = async () => {
      const types = await projectType();  
      const formattedOptions = types.map(type => ({
        value: type.id,
        label: type.name,
      }));
      setProjectTypeOptions(formattedOptions);
    };
    fetchProjectTypes();
  }, []);

  useEffect(() => {
    if (data) {
      setProjectInformation(prev => ({
        ...prev,
        lossDate: data.lossDate,
        insurancePolicy: data.insurancePolicy,
        insuranceClaim: data.insuranceClaim,
        deductible: data.deductible || 0,
        miscNotes:data.miscNotes
      }));
      const updateOptionValue = (key, optionsList, labelValue) => {
        const matchedOption = optionsList.find(option => option.label === labelValue);
        if (matchedOption) {
          setProjectInformation(prev => ({
            ...prev,
            [key]: matchedOption.value
          }));
        }
      };
      if (data.projectTypeName) {
        updateOptionValue('projectTypeId', projectTypeOptions, data.projectTypeName);
      }
      if (data.insuranceCompany) {
        updateOptionValue('insuranceCompany', insuranceCompaniesList, data.insuranceCompany);
      }
      if(data.propertyTypeName){
        updateOptionValue('selectedPropertyType',propertyTypeOptions, data.propertyTypeName)
      }
      if (data.referralSource) {
        updateOptionValue('referralSource', referralOptions, data.referralSource);
      }
    }
  }, [data, projectTypeOptions, referralOptions, insuranceCompaniesList]);
  

  useEffect(() => {
    const fetchPropertyTypes = async () => {
      const types = await propertyType();
      if (!types || types.length === 0) {
        setPropertyTypeOptions([]);
        return;
      }
     
      const formattedOptions = types.map(type => ({
        value: type.id,
        label: type.name,
      }));
      setPropertyTypeOptions(formattedOptions);
      const defaultType = formattedOptions.find(type => type.value === 1) || formattedOptions[0]; 
      setSelectedPropertyType(defaultType); 
    };
    fetchPropertyTypes();
  }, []);

  useEffect(()=>{
    sendPropertyType(selectedPropertyType?.value || 0)
  },[selectedPropertyType])

  useEffect(() => {
    const fetchReferralSources = async () => {
      const types = await referralSources(); 
      if (types.length > 0) {
        const formattedOptions = types.map(type => ({
          value: type.id,
          label: type.name,
        }));
        setReferralOptions(formattedOptions);
      }
    };
    fetchReferralSources();
  }, []);
  
  useEffect(() => {
    const fetchInsuranceCompanies = async () => {
      const types = await insuranceCompanies(); 
      if (types.length>0){ 
      const formattedOptions = types.map(type => ({
        value: type.id,      
        label: type.name,   
      }));

      setInsuranceCompaniesList(formattedOptions);  
    }
    else{
        setInsuranceCompaniesList([]); 
    }
    };
    fetchInsuranceCompanies();
  }, []);  
  
    const toggleProjectDetails = () => {
        setIsProjectOpen(!isProjectOpen);
      };


    useEffect(()=>{
        setSelectedSubTypeValues(projectSubTypes.map(() => null));
        if(projectInformation.projectTypeId.value === 3 || projectInformation.projectTypeId.value === 4){
            const fetchProjectSubTypes = async () => {
                const types = await getprojectSubtypes(projectInformation.projectTypeId.value); 
                setProjectSubTypes(types);
            };
            fetchProjectSubTypes();
        }
        else{
            setProjectSubTypes([]);
        }
        
    },[projectInformation.projectTypeId])

    const handleSubTypeChange = (value, index) => {
        setSelectedSubTypeValues({
          ...selectedSubTypeValues,
          [index]: value
        });
      };
      
      const saveProjectInfo = ()=>{
        const projectData = {};
        projectData.projectId = projectId;
        projectData.projectTypeId = projectInformation.projectTypeId;
        projectData.projectSubTypeList = [];
        projectData.propertyTypeId=projectInformation.selectedPropertyType;
        projectData.referralSourceOrgId = projectInformation.referralSource;
        projectData.lossDate = projectInformation.lossDate;
        projectData.insuranceOrgId = projectInformation.insuranceCompany;
        projectData.insurancePolicy = projectInformation.insurancePolicy;
        projectData.insuranceClaim = projectInformation.insuranceClaim;
        projectData.deductible = projectInformation.deductible;
        projectData.miscNotes = projectInformation.miscNotes
        if(projectData.projectTypeId){
          const types= activateMilestones(projectId)
          console.log(types)
        } 
        editProjectInformation(projectData).then((res)=>{ onClose()}).catch((err)=>{
          alert("Something went wrong while adding project information")          
        });        
      } 

    return(
        <section className="customer-information-bottom-div">
            <div className="heading-edit-button-div">
                <div className="project-information-section-heading project-information-label">
                    <button onClick={toggleProjectDetails}>
                            <FontAwesomeIcon icon={isProjectOpen ? faChevronUp : faChevronDown} />
                            <span className="project-information-div"> Project Information</span>
                    </button>
                </div>
            </div>
            <div className={`project-information-bottom-values-div project-information ${isProjectOpen ? 'show' : ''}`}>
                <div className = "row">
                    <div className="col-md-4">
                        <div className="input-div client-type-div" >
                            <div className="label">Project Type</div>
                            <Select
                                className="basic-single"
                                classNamePrefix="custom-select"
                                menuPosition="fixed"
                                menuPortalTarget={document.body}
                                options={projectTypeOptions}
                                value={projectTypeOptions.find((option) => option.value === projectInformation?.projectTypeId)||null}     
                               
                                onChange={(opt) => {
                                  setProjectInformation((prev) => ({
                                    ...prev,
                                    projectTypeId: opt.value, // Update the state with the selected value
                                  }));
                                }}
                                
                                  />
                                  
                        </div>
                    </div>
                    {projectSubTypes<=0 && <div className="col-md-4">
                        <div className="input-div client-type-div" >
                            <div className="label">Project Subtype</div>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="custom-select"
                                    isDisabled = {projectSubTypeOptionsDisable}
                                    menuPosition="fixed"  
                                    menuPortalTarget={document.body}  
                                    options={projectSubTypeOptions}
                                />
                        </div>
                    </div>}
                    {projectSubTypes.length > 0 &&
                                projectSubTypes.map((subtype, index) => (<div className={`col-md-${4 / Math.min(projectSubTypes.length, 4)}`}>
                            <div className="input-div client-type-div" >
                            <div className="label">{subtype.subtype_class_name}</div>
                                <Select
                                    key={index}
                                    className="basic-single"
                                    classNamePrefix="custom-select"
                                    menuPosition="fixed"
                                    menuPortalTarget={document.body}
                                    options={subtype.subtypes.map(sub => ({
                                    value: sub.subtype_id,
                                    label: sub.subtype_name,
                                    }))}
                                    value={selectedSubTypeValues[index]}
                                    onChange={(opt) => handleSubTypeChange(opt, index)}
                                />
                               
                        </div>
                    </div>
                     ))}

                    <div className="col-md-4">
                        <div className="input-div organization-name-div" >
                            <div className="label">Property Type</div>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="custom-select"
                                    menuPosition="fixed"  
                                    menuPortalTarget={document.body}
                                   
                                    options={propertyTypeOptions}
                                    value={
                                      propertyTypeOptions.find(
                                        (option) => option.value === projectInformation?.selectedPropertyType
                                      ) || null
                                    }       
                                    onChange={(opt) =>
                                      setProjectInformation((prev) => ({
                                        ...prev,
                                        selectedPropertyType: opt.value,
                                      }))
                                    }
                                    />
                        </div>
                    </div>
                </div>
                <div className = "row">
                    <div className="col-md-4">
                        <div className="input-div alternate-number-div">
                            <div className="label">Referral Source</div>
                            <Select 
                                className="basic-single"
                                classNamePrefix={"custom-select"} 
                                menuPosition="fixed"  
                                menuPortalTarget={document.body} 
                                options={referralOptions}
                                value={
                                  referralOptions.find(
                                    (option) => option.value === projectInformation?.referralSource
                                  ) || null
                                }       
                                onChange={(opt) =>
                                  setProjectInformation((prev) => ({
                                    ...prev,
                                    referralSource: opt.value,
                                  }))
                                }
                                type="text" />
                        </div>
                    </div>
                    
                    <div className="col-md-4">
                            <div className="input-div custom-datepicker">
                                <div className="label">Loss Date</div>
                                    <DatePicker
                                        ref = {datePickerRef}
                                        enableTabLoop={false}
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        dateFormat="MM/dd/yyyy"
                                        placeholderText="MM/DD/YYYY"
                                        className="custom-date-picker"
                                        wrapperClassName="w-full"   
                                        value= {projectInformation.lossDate}                         
                                    />
                                    <span className="calendar-icon" onClick={()=>datePickerRef.current.setFocus()}>
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                </span>
                            </div>
                        </div>
                    </div>
                
                <div className="side-heading email-div">
                    <p><b>Insurance Information </b></p>
                </div>
        
                <div className = "row">
                    <div className="col-md-4">
                        <div className="input-div email-div">
                            <div>Company Name</div>
                            <Select
                                className="basic-single"
                                classNamePrefix="custom-select"
                                menuPosition="fixed"
                                menuPortalTarget={document.body}
                                options={insuranceCompaniesList}
                                value={
                                  insuranceCompaniesList.find(
                                    (option) => option.value === projectInformation?.insuranceCompany
                                  ) || null
                                }
                                
                                onChange={(opt) =>
                                  setProjectInformation((prev) => ({
                                    ...prev,
                                    insuranceCompany: opt.value,
                                  }))
                                }
                              />

                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="input-div mobile-div">
                            <div>Insurance Policy</div>
                            <input className="job-details-input" type="text" value= {projectInformation.insurancePolicy} onChange={(e) => setProjectInformation({
                                ...projectInformation,
                                insurancePolicy: e.target.value
                            })}  />
                        </div>
                    </div>
                    
                    <div className="col-md-4">
                        <div className="input-div">
                            <div>Claim Number</div>
                            <input className="job-details-input" type="text" value= {projectInformation.insuranceClaim} onChange={(e) => setProjectInformation({
                                  ...projectInformation,
                                  insuranceClaim: e.target.value
                              })}  />
                        </div>
                    </div>
                </div>
                <div className = "row">
                    <div className="col-md-4">
                        <div className="input-div">
                            <div>Deductible</div>
                            <div className="deductible-div">
                            <CurrencyInput
                              type="text"
                              deductibleValue={projectInformation.deductible ? projectInformation.deductible : ''}
                              onValueChange={(value) =>
                                setProjectInformation({
                                  ...projectInformation,
                                  deductible: value, // value will be the formatted number as a string
                                })
                              }
                            />

                            </div>        
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="input-div alternate-number-div">
                            <div>Miscellenous Note</div>
                            <textarea value={projectInformation.miscNotes} onChange={(e) => {
                              setProjectInformation((prev) => ({
                                ...prev,
                                miscNotes: e.target.value
                              }))
                            }} className="job-details-input" rows={2}/>
                        </div>
                    </div>
                </div>
                {data && 
                    <div className="button-footer">
                        <button className="cancelButton" onClick = {()=>{onClose("project_info")}}>Cancel</button>
                        <button className="saveButton"onClick={()=>{saveProjectInfo()}}>Save</button>
                    </div>}
           
                <br/>
            </div>
        </section>
    )

}

export default  ProjectInformation;