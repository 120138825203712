import React, { useState, useEffect } from 'react';
import './PostingPage.css';
import TextEditor from './TextEditor'; 
import { faEllipsisV, faFilePdf, faTrash, faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import jsPDF from 'jspdf';
import ShareModal from './ShareModal';
import useAuthService from '../../../services/AuthService';

const PostingPage = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isBlankPageOpen, setIsBlankPageOpen] = useState(false);
  const [editorContent, setEditorContent] = useState('');
  const [modifiedTime, setModifiedTime] = useState('');
  const [selectedFile, setSelectedFile] = useState(null); 
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [shareOpen, setShareOpen] = useState(false);
  const [templateList, setTemplateList] = useState('');
  const [selectedTemplate, setSelectedemplate]=useState('')
  const { getTemplates } = useAuthService();

  useEffect(() => {
    const getTextTemplates = async () => {
      const types = await getTemplates();  
      setTemplateList(types);
    };
    getTextTemplates();
  }, []); 


  const [files] = useState([
    { name: 'Material List', url: '', modifiedTime: 'Sept 28, 2024' },
    { name: 'Estimate', url: '/Estimate.html', modifiedTime: 'Sept 27, 2024' },
    { name: 'Checklist', url: '/path/to/checklist.html', modifiedTime: 'Sept 26, 2024' },
    { name: 'Work Order', url: '/Work Order.html', modifiedTime: 'Sept 25, 2024' },
    { name: 'Daily Log', url: '/Daily Log.html', modifiedTime: 'Sept 24, 2024' }
  ]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const exportAsPDF = (file) => {
    const doc = new jsPDF();
    doc.text(file.name, 10, 10); 
    doc.text(editorContent, 10, 20); 
    doc.save(`${file.name}.pdf`); 
  };

  const openBlankPage = () => {
    setIsBlankPageOpen(true);
    setEditorContent('');
    setModifiedTime('');
    setSelectedFile(null); 
    setIsDropdownOpen(false);
  };

  const closeBlankPage = () => {
    setIsBlankPageOpen(false);
  };

  const openFile = async (file) => {
    try {
      const response = await fetch(file.url);
      const content = await response.text(); 
      setEditorContent(content); 
      setIsBlankPageOpen(true); 
      setModifiedTime(file.modifiedTime);
      setSelectedFile(file); 
    } catch (error) {
      setEditorContent('Error loading file content');
    }
  };



  const handleMaterialListOpen = async (name) => {
    let templateUrl = '';

    if (name === 'Material List') {
        templateUrl = templateList["stg/templates/Materials List.html"];
        console.log(templateUrl)
    } else if (name === 'Daily Log') {
        templateUrl = templateList["stg/templates/Daily Log.html"];
    } else if (name === 'Estimate') {
        templateUrl = templateList["stg/templates/Estimate.html"];
    } else if (name === 'Work Order') {
        templateUrl = templateList["stg/templates/Work Order.html"];
    }

    if (templateUrl) {
        const content = await (await fetch(templateUrl)).text();
        setEditorContent(content); 
        setIsBlankPageOpen(true);
        setModifiedTime(''); 
        setSelectedFile({ name: name, url: templateUrl });
    }
};

  const toggleEllipseDropdown = (index) => {
    if (openDropdownIndex === index) {
      setOpenDropdownIndex(null); 
    } else {
      setOpenDropdownIndex(index); 
    }
  };

  const openShareModal = () => {
    setShareOpen(true);
  };

  const closeShareModal = () => {
    setShareOpen(false);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
    setOpenDropdownIndex(null);
  };
  

  return (
    <div className="posting-container">
      <div className="editor-section">
        <div className="file-list-container">
          <button className="create-page" onClick={toggleDropdown}>
            + Create
          </button>

          {files.map((file, index) => (
            <div 
              className="file-container" 
              key={index}
              onMouseLeave={handleMouseLeave} // Close dropdown on mouse leave
            >
              <FontAwesomeIcon 
                className='ellipse' 
                icon={faEllipsisV} 
                onClick={() => toggleEllipseDropdown(index)} 
              />
              
              {openDropdownIndex === index && (
                <div className="ellipse-dropdown-options" onMouseLeave={handleMouseLeave}>
                  <a href="#" onClick={() => exportAsPDF(file)}><FontAwesomeIcon className="icons" icon={faFilePdf}/>Export as PDF</a>
                  <a href="#" onClick={openShareModal}><FontAwesomeIcon className="icons" icon={faShare}/>Share</a>
                  <a href="#"><FontAwesomeIcon className="icons" icon={faTrash} /> Delete</a>
                </div>
              )}
              
              <button className="file-button" onClick={() => openFile(file)}>
                <h5 className="file-header">{file.name}</h5>
                <span className="editor-name">John Doe . {file.modifiedTime}</span>
              </button>
            </div>
          ))}
        </div>
        
        {shareOpen && <ShareModal closeModal={closeShareModal} />}
        
        {isDropdownOpen && (
          <div className="dropdown-options" onMouseLeave={handleMouseLeave}>
            <a href="#" onClick={openBlankPage}>Blank Page</a>
            <a href="#" onClick={()=>handleMaterialListOpen("Material List")}>Material List</a>
            <a href="#"onClick={()=>handleMaterialListOpen("Daily Log")}>Daily Log</a>
            <a href="#" onClick={()=>handleMaterialListOpen("Work Order")}>Work Order</a>
            <a href="#" onClick={()=>handleMaterialListOpen("Estimate")}>Estimate</a>
          </div>
        )}

        {/* Modal for text editor */}
        {isBlankPageOpen && (
          <div className="modal-overlay">
            <div className="modal-content1">
              <span className="close" onClick={closeBlankPage}>&times;</span>

              <div className="modified-time">
                <input 
                  type="text" 
                  className="text-file-input" 
                  defaultValue={selectedFile ? selectedFile.name : 'Untitled Document'} 
                />
                <div className="date-time">
                  {modifiedTime ? `Last Modified: ${modifiedTime}` : 'Not Modified Yet'}
                </div>
              </div>
              <hr />
              <TextEditor 
                editorContent={editorContent} 
                
                setEditorContent={setEditorContent} 
                modifiedTime={modifiedTime} 
                setModifiedTime={setModifiedTime} 
                isBlankPageOpen={isBlankPageOpen}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PostingPage;
