
export const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
}
export const isValidPhone = (phone) => {
    const phonePattern = /^\d{10}$/;
    return phonePattern.test(phone);
}

export const isMandatoryFieldValid = (value) => {
    const specialCharPattern = /[^a-zA-Z0-9]/;
    return value !== null && value !== undefined && value.trim() !== '';
}
export const isValidZipCode = (zip) => {
    const phonePattern = /^\d{5}$/;
    return phonePattern.test(zip);
}

export const addressFilter = (data)=>{
    let address1 = '';
    let address2 = '';
    let city = '';
    let county = '';
    let state = '';
    let country = '';
    let zipCode = '';
    const filteredResults = data.map((item) => {
        console.log("item: ",item);
        item.address_components.forEach(component => {
            const { long_name, short_name, types } = component;

            if (types.includes('street_number')) {
                address1 += long_name + ' ';
            } else if (types.includes('route')) {
                address1 += long_name;
            } else if (types.includes('locality')) {
                city = long_name;
            } else if (types.includes('administrative_area_level_1')) {
                state = short_name;
            } else if (types.includes('country')) {
                country = short_name;
            }
            else if (types.includes('administrative_area_level_2')) {
                county = long_name; 
            }
            else if (types.includes('postal_code')) {
                zipCode = long_name;
            } else if (types.includes('neighborhood')) {            
                address2 += long_name;
            }
        });
        return {
            address1,
            address2,
            city,
            county,
            zipCode,
            country,
            description: item.formatted_address || "",
            state
            }
        })
    return filteredResults;
}

