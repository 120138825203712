import React from 'react';
import './PrintDialog.css';

const PrintDialog = React.forwardRef(({ selectedPhotos, layoutOption }, ref) => {
    const getGridStyle = () => {
        switch (layoutOption) {
            case 1:
                return { gridTemplateColumns: '1fr' }; 
            case 2:
                return { gridTemplateColumns: '1fr', gridTemplateRows: 'repeat(2, auto)'};  
            case 3:
                return { gridTemplateColumns: '1fr 1fr', gridTemplateRows: 'repeat(2, auto)' }; 
            default:
                return { gridTemplateColumns: '1fr' }; 
        }
      };
       
const getLayoutClass = () => {
    switch (layoutOption) {
      case 1:
        return 'layout-1-image';
      case 2:
        return 'layout-2-images';
      case 3:
        return 'layout-4-images';
      default:
        return 'layout-1-image'; 
    }
};

return (
    
  <div ref={ref} className={`print-container ${getLayoutClass()}`}  style={{ display: 'grid', ...getGridStyle() }}>
    {selectedPhotos.map((photo, index) => (
      <img
        key={index}
        src={photo}
        alt={`photo-${index}`}
        className="print-image"
        style={{ margin: '15px', width: '90%', height:'90%'}} 
      />
    ))}
  </div>
);
})

export default PrintDialog;
