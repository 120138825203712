import { useState } from 'react';
import './ProjectListView.css';
import ProjectListButtons from '../components/ProjectList/ProjectButtonsListComponent/ProjectListButtons';
import ProjectList from '../components/ProjectList/AllProjectsListComponent/ProjectList';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


const ProjectListView = () => {
  const navigate = useNavigate();
  const [selectedSection, setSelectedSection] = useState("all");
  const [searchQuery, setSearchQuery] = useState('');
  const userSession = useSelector((state) => state.AuthReducer.userSession);
  console.log(userSession);
  const handleAddClick = () => {
    navigate('/createProject'); 
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className="ProjectListBackground">
      <div className="ProjectListViewSection">
        <div className="ProjectListHeader">
          <div className="ProjectList">
            Project List
          </div>
          <div className="button-container">
            <input
              className="project-list-search-box"
              type="text"
              placeholder="&#xF002; Search" 
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <button className="createButton" onClick={handleAddClick}>+ Create Project</button>
          </div>
        </div>
        
        <div className="ProjectListSection">
          <ProjectListButtons activeSection={selectedSection} setActiveSection={setSelectedSection} />
          {selectedSection === "all" && <ProjectList searchQuery={searchQuery} />}
        </div>    
      </div>
    </div>
  );
};

export default ProjectListView;
