import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './DisplayMilestones.css';
import { useState, useEffect } from 'react';
import useAuthService from '../../../services/AuthService';

const DisplayMilestones = ({ projectId,onMilestonesLoad}) => {
    const [milestones, setMilestones] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedMilestoneIndex, setSelectedMilestoneIndex] = useState(null);
    const [selectedMilestoneTaskIndex, setSelectedMilestoneTaskIndex] = useState(null);
    const { getProjectMilestonesTasks } = useAuthService();

    useEffect(() => {
        const fetchMilestones = async () => {
            try {
            setIsLoading(true);
            const { data } = await getProjectMilestonesTasks(projectId);
            setMilestones(data);
            onMilestonesLoad(data);
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
            }
        };
        fetchMilestones();
    }, [projectId]);

    if (isLoading) {
        return <div>Loading milestones...</div>;
    }
    if (error) {
        return <div>Error loading milest
            ones: {error.message}</div>;
    }
    const handleMilestoneClick = (index) => {
        if (selectedMilestoneIndex === index) {
            setSelectedMilestoneIndex(null); // Collapse if clicked again
        } else {
            setSelectedMilestoneIndex(index); // Show tasks if clicked
        }
    };
    const checkIndexActiveShrunken = (index) => {
        if (selectedMilestoneIndex && selectedMilestoneIndex - 1 === index && milestones[selectedMilestoneIndex].tasks && milestones[selectedMilestoneIndex].tasks.length > 0)
            return false;
        return selectedMilestoneIndex === index || selectedMilestoneIndex - 1 === index || selectedMilestoneIndex + 1 === index;
    };
    const checkIndexShortenBorder = (index) => {
        if (selectedMilestoneIndex && selectedMilestoneIndex + 1 === index && milestones[selectedMilestoneIndex].tasks && milestones[selectedMilestoneIndex].tasks.length > 0)
            return false;
        return selectedMilestoneIndex + 1 === index || selectedMilestoneIndex - 1 === index;
    };
    return (
        <div className="MilestoneSection">
            <div className="milestone-list">
                {milestones.map((milestone, index) => (
                    <div className='milestone-wrapper' key={milestone.milestoneId}>
                        <div 
                            className={`milestone-item ${checkIndexActiveShrunken(index) ? 'active' : 'shrunken'} ${checkIndexShortenBorder(index) ? "shorten-border":""}`}
                            onClick={() => handleMilestoneClick(index)}
                        >
                            <div className='milestone-top-item'>
                                <span className={`fa-circle-check ${selectedMilestoneIndex === index? "orange":""}`}>
                                    <div className={`circled ${selectedMilestoneIndex === index? "orange":index > selectedMilestoneIndex ? "black" :"green"}`}> {index + 1} </div>
                                </span>
                                {index < milestones.length - 1 && (
                                    <div className={`border-line ${selectedMilestoneIndex === index? "orange": index > selectedMilestoneIndex ? "black" :""}`} onClick={() => handleMilestoneClick(index)}></div>
                                )}
                            </div>
                            <div className='milestone-bottom-item'>
                                {milestone.milestoneName}
                            </div>
                        </div>
                        {selectedMilestoneIndex === index && milestone.tasks && milestone.tasks.map((task, taskIndex) => (
                            <div className={`milestone-item task ${selectedMilestoneTaskIndex === taskIndex || (selectedMilestoneTaskIndex - 1 === taskIndex) || (selectedMilestoneTaskIndex + 1 === taskIndex) ? 'active' : 'shrunken'} ${selectedMilestoneIndex + 1 === index || selectedMilestoneIndex - 1 === index ? "shorten-border":""}`} 
                                key={task.taskId}>
                                <div className='milestone-top-item'>
                                    <span className={`fa-circle-check-darker-shorter ${selectedMilestoneTaskIndex === taskIndex? "orange":""}`}>
                                        <div className={`squared ${selectedMilestoneTaskIndex === taskIndex? "orange":taskIndex > selectedMilestoneTaskIndex ? "black" :"green"}`}> {taskIndex + 1} </div>
                                    </span>                                    
                                    <div className={`border-line darker`}></div>
                                </div>
                                <div className='milestone-bottom-item'>
                                    {task.taskName}
                                </div>
                            </div>
                        ))}                        
                    </div>
                ))}
            </div>
        </div>
    );
}
 
export default DisplayMilestones;