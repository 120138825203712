import React, { useState } from "react";
import './Modal.css'; 

const AddRole = ({ show, handleClose, handleSave }) => {
  const [roleDetails, setRoleDetails] = useState('');

  const handleInputChange = (event) => {
    setRoleDetails(event.target.value);
  };

  const handleCloseClick = () => {
    handleClose(); 
  };

  const handleSaveClick = () => {
    handleSave(roleDetails); 
  };

  if (!show) {
    return null; 
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={handleCloseClick}>
          &times;
        </span>
        <div className="modal-header">
          <h5>Add New Role</h5>
        </div>
        <div className="modal-body">
          <div className="form-row">
            <div className="form-group col-md-4">
              <label>Role Name</label>
              <input
                className="roleDetailsInput"
                name="roleName"
                type="text"
                value={roleDetails}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button className="Close" onClick={handleCloseClick}>
            Close
          </button>
          <button className="Save" onClick={handleSaveClick}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddRole;
