import React, { useState } from 'react';
import './CreateProjectView.css'; 
import CustomerInformation from '../components/CreateProject/CustomerInformation';
import ProjectInformation from '../components/CreateProject/ProjectInformation';
import ProjectContacts from '../components/CreateProject/ProjectContacts';
import AssignedStaff from '../components/CreateProject/AssignedStaff';
import { useNavigate } from 'react-router-dom';
function CreateProjectView() {
  const navigate = useNavigate()
  
  const [propertyType, setPropertyType] = useState();
  const [customerInfo, setCustomerInfo] = useState({});
  const [projectInfo, setProjectInfo] = useState({}); 
  const [contacts, setContacts] = useState([]);
  const [assignedStaff, setAssignedStaff] = useState([]);

  // Handlers to receive data from the child component
  const handlePropertyType = (value) => {
    setPropertyType(value);
  };

  const handleCustomerInfo = (data) => {
    setCustomerInfo(data);
  };

  const handleProjectInfo = (value) => {
    setProjectInfo(value);
  };
  const handleContacts = (value) => {
    setContacts(value);
  };
  const handleAssignedStaff = (value) => {
    setAssignedStaff(value);
  };

  // Handle form submission
  const handleSubmit = async () => {
    const projectData = {
      propertyType,
      customerInfo,
      projectInfo,
      contacts,
      assignedStaff,
    };

    console.log("projectData",projectData)

    // Call your API to save the project data
    try {
      // const response = await axiosInstance.post('/projects', projectData);
      const response = await fetch('http://localhost:8000/api/projects/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(projectData),
      });
      const result = await response.json();

      // After successful submission, navigate to the project details page
      navigate('/viewProject', {
        state: { project_id: result.project_id }
      });
    } catch (error) {
      console.error('Error submitting project:', error);
    }
  }

  const handleCancel = () =>{
    navigate('/viewProjectList'); 
  }

  return (
    <section className="create-project-page">
      <div className="create-project-header">
        Create Project
      </div>
      <ProjectInformation sendPropertyType={handlePropertyType} projectId={null}/>
      <br/>
      <CustomerInformation projectId = {null} propertyType={propertyType} sendCustomerInfo={handleCustomerInfo}/>
      <br/>
      <ProjectContacts showAddedContacts={true} sendAddedContacts={handleContacts}/>
      <br/>
      <AssignedStaff showAddedStaff={true} sendAddedStaff={handleAssignedStaff}/>
      <br/>
      <div className="submit-job-details-button-div">
        <button className="cancel-create-project-button" onClick={handleCancel}>Cancel</button>
        <button className="submit-create-project-button" onClick={handleSubmit}>
          Submit &nbsp;
          <div className="submit-loader new-job-submit-loader"></div>
        </button>
      </div>
    </section>
  );
}

export default CreateProjectView;