import './PostingButtonsList.css';
const PostingButtonsList = ({activeSection, setSelectedSection})=>{
    // console.log(activeSection)
    return (
        <div className="PostingButtonsListSection">
            <button 
                className={`SectionButton ${activeSection === "details" ? "active" : ""}`} 
                onClick={() => setSelectedSection("details")}>
                Details
            </button>
            <button 
                className={`SectionButton ${activeSection === "events" ? "active" : ""}`} 
                onClick={() => setSelectedSection("events")}>
                Events
            </button>
            <button 
                className={`SectionButton ${activeSection === "dates" ? "active" : ""}`} 
                onClick={()=>setSelectedSection("dates")} >
                Dates
            </button>
            <button 
                className={`SectionButton ${activeSection === "tasks" ? "active" : ""}`} 
                onClick={() => setSelectedSection("tasks")} >
                Tasks
            </button>
            <button 
                className={`SectionButton ${activeSection === "documents" ? "active" : ""}`} 
                onClick={() => setSelectedSection("documents")} >
                File Manager
            </button>
            <button 
                className={`SectionButton ${activeSection === "pages" ? "active" : ""}`} 
                onClick={() => setSelectedSection("pages")} >
                Pages
            </button>
            <button 
                className={`SectionButton ${activeSection === "media" ? "active" : ""}`} 
                onClick={()=>setSelectedSection("media")} >
                Media
            </button>
            <button 
                className={`SectionButton ${activeSection === "notes" ? "active" : ""}`} 
                onClick={()=>setSelectedSection("notes")} >
                Notes
            </button>
            <button 
                className={`SectionButton ${activeSection === "finances" ? "active" : ""}`} 
                onClick={()=>setSelectedSection("finances")} >
                Financials
            </button>
        </div>
    );
}

export default PostingButtonsList;