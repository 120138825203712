import React, { useState } from 'react';
import './CurrencyInput.css';

const currencies = ['USD', 'EUR', 'GBP', 'JPY', 'AUD', 'CAD'];

const CurrencyInput = ({
  onValueChange,
  onCurrencyChange,
  initialCurrency = 'USD',
  deductibleValue = ''
}) => {
  const [currency, setCurrency] = useState(initialCurrency);

  const handleCurrencyChange = (e) => {
    const selectedCurrency = e.target.value;
    setCurrency(selectedCurrency);
    if (onCurrencyChange) onCurrencyChange(selectedCurrency);
  };

  const handleValueChange = (e) => {
    const newValue = e.target.value;
    if (onValueChange) onValueChange(newValue);
  };

  return (
    <div className="currencyDropdown">
      <input
        className="currency-values"
        type="text"
        value={deductibleValue} // Use the deductibleValue prop here
        onChange={handleValueChange}
      />
      <select
        className="currencySelect"
        value={currency}
        onChange={handleCurrencyChange}
      >
        {currencies.map((cur) => (
          <option key={cur} value={cur}>
            {cur}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CurrencyInput;
