
import config from '../config.json';
import React from 'react';
import {addressFilter} from './utils';

export const locationAutoComplete = async (val)=>{
    const url = `${config.backendAPI}/crm/google_api/`;
    const options = {
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json'
        }, 
        body: JSON.stringify({address: val})
    };
    
    return await fetch(url, options)
        .then(res => res.json()).then((data)=>{
            try{
                return addressFilter(data);
            }
            catch(ex){
                console.error("error while accessing data" + ex)
                return [];
            }
        }).catch((err)=>{
            console.log(err);
        })
}
