import './ProjectView.css';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DisplayMilestones from '../components/ProjectPosting/DisplayMilestonesComponent/DisplayMilestones';
import ProjectInfo from '../components/ProjectPosting/ProjectInfoComponent/ProjectInfo';
import PostingButtonsList from '../components/ProjectPosting/PostingButtonsListComponent/PostingButtonsList';
import PostingDetails from '../components/ProjectPosting/PostingDetailsComponent/PostingDetails';
import PostingTasks from '../components/ProjectPosting/PostingTasksComponent/PostingTasks';
import PostingEvents from '../components/ProjectPosting/PostingEventsComponent/PostingEvents';
import PostingDocuments from '../components/ProjectPosting/PostingDocumentsComponent/PostingDocuments';
import PostingMedia from '../components/ProjectPosting/PostingMediaComponent/PostingMedia';
import { useNavigate } from 'react-router-dom';
import PostingPage from '../components/ProjectPosting/PostingPageComponent/PostingPage';
import useAuthService from '../services/AuthService';


const ProjectView = () =>{
    const location = useLocation();
    const projectId = location.state?.projectId;
    const projectName = location.state?.projectName;
    const authService = useAuthService();
    const {getCustomerProjectInfo, getViewProjectContacts, getViewAssignedStaff} = useAuthService();
    const [projectData, setprojectData] = useState(null);
    const [selectedSection, setSelectedSection] = useState("details");
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [height, setHeight] = useState(400);
    const navigate = useNavigate();
    const [milestones,setMileStone]= useState()

    const fetchProjectInfo = async () => {
        try {
            getCustomerProjectInfo(projectId).then((data)=>{
                setprojectData(data);
                setIsLoading(false);
            }).catch((err)=>{
                setIsLoading(false);
                alert("Error while fetchProjectInfo()")
                console.log("Error while fetching data..."+err);
            });
        } catch (err) {
            setError(err);
        } finally {
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        fetchProjectInfo()
        
    }, [projectId]);
    useEffect(() => {
        setHeight(selectedSection === 'details' ? 400 : 0);
    }, [selectedSection]);
    // useEffect(()=>{
    //     fetchSelectionData();
    // })
    const fetchSelectionData = async () => {  
        if (!projectId) return;
    
        let fetchData;
        try {
            switch (selectedSection) {
                case 'details':
                    fetchData = getCustomerProjectInfo;
                    break;
                case 'contacts':
                    fetchData = getViewProjectContacts;
                    break;
                case 'staff':
                    fetchData = getViewAssignedStaff;
                    break;
                default:
                    return;
            }
            console.log("selected section:"+selectedSection)
            fetchData(projectId).then((data)=>{          
                setprojectData(data);
            }).catch((err)=>{
                alert("Error while fetchSelectionData")
                console.error(err);
            });            
        } catch (error) {
            console.error(`Error fetching ${selectedSection} data:`, error);
        }
    };
    const handleMilestonesLoad = (milestonesData) => {
        setMileStone(milestonesData)
       
    };
    useEffect(() => {
        setHeight(selectedSection === 'details' ? 400 : 0);
    }, [selectedSection]);
   
    if (error) {
        return <div>Error: {error.message}</div>;
    }
    
    return (
        <div className = "ProjectViewBackground">
            <div className="ProjectViewSection">
                {/* {projectData && <ProjectInfo data = {projectData}/>} */}
                <DisplayMilestones  projectId={projectId} data={projectData} onMilestonesLoad={handleMilestonesLoad}/>
                {isLoading && (<div className='loader-container'> <div className='loader'> </div></div>)}
                <div className="ProjectNameHeader">
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" onClick={() => navigate('/viewProjectList')}/>
                    <div className="ProjectName">
                        {projectName}
                    </div>
                </div>
                <div className="ProjectSection">
                    <ProjectInfo initialHeight={height}  projectId={projectId} data = {projectData}/>
                    <PostingButtonsList 
                        activeSection={selectedSection} 
                        setSelectedSection={setSelectedSection}
                    />
                    { selectedSection === "details" && (
                        <PostingDetails data={projectData} retrieveData={fetchSelectionData} projectId = {projectId}/>
                    )}
                    { selectedSection === "events" && <PostingEvents/> }
                    { selectedSection === "tasks" && <PostingTasks milestonedata={milestones}/> }
                    { selectedSection === "documents" && <PostingDocuments/> }
                    { selectedSection === "media" && <PostingMedia/> } 
                    { selectedSection === "pages" && <PostingPage/> }                    
                </div>
            </div>   
        </div>
    );
}

export default ProjectView;