import React, { useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import Quill from 'quill';
import 'react-quill/dist/quill.bubble.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBold, faItalic, faUnderline, faListOl, faListUl, faImage } from '@fortawesome/free-solid-svg-icons';

import './PostingPage.css';

// Create the SmartBreak blot for <br> functionality
const Break = Quill.import('blots/break');
const Embed = Quill.import('blots/embed');

class SmartBreak extends Break {
  length() {
    return 1;
  }
  value() {
    return '\n';
  }
  insertInto(parent, ref) {
    Embed.prototype.insertInto.call(this, parent, ref);
  }
}

SmartBreak.blotName = 'break';
SmartBreak.tagName = 'BR';
Quill.register(SmartBreak);

const TextEditor = ({ editorContent, setEditorContent, modifiedTime, setModifiedTime, isBlankPageOpen }) => {
  const quillRef = useRef(null);

  const handleEditorChange = (content) => {
    console.log(content)
    const updatedContent = content.replace(/<br\s*\/?>/g, '<br>');
    setEditorContent(updatedContent);
    console.log(updatedContent)
    setModifiedTime(new Date().toLocaleString());
  };

  const handleFormat = (format, value) => {
    const quill = quillRef.current.getEditor();
    const range = quill.getSelection();

    if (range) {
      if (format === 'list') {
        const currentFormat = quill.getFormat(range);
        quill.format('list', currentFormat.list === value ? false : value);
      } else {
        const currentFormat = quill.getFormat(range);
        quill.format(format, !currentFormat[format]);
      }
    }
  };

  const handleImageInsert = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const quill = quillRef.current.getEditor();
        const range = quill.getSelection();
        const index = range ? range.index : quill.getLength();
        quill.insertEmbed(index, 'image', reader.result);
        quill.setSelection(index + 1); // Move cursor after the inserted image
      };
      reader.readAsDataURL(file);
    }
  };

  const handleTextSizeChange = (size) => {
    const quill = quillRef.current.getEditor();
    quill.format('size', size);
  };

  // Modify the Enter key behavior to insert a <br> tag
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection();
      if (range) {
        quill.insertEmbed(range.index, 'break', true, 'user'); // Insert <br> tag
        quill.setSelection(range.index + 1); // Move cursor to the next line
        event.preventDefault(); // Prevent default behavior
      }
    }
  };

  useEffect(() => {
    if (isBlankPageOpen) {
      const quill = quillRef.current.getEditor();
      quill.format('size', 'large');
    }
  }, [isBlankPageOpen]);

  return (
    <div className="quill-editor">
      <div className="toolbar">
        <button className="ql-bold" onClick={() => handleFormat('bold', true)}>
          <FontAwesomeIcon icon={faBold} />
        </button>
        <button className="ql-italic" onClick={() => handleFormat('italic', true)}>
          <FontAwesomeIcon icon={faItalic} />
        </button>
        <button className="ql-underline" onClick={() => handleFormat('underline', true)}>
          <FontAwesomeIcon icon={faUnderline} />
        </button>
        <button className="ql-list" onClick={() => handleFormat('list', 'ordered')}>
          <FontAwesomeIcon icon={faListOl} />
        </button>
        <button className="ql-list" onClick={() => handleFormat('list', 'bullet')}>
          <FontAwesomeIcon icon={faListUl} />
        </button>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageInsert}
          style={{ display: 'none' }}
          id="image-upload"
        />
        <label htmlFor="image-upload" className="ql-image">
          <FontAwesomeIcon icon={faImage} />
        </label>

        <select onChange={(e) => handleTextSizeChange(e.target.value)} defaultValue="large">
          <option value="small">Small</option>
          <option value="large">Normal</option>
          <option value="huge">Extra Large</option>
        </select>
      </div>

      <ReactQuill
        className="quill"
        ref={quillRef}
        value={editorContent}
        onChange={handleEditorChange}
        theme="bubble"
        placeholder="Start typing here..."
        onKeyDown={handleKeyDown} // Handle key down to insert <br> tags
      />
    </div>
  );
};

export default TextEditor;
